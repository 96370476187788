import { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import Icon from '../../../components/shared/Icon';
import InfoMessage from '../../../components/shared/InfoMessage';
import SearchInput from './SearchInput';
import SearchResultsList from './SearchResultsList';

import { useSearchTerm } from '../../../hooks/useSearchTerm';

import SearchStore from '../services/search.store';
const searchStore: SearchStore = new SearchStore();

const JS_CLASSES = {
  searchToggler: 'js-header-search-icon',
  searchClear: 'js-search-close-icon',
};

const MIN_CHARS = 4;

/**
 * Header search component to handle user input and display search results.
 *
 * @component
 */
const HeaderSearch = () => {
  const navigate = useNavigate();
  const searchContainerRef = useRef<HTMLDivElement | null>(null);
  const searchInputRef = useRef<HTMLInputElement | null>(null);

  const [isSearchContainerVisible, setIsSearchContainerVisible] =
    useState(false);
  const [isTyping, setIsTyping] = useState(false);

  const {
    searchTerm,
    setSearchTerm,
    debouncedSearchTerm,
    isSearchTermTooShort,
    searchInputPrompt,
  } = useSearchTerm({ minChars: MIN_CHARS });

  // Use the `useGetSearchResults` hook from the search store to fetch search results based on the debounced search term
  const { data, error, isFetching, refetch } = searchStore.useGetSearchResults({
    term: debouncedSearchTerm,
    rowsPerPage: 10,
  });

  useEffect(() => {
    const handleVisibilityChange = () => {
      // Focus the input only when the document is visible and the search container is visible
      if (document.visibilityState === 'visible' && isSearchContainerVisible) {
        searchInputRef.current?.focus();
      }
    };

    // Call the handler immediately to focus if conditions are met on mount
    handleVisibilityChange();
    document.addEventListener('visibilitychange', handleVisibilityChange);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, [isSearchContainerVisible]);

  useEffect(() => {
    // Check if the search container is visible and the input is focused
    const shouldRefetch =
      isSearchContainerVisible &&
      debouncedSearchTerm.length >= 4 &&
      isSearchInputFocused();

    if (shouldRefetch) {
      refetch();
    }
  }, [isSearchContainerVisible, refetch]);

  const isSearchInputFocused = () => {
    return document.activeElement === searchInputRef.current;
  };

  // Close the search container when clicking outside of it, excluding clicks on the header search icon
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as HTMLElement;

      // Do not close if the clicked target is the search icon or the close button
      if (
        target.classList.contains(JS_CLASSES.searchToggler) ||
        target.classList.contains(JS_CLASSES.searchClear)
      ) {
        return;
      }

      // Close the container if clicking outside of it
      if (
        searchContainerRef.current &&
        !searchContainerRef.current.contains(target)
      ) {
        handleCloseSearchBar();
      }
    };

    if (isSearchContainerVisible) {
      document.addEventListener('click', handleClickOutside);
    } else {
      document.removeEventListener('click', handleClickOutside);
    }

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isSearchContainerVisible]);

  /**
   * Toggles the visibility of the search bar.
   */
  const handleToggleSearchBar = () => {
    setIsSearchContainerVisible((prevState) => !prevState);
  };

  /**
   * Closes the search bar.
   */
  const handleCloseSearchBar = () => {
    setIsSearchContainerVisible(false);
  };

  /**
   * Tracks search term change.
   */
  const handleSearchTermChange = (term: string) => {
    setSearchTerm(term);
    setIsTyping(term !== debouncedSearchTerm);
  };

  /**
   * Submits search form.
   */
  const handleSubmit = () => {
    if (!searchTerm || isSearchTermTooShort) {
      return;
    }

    navigate(`/search?q=${searchTerm}&p=1`);
    handleCloseSearchBar();
    setSearchTerm('');
  };

  return (
    <div className="relative flex items-center w-11 h-full">
      <div
        className={`flex items-center justify-center w-full h-11 cursor-pointer ${JS_CLASSES.searchToggler}`}
        onClick={handleToggleSearchBar}
      >
        <Icon name="search" className="block w-5 pointer-events-none" />
      </div>
      {/* Search Container */}
      {isSearchContainerVisible && (
        <div
          ref={searchContainerRef}
          className="z-[1] fixed top-[80px] right-0 w-full flex flex-col gap-2 lg:absolute lg:right-0 lg:w-auto lg:min-w-[650px] lg:max-w-[650px] p-2 shadow-card rounded-lg border bg-white"
        >
          {/* Search Input */}
          <SearchInput
            ref={searchInputRef}
            value={searchTerm}
            isFetching={isFetching}
            clearIconClassName={JS_CLASSES.searchClear}
            onSearchTermChange={handleSearchTermChange}
            onSubmit={handleSubmit}
          />

          {/* Info mesage - min char requirement */}
          {isSearchTermTooShort && (
            <InfoMessage icon="info" message={searchInputPrompt} />
          )}

          {/* Search Results */}
          {data && searchTerm && isSearchInputFocused() && (
            <SearchResultsList
              data={data}
              error={!!error}
              isFetching={isFetching || isTyping}
              searchTerm={searchTerm}
              minChars={MIN_CHARS}
              onClose={handleSubmit}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default HeaderSearch;
