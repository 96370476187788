import Icon from '../Icon';
import * as ICONS from '../Icon/Icons';

import { SOCIAL_MEDIA_LINKS } from '../../../constants';
import { SocialMediaItemInterface } from '../../../types';

interface SocialMediaProps {
  data?: SocialMediaItemInterface[];
}

const SocialMedia: React.FC<SocialMediaProps> = ({
  data = SOCIAL_MEDIA_LINKS,
}) => {
  console.log(data);
  return (
    <ul className="flex gap-8 flex-wrap max-w-[200px]">
      {data.map((item) => (
        <>
          <li key={item.url} className="w-fit">
            <a
              title={item.label}
              href={item.url}
              target="_blank"
              rel="noopener noreferrer"
              aria-label={item.label}
            >
              <Icon
                name={item.id as keyof typeof ICONS}
                className={'hover:text-primary-300'}
              />
            </a>
          </li>
        </>
      ))}
    </ul>
  );
};

export default SocialMedia;
