import { Link, useNavigate, useParams } from 'react-router-dom';

import Button from '../../components/shared/Button';
import EducationReports from '../../modules/education/UI';
import Meta from '../../components/layout/Meta';
import PageLayout from '../../layouts/PageLayout';
import PageTitleWithActions from '../../layouts/PageLayout/TitleWithActions';

import { EDUCATION_HEADINGS, EDUCATION_PATH } from '../../constants';
import { useEffect } from 'react';

function EducationReportsPage() {
  const navigate = useNavigate();
  const { slug } = useParams<{ slug: string }>();
  const currentPage = EDUCATION_HEADINGS.find((heading) => heading.id === slug);
  const pageTitle = currentPage
    ? currentPage.title
    : 'Edukacija i korisne informacije';

  useEffect(() => {
    !currentPage && navigate(`${EDUCATION_PATH}/${EDUCATION_HEADINGS[0].id}`);
  }, [currentPage, navigate]);

  return (
    <>
      <Meta title="Edukacija i korisne informacije" />
      <PageLayout>
        <PageTitleWithActions title={pageTitle}>
          <Link
            to="/education/add-document"
            state={{ currentType: slug }}
            replace
            className="focus:outline-none group"
          >
            <Button tabIndex={-1}>Novi dokument</Button>
          </Link>
        </PageTitleWithActions>
        <EducationReports slug={slug} />
      </PageLayout>
    </>
  );
}

export default EducationReportsPage;
