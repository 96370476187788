import http from '../../../utils/http';

import { SearchApiInterface } from './search.types';

class SearchApi {
	/* Get Search Results */
	async getSearchResults({
		term = '',
		page = 1,
		rowsPerPage = 10,
		regionIds = [],
	}: SearchApiInterface) {
		const params = new URLSearchParams();

		params.append('term', term);
		params.append('page', page.toString());
		params.append('rowsPerPage', rowsPerPage.toString());
		regionIds.length &&
			regionIds.forEach((id: number) =>
				params.append('regionIds[]', id.toString())
			);

		const url = `/search${
			params.toString() ? `?${params.toString()}` : ''
		}`;

		return http.get(url);
	}

	async getDocument(url: string) {
		return http.get(url, {
			responseType: 'blob',
		});
	}
}

export default SearchApi;
