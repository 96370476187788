import { useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';

import Icon from '../../shared/Icon';

import { ACTIVE_REGION_KEY } from '../../../constants';
import { useRegions } from '../../../context/regions-context';
import { usePaginatedDropdown } from '../../../hooks/usePaginatedDropdown';

import RegionsStore from '../../../modules/regions/services/regions.store';
const regionsStore: RegionsStore = new RegionsStore();

const AvailableRegions = (): JSX.Element | null => {
  const [searchParams, setSearchParams] = useSearchParams();
  const availableRegionsRef = useRef<HTMLDivElement>(null);
  const hasLabel = useRef(false);
  const hasLoadedData = useRef(false);

  const {
    availableRegionsData,
    setAvailableRegionsData,
    activeRegion,
    setActiveRegion,
    isDropdownActive,
    onCloseDropdown,
    toggleDropdown,
  } = useRegions();

  const useRegionsDropdown = () => {
    return usePaginatedDropdown({
      fetchData: (page) =>
        regionsStore.useGetRegions({
          page,
          searchTerm: '',
          shouldFetch: availableRegionsData?.allRegions === 1 ? true : false,
        }),
      extractItems: (data) => {
        return (data?.regions || []).map((region: any) => ({
          id: region.id,
          label: region.name,
        }));
      },
    });
  };

  const { finalItems: allRegions } = useRegionsDropdown();

  useEffect(() => {
    if (hasLoadedData.current === true) return;

    // If allRegions === 1 and allRegions is provided, update availableRegionsData with fetched data
    if (availableRegionsData?.allRegions === 1 && allRegions?.length > 0) {
      // Avoid unnecessary updates by checking if the data has changed
      setAvailableRegionsData((prevData) => {
        // Check if regions or any other required data needs to be updated
        const newRegions = allRegions.map((region) => ({
          id: region.id,
          name: region.label,
          init: true,
        }));

        const key = localStorage.getItem(ACTIVE_REGION_KEY);
        hasLoadedData.current = true;

        const activeRegion = key
          ? parseInt(key as string)
          : newRegions.length > 0
          ? newRegions[0].id
          : undefined;

        return {
          ...prevData,
          regions: newRegions,
          allRegions: prevData?.allRegions ?? 0,
          activeRegion: activeRegion,
        };
      });
    } else if (availableRegionsData?.allRegions === 0) {
      hasLoadedData.current = true;
    }
  }, [allRegions, availableRegionsData?.allRegions, setAvailableRegionsData]);

  useEffect(() => {
    if (hasLabel.current) return; // If already run, skip the effect

    if (availableRegionsData?.regions.length) {
      setActiveRegion(
        availableRegionsData?.regions.find(
          (region) =>
            Number(region.id) === Number(availableRegionsData.activeRegion)
        ) ||
          availableRegionsData?.regions?.[0] ||
          undefined
      );
      // Mark as done to prevent the effect from running again
      hasLabel.current = true;
    }
  }, [
    availableRegionsData?.activeRegion,
    availableRegionsData?.regions,
    setActiveRegion,
  ]);

  useEffect(() => {
    if (!isDropdownActive) return;

    const handleClickOutside = (event: MouseEvent) => {
      if (
        availableRegionsRef.current &&
        !availableRegionsRef.current.contains(event.target as Node)
      ) {
        onCloseDropdown();
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isDropdownActive, onCloseDropdown]);

  if (
    availableRegionsData?.allRegions !== 1 &&
    (!availableRegionsData || availableRegionsData?.regions?.length <= 0)
  ) {
    return null;
  }

  const regionsToShow = availableRegionsData.regions.filter(
    (region) => region.id !== activeRegion?.id
  );

  const handleClick = ({ id, name }: { id: number; name: string }) => {
    // Create a copy of the search parameters
    const updatedParams = new URLSearchParams(searchParams);

    // Remove the 'page' parameter from the URL
    updatedParams.delete('page');

    // Update the search parameters in the URL
    setSearchParams(updatedParams);

    // Set active region
    setActiveRegion({ id, name });
  };

  return (
    <>
      {availableRegionsData.regions.length > 1 ? (
        <div
          className="bg-white text-center border-t border-b border-gray-300"
          ref={availableRegionsRef}
        >
          <button
            id={
              activeRegion?.id ? `reg-${activeRegion.id.toString()}` : undefined
            }
            className="flex w-full gap-1 items-center justify-center px-4 py-1"
            onClick={toggleDropdown}
          >
            <span>Region:</span>
            <span>{activeRegion?.name}</span>
            <Icon
              name="chevronDown"
              className={`transition-transform duration-300 ${
                isDropdownActive ? 'rotate-180' : 'rotate-0'
              }`}
            />
          </button>
          {isDropdownActive && (
            <ul className="flex flex-col gap-1 max-h-[80vh] sm:max-h-80 overflow-auto scrollbar mb-1">
              {regionsToShow.map(({ id, name }) => (
                <li
                  id={`reg-${id.toString()}`}
                  className="hover:cursor-pointer hover:text-primary-300"
                  key={id}
                  onClick={handleClick.bind(null, { id, name })}
                >
                  {name}
                </li>
              ))}
            </ul>
          )}
        </div>
      ) : null}
    </>
  );
};

export default AvailableRegions;
