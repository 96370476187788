import Button from '../../../components/shared/Button';
// import StatusIndicator from '../../../components/shared/StatusIndicator';
import { formatDateWithPoints } from '../../../utils';
import { EDITING_TEXT } from '../../../constants';
import { RegionInterface } from '../services/regions.types';
import Icon from '../../../components/shared/Icon';
import { useModal } from '../../../context/modal-context';
import RegionPreview from './RegionPreview';
import RegionForm from './RegionForm';
import NoSearchResultsInfoMessage from '../../../components/shared/InfoMessage/NoSearchResults';
import NoResultsInfoMessage from '../../../components/shared/InfoMessage/NoResults';

interface RegionListProps {
  regions: RegionInterface[] | undefined;
  onUpdate: (region?: RegionInterface | null) => void;
  searchValue?: string;
}

/**
 * Renders a list of regions with the option to edit each region.
 * Displays a message when there are no results and shows a status indicator for each region.
 *
 * @component
 * @param {RegionListProps} props - The props for this component.
 * @param {RegionInterface[] | undefined} props.regions - The list of regions to display.
 * @param {(region?: RegionInterface | null) => void} props.onUpdate - The callback to handle region updates.
 * @param {string} [props.searchValue] - The current search value used to filter the list of regions.
 *
 * @returns {React.JSX.Element} The rendered list of regions.
 */
const RegionsList = ({
  regions,
  onUpdate,
  searchValue = '',
}: RegionListProps): React.JSX.Element => {
  const { openModal, closeModal } = useModal();

  const handleOpenPreview = (e: any, region: any) => {
    e.stopPropagation();
    openModal(<RegionPreview region={region} />, 'Region');
  };

  const handleOpenRegionForm = (e: any, region: any) => {
    e.stopPropagation();
    openModal(
      <RegionForm region={region} onClose={closeModal} />,
      'Izmeni region'
    );
  };

  return (
    <ul className="flex flex-col gap-3">
      {regions?.length === 0 ? (
        <li>
          {searchValue === '' ? (
            <NoResultsInfoMessage />
          ) : (
            <NoSearchResultsInfoMessage />
          )}
        </li>
      ) : (
        regions?.map((region) => {
          const formatedDate = formatDateWithPoints(
            region?.dateEdited?.date || region?.dateCreated?.date
          );
          return (
            <li
              key={region.id}
              id={`region-${region.id}`}
              className="pks-card-bg pks-card-bg-hover overflow-hidden rounded-lg cursor-pointer group"
              onClick={(e) => handleOpenPreview(e, region)}
            >
              <div className="flex gap-4 items-center justify-between ml-[6px] pl-[22px] pr-4 py-3 bg-white">
                <div className="flex gap-2 items-center">
                  {/* <>
										{region.status === 1 ? (
											<StatusIndicator
												label={'Aktivan'}
												active
											/>
										) : (
											<StatusIndicator
												label={'Neaktivan'}
											/>
										)}
									</> */}
                  <div className="pks-layout-col">
                    <p className="font-bold">{region.name}</p>
                    <span className="text-secondary-400">{formatedDate}</span>
                  </div>
                </div>
                <div className="inline-flex items-center flex-col lg:flex-row  gap-4">
                  <span
                    className="transform scale-125 p-2 "
                    onClick={(e) => handleOpenRegionForm(e, region)}
                  >
                    <Icon
                      name="pencil"
                      aria-hidden="true"
                      className="hover:text-primary-300"
                      tabIndex={-1}
                    />
                  </span>
                  <button /* onClick={() => onDelete(member.id)} */>
                    <Icon
                      name="trash"
                      aria-hidden="true"
                      className="text-white"
                      tabIndex={-1}
                    />
                  </button>
                </div>
              </div>
            </li>
          );
        })
      )}
    </ul>
  );
};

export default RegionsList;
