import FraudCasesList, {
  FraudCasesListSkeleton,
} from '../../fraudCases/UI/FraudCasesList';
import InfoMessage from '../../../components/shared/InfoMessage';
import FraudCaseStore from '../../fraudCases/services/fraudCases.store';

/**
 * Fraud Case Preview Component
 * Displays a preview of the latest fraud cases with optional loading skeleton and error messages.
 * Allows customization of the title and the number of cases shown.
 *
 * @param {Object} props - Component props
 * @param {string} [props.title='Lista poslednjih slučajeva'] - Title of the preview section.
 * @param {number} [props.count=4] - Number of cases to display.
 * @returns {React.JSX.Element} - The rendered component.
 */

const fraudCaseStore = new FraudCaseStore();

const FreudCasesPreview = ({
  title = 'Lista poslednjih slučajeva',
  count = 4,
}: {
  title?: string;
  count?: number;
}): React.JSX.Element => {
  // Fetch the latest fraud cases using a custom hook
  const {
    data: fraudCasesData,
    isLoading,
    error,
  } = fraudCaseStore.useGetFraudCases();
  const fraudCases = fraudCasesData?._embedded?.fraudcases;

  /** Content */
  let content = null;
  if (isLoading) {
    // Display skeleton loader while data is being fetched
    content = <FraudCasesListSkeleton count={count} />;
  } else if (error || !fraudCases?.length) {
    // Display error or no data message
    const message = error
      ? error.message
      : 'Trenutno nema dostupnih slučajeva.';
    const icon = error ? 'danger' : 'info';
    content = <InfoMessage message={message} icon={icon} />;
  } else if (fraudCases?.length) {
    // Display the list of fraud cases
    content = <FraudCasesList data={fraudCases?.slice(0, count)} />;
  }

  return (
    <div className="pks-layout-col-md">
      <h3 className="h2">{title}</h3>
      {content}
    </div>
  );
};

export default FreudCasesPreview;
