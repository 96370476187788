import { AxiosError, AxiosResponse } from 'axios';
import {
  keepPreviousData,
  QueryKey,
  useQuery,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';

import queryClient from '../../../config/tanstackQueryConfig';
import { SOMETHING_WENT_WRONG_TEXT } from '../../../constants';
import { ActionResponse, ErrorResponse } from '../../../types';
import { handleErrors } from '../../../utils';

import { ResetPasswordFormDataInterface } from './forumMembers.types';

import ForumMembersApi from './forumMembers.api';
const api: ForumMembersApi = new ForumMembersApi();

const QUERY_KEY = 'forum-members';
const QUERY_KEY_AVATAR = 'forum-member-avatar';

/* const MINUTE: number = 1000 * 60; */

class ForumMembersStore {
  /* public forumMembers: any[] = [];
	public forumMember: any = null; */

  preloadForumMembers = async (
    page?: number,
    searchTerm?: string,
    region?: string,
    organization?: string
  ) => {
    return await queryClient.fetchQuery(
      forumMembersOptions(page, searchTerm, region, organization)
    );
  };

  useGetForumMembers = (
    page?: number,
    searchTerm?: string,
    region?: string,
    organization?: string
  ) => {
    return useQuery(
      forumMembersOptions(page, searchTerm, region, organization)
    );
  };

  getForumMember = async (id: number): Promise<ActionResponse<any>> => {
    try {
      const res = await api.getForumMember(id);
      const resData = res.data;
      return {
        success: true,
        message: resData?.message || 'Članovi su uspešno prikazani!',
        data: resData,
      };
    } catch (error) {
      const { message, success } = handleErrors(error);
      return { message, success };
    }
  };

  preloadForumMember = this.getForumMember;

  addForumMember = async (payload: any): Promise<ActionResponse<any>> => {
    try {
      const res = await api.addForumMember(payload);
      const resData = res.data;
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
      return {
        success: true,
        message: resData?.message || 'Član je uspešno dodat!',
        data: resData,
      };
    } catch (error) {
      const { message, success } = handleErrors(error);
      return { message, success };
    }
  };

  getForumMemberAvatar = async (id: number): Promise<ActionResponse<any>> => {
    try {
      const res = await api.getForumMemberAvatar(id);
      const resData = res.data;
      const imageBlob = new Blob([res.data], {
        type: res.headers['content-type'],
      });

      const imageFile = new File([imageBlob], `avatar_${id}`, {
        type: res.headers['content-type'],
        lastModified: Date.now(),
      });

      return {
        success: true,
        message: resData?.message,
        data: imageFile,
      };
    } catch (error) {
      const { message, success } = handleErrors(error);
      return { message, success };
    }
  };

  useGetForumMemberAvatar = (id: number): UseQueryResult<any, Error> => {
    return useQuery(forumMemberAvatarOptions(id));
  };

  updateForumMember = async (id: number, payload: any) => {
    try {
      const res = await api.updateForumMember(id, payload);
      const resData = res.data;
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
      // queryClient.invalidateQueries({ queryKey: [QUERY_KEY_AVATAR, { id }] });
      return {
        success: true,
        message: resData?.message || 'Član je uspešno izmenjen!',
        data: resData,
      };
    } catch (error) {
      const resError = error as AxiosError;
      const data = resError?.response?.data;
      const code = resError?.response?.request?.status || null;

      if (data) {
        const { message, success } = data as ErrorResponse;
        const newMessage = message || data;
        return {
          message: newMessage || SOMETHING_WENT_WRONG_TEXT,
          success,
          code,
        };
      } else {
        return {
          message: SOMETHING_WENT_WRONG_TEXT,
          success: false,
        };
      }
    }
  };

  deleteForumMember = async (id: number) => {
    try {
      const res = await api.deleteForumMember(id);
      const resData = res.data;
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
      return {
        success: true,
        message: 'Član je uspešno obrisan!',
        data: resData,
      };
    } catch (error) {
      const { message, success } = handleErrors(error);
      return { message, success };
    }
  };

  setForumMemberRoles = async (id: number, data: any) => {
    try {
      const res = await api.setForumMemberRoles(id, data);
      const resData = res.data;
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
      return {
        success: true,
        message: resData?.message || 'Role su uspešno dodate!',
        data: resData,
      };
    } catch (error) {
      const { message, success } = handleErrors(error);
      return { message, success };
    }
  };

  getForumMemberRole = async (id: number) => {
    try {
      const res = await api.getForumMemberRole(id);
      const resData = res.data;
      return {
        success: true,
        message: resData?.message || 'Roles successfully fetched',
        data: resData,
      };
    } catch (error) {
      const { message, success } = handleErrors(error);
      return { message, success };
    }
  };

  setNewPassord = async (
    data: ResetPasswordFormDataInterface
  ): Promise<ActionResponse<any>> => {
    try {
      const res = await api.setNewPassword(data);
      const resData = res.data;

      return {
        success: true,
        message: resData?.message || 'Lozinka je uspešno izmenjena',
      };
    } catch (error) {
      const resError = error as AxiosError;
      const data = resError?.response?.data;
      const code = resError?.response?.request?.status || null;

      if (data) {
        const { message, success } = data as ErrorResponse;
        const newMessage = message || data;
        return {
          message: newMessage || SOMETHING_WENT_WRONG_TEXT,
          success,
          code,
        };
      } else {
        return {
          message: SOMETHING_WENT_WRONG_TEXT,
          success: false,
        };
      }
    }
  };
}

export default ForumMembersStore;

/**
 * Returns query options for fetching / prefetching forum memberss.
 */
export const forumMembersOptions = (
  page?: number,
  searchTerm?: string,
  region?: string,
  organization?: string
): UseQueryOptions<any, Error> => ({
  queryKey: [QUERY_KEY, { page, searchTerm, region, organization }] as QueryKey,
  queryFn: async (): Promise<any> => {
    const response: AxiosResponse<any> = await api.getForumMembers(
      page,
      searchTerm,
      region,
      organization
    );
    return response.data;
  },
  placeholderData: keepPreviousData,
});

export const forumMemberAvatarOptions = (
  id?: number
): UseQueryOptions<any, Error> => ({
  queryKey: [QUERY_KEY_AVATAR, id] as QueryKey,
  queryFn: async (): Promise<any> => {
    try {
      const res: AxiosResponse<any | ErrorResponse> =
        await api.getForumMemberAvatar(id!);
      const resData = res.data;
      const imageBlob = new Blob([res.data], {
        type: res.headers['content-type'],
      });

      const imageFile = new File([imageBlob], `avatar_${id}`, {
        type: res.headers['content-type'],
        lastModified: Date.now(),
      });

      // queryClient.invalidateQueries({ queryKey: [QUERY_KEY_AVATAR, { id }] });
      const imageUrl = URL.createObjectURL(imageBlob);

      return {
        success: true,
        message: 'Avatar fetched successfully',
        data: imageUrl,
      };
    } catch (error) {
      const resError = error as AxiosError;
      const data = resError?.response?.data;
      const code = resError?.response?.request?.status || null;

      if (data) {
        const { message, success } = data as ErrorResponse;
        const newMessage = message || data;

        return {
          message: newMessage || SOMETHING_WENT_WRONG_TEXT,
          success,
          code,
        };
      } else {
        return {
          message: SOMETHING_WENT_WRONG_TEXT,
          success: false,
        };
      }
    }
  },
  enabled: Boolean(id),
  placeholderData: undefined,
});
