import { useState } from 'react';
import { Link } from 'react-router-dom';

import Meta from '../../components/layout/Meta';
import PageLayout from '../../layouts/PageLayout';
import PageTitleWithActions from '../../layouts/PageLayout/TitleWithActions';
import News from '../../modules/news/UI/index';

import Button from '../../components/shared/Button';
import ToggleSwitch from '../../components/shared/ToggleSwitch';

import { ViewEnum } from '../../types';

const EducationNewsPage = () => {
  const pageTitle = 'Vesti i korisne informacije';

  const [showActive, setShowActive] = useState(true);

  const handleToggleChange = (checked: boolean) => {
    setShowActive(checked);
  };

  return (
    <>
      <Meta title={pageTitle} />
      <PageLayout>
        <PageTitleWithActions title={pageTitle}>
          <Link
            to="/education/add-news"
            replace
            className="focus:outline-none group"
          >
            <Button tabIndex={-1}>Unesi novu vest</Button>
          </Link>
        </PageTitleWithActions>

        <div className="w-fit">
          <ToggleSwitch
            checked={showActive}
            id="news-status"
            label={`${showActive ? 'Aktivne vesti' : 'Deaktivirane vesti'}`}
            onChange={handleToggleChange}
          />
        </div>

        <News view={ViewEnum.Grid} showInactive={!showActive} />
      </PageLayout>
    </>
  );
};

export default EducationNewsPage;
