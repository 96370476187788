import { useEffect } from 'react';

import Icon from '../../../components/shared/Icon';
import NoResultsInfoMessage from '../../../components/shared/InfoMessage/NoSearchResults';
// import StatusIndicator from '../../../components/shared/StatusIndicator';

interface RolesListProps {
  roles: any[] | undefined;
  selected?: number;
  onClick: (id: number) => void;
  onUpdate: (role?: any) => void;
}

const RolesList: React.FC<RolesListProps> = ({
  roles,
  selected,
  onClick,
  onUpdate,
}) => {
  useEffect(() => {
    window.scrollTo({
      top: 0, // Scroll to the top of the page
      behavior: 'smooth', // Smooth scrolling effect
    });
  }, [selected]);

  return (
    <ul className="flex flex-col gap-3 h-full">
      {!roles || roles?.length === 0 ? (
        <li key="no-roles">
          <NoResultsInfoMessage />
        </li>
      ) : (
        roles.map((role: any) => (
          <li
            key={role.id}
            className={`pks-card-bg pks-card-bg-hover overflow-hidden rounded-lg cursor-pointer group ${
              role.id === selected
                ? 'sticky z-10 top-32 pks-card-bg-active'
                : ''
            }`}
            onClick={onClick.bind(null, role.id)}
          >
            <div
              className={`flex gap-4 items-center justify-between ml-[6px] pl-[22px] pr-4 py-3 ${
                role.id === selected ? 'bg-white' : 'bg-secondary-100'
              }`}
            >
              <div className="flex gap-2 items-center">
                {/* <>
									{role.status === 1 ? (
										<StatusIndicator
											label={'Aktivna'}
											active
										/>
									) : (
										<StatusIndicator label={'Neaktivana'} />
									)}
								</> */}
                <p className="font-bold">{role.name}</p>
              </div>
              <div
                className="transform scale-125"
                onClick={() => onUpdate(role)}
              >
                <Icon name="pencil" className="hover:text-primary-300" />
              </div>
            </div>
          </li>
        ))
      )}
    </ul>
  );
};

export default RolesList;
