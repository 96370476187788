import { ReactNode } from 'react';
import { HelmetProvider } from 'react-helmet-async';

import { AuthProvider } from './context/auth-context';
import { ModalProvider } from './context/modal-context';
import { RegionsProvider } from './context/regions-context';

import { QueryClientProvider } from '@tanstack/react-query';
import queryClient from './config/tanstackQueryConfig';

interface ProvidersProps {
  children: ReactNode;
}

const Providers = ({ children }: ProvidersProps) => {
  return (
    <AuthProvider>
      <RegionsProvider>
        <QueryClientProvider client={queryClient}>
          <HelmetProvider>
            <ModalProvider>{children}</ModalProvider>
          </HelmetProvider>
        </QueryClientProvider>
      </RegionsProvider>
    </AuthProvider>
  );
};

export default Providers;
