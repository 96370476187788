import { ChangeEvent, useEffect, useRef, useState } from 'react';
import { Controller } from 'react-hook-form';

import Icon from '../Icon';

import useInputContext from '../../../hooks/useInputContext';

import ForumMembersStore from '../../../modules/forumMembers/services/forumMembers.store';
const forumMembersStore: ForumMembersStore = new ForumMembersStore();

interface AvatarInputProps {
  userId: number;
  inputName?: string;
  maxSizeMB?: number;
  userFunction?: string;
  userName?: string;
}

const AvatarInput: React.FC<AvatarInputProps> = ({
  userId,
  inputName = 'avatar',
  maxSizeMB = 5,
  userName,
  userFunction,
}) => {
  const { control, error, value, setValue, watch } = useInputContext(inputName);
  watch(inputName);

  const [sizeError, setSizeError] = useState<string | null>(null);
  const errorRef = useRef<HTMLDivElement | null>(null); // Ref for the error message container

  const initials = userName
    ? userName
        .split(' ')
        .map((part) => part.charAt(0).toUpperCase())
        .join('')
    : 'AU';

  useEffect(() => {
    const fetchAvatar = async () => {
      const response = await forumMembersStore.getForumMemberAvatar(userId);

      if (response.success && response.data instanceof File) {
        setValue(inputName, response.data);
      }
    };

    // Fetch avatar if userId is provided
    if (userId) {
      fetchAvatar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files; // Get the files from the input

    // Check if any files were selected
    if (files && files.length > 0) {
      const file = files[0];
      const maxSizeInBytes = maxSizeMB * 1024 * 1024;

      // Check if the selected file exceeds the maximum size
      if (file.size > maxSizeInBytes) {
        setSizeError(`Slika mora biti manja od ${maxSizeMB}MB`);
        setValue(inputName, '');
      } else {
        setSizeError(null);
        setValue(inputName, file);
      }
    } else {
      setSizeError(null);
      setValue(inputName, '');
    }
  };

  useEffect(() => {
    if (sizeError || error) {
      errorRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }, [sizeError, error]);

  return (
    <div
      className={`flex flex-col items-center text-center xs:flex-row xs:text-left ${
        userName?.trim().length !== 0 || userFunction || error || sizeError
          ? 'gap-2'
          : ''
      }`}
    >
      <label
        htmlFor="avatar"
        className="relative w-24 h-24 min-w-24 rounded-full bg-secondary-200 flex items-center justify-center cursor-pointer"
      >
        <div className="absolute top-0 right-0">
          <Icon name="camera" />
        </div>
        {value instanceof File ? (
          <img
            src={URL.createObjectURL(value)}
            alt="Selected avatar"
            className="w-full h-full object-cover rounded-full"
          />
        ) : initials ? (
          <span className="font-bold text-center text-xl">{initials}</span>
        ) : (
          <span className="font-bold text-center text-sm">
            Dodaj <br />
            avatara
          </span>
        )}
      </label>

      <div>
        {userName && <p className="font-bold">{userName}</p>}
        {userFunction && <p>{userFunction}</p>}
        {/* Error */}
        {(error || sizeError) && (
          <div className="text-danger" ref={errorRef}>
            {error?.message && <span className="block">{error.message}</span>}
            {sizeError && <span className="block">{sizeError}</span>}
          </div>
        )}
      </div>

      <Controller
        name={inputName}
        control={control}
        rules={{
          validate: {
            fileSize: () => {
              if (sizeError) return sizeError;
              return true;
            },
          },
        }}
        render={() => (
          <div className="pks-layout-col">
            <div className="flex flex-col gap-2">
              <div className="inline-flex flex-wrap gap-2 items-center">
                <div className="relative">
                  <input
                    id="avatar"
                    name={inputName}
                    type="file"
                    accept="image/jpeg, image/png, image/jpg"
                    className="hidden opacity-0"
                    onChange={handleFileChange}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
      />
    </div>
  );
};

export default AvatarInput;
