import {
  keepPreviousData,
  useQuery,
  UseQueryOptions,
} from '@tanstack/react-query';
import { /* AxiosError,  */ AxiosResponse } from 'axios';

import queryClient from '../../../config/tanstackQueryConfig';
/* import { SOMETHING_WENT_WRONG_TEXT } from '../../../constants';
import {
  ActionResponse,
  ErrorResponse,
  SuccessResponse,
  UploadFileInterface,
} from '../../../types'; */
import { handleErrors } from '../../../utils';

import { QUERY_KEY as searchQueryKey } from '../../search/services/search.store';
import NewsApi from './news.api';
import { NewsPaginationInterface } from './index.types';
import { StatusEnum } from '../../../types';

export const QUERY_KEYS = {
  NEWS: 'news',
  DOCUMENTS: 'documents',
};

const api: NewsApi = new NewsApi();

class NewsStore {
  useGetNews = ({
    page,
    region,
    rowsPerPage,
    inactive = false,
  }: {
    page?: number;
    region?: string;
    rowsPerPage?: any;
    inactive?: boolean;
  }) => {
    return useQuery(
      getNewsQueryOptions({ page, region, rowsPerPage, inactive })
    );
  };

  useGetNewsPhoto = async (id: number) => {
    try {
      const res = await api.getNewsPhoto(id);

      const imageBlob = new Blob([res.data], {
        type: res.headers['content-type'],
      });

      const imageFile = new File([imageBlob], `news_${id}`, {
        type: res.headers['content-type'],
      });

      const fileUrl = URL.createObjectURL(imageFile);

      return {
        success: true,
        // message: res.data?.message || 'Logo uspešno dodat',
        data: {
          imageFile,
          fileUrl,
        },
      };
    } catch (error) {
      const { message, success } = handleErrors(error);
      return { message, success };
    }
  };

  storeNews = async ({ data, id }: { data: any; id?: number }) => {
    try {
      const res = await api.storeNews({ data, id });
      const resData = res.data;
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.NEWS] });
      queryClient.invalidateQueries({ queryKey: [searchQueryKey] });
      return {
        success: true,
        message: resData?.message || 'News successfully added!',
        data: resData,
      };
    } catch (error) {
      const { message, success } = handleErrors(error);
      return { message, success };
    }
  };

  changeStatus = async ({ id, status }: { id: number; status: StatusEnum }) => {
    try {
      const res = await api.changeStatus({ id, status });
      const resData = res.data;
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.NEWS] });
      queryClient.invalidateQueries({ queryKey: [searchQueryKey] });
      const successMessage =
        status === StatusEnum.Active
          ? 'Vest je uspešno aktivirana!'
          : 'Vest je uspešno deaktivirana!';
      return {
        success: true,
        message: successMessage,
        data: resData,
      };
    } catch (error) {
      const { message, success } = handleErrors(error);
      return { message, success };
    }
  };

  getSingleNews = async (id: number) => {
    try {
      const res = await api.getSingleNews(id);
      const resData = res.data;
      return {
        success: true,
        // message: resData?.message || 'Organization successfully added!',
        data: resData,
      };
    } catch (error) {
      const { message, success } = handleErrors(error);
      return { message, success };
    }
  };

  deleteNews = async (id: number) => {
    try {
      const res = await api.deleteNews(id);
      const resData = res.data;
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.NEWS] });
      queryClient.invalidateQueries({ queryKey: [searchQueryKey] });
      return {
        success: true,
        message: 'Vest je uspešno obrisana!',
        data: resData,
      };
    } catch (error) {
      const { message, success } = handleErrors(error);
      return { message, success };
    }
  };
}

export default NewsStore;

/**
 * Function that returns query options for fetching news data.
 *
 * @returns {UseQueryOptions<NewsPaginationInterface, Error>}
 * Options for the `useQuery` hook to fetch news data.
 */
export const getNewsQueryOptions = ({
  page,
  region,
  rowsPerPage,
  inactive = false,
}: {
  page?: number;
  region?: string;
  rowsPerPage?: any;
  inactive?: boolean;
}): UseQueryOptions<NewsPaginationInterface, Error> => ({
  queryKey: [QUERY_KEYS.NEWS, { page, region, rowsPerPage, inactive }],
  queryFn: async (): Promise<any> => {
    const response: AxiosResponse<any> = await api.getNews({
      page,
      region,
      rowsPerPage,
      inactive,
    });
    return response.data;
  },
  placeholderData: keepPreviousData,
});

/* export const getDocumentsQueryOptions = (
  page?: number,
  type?: string,
  searchTerm?: string,
  region?: string,
  organization?: string,
  rowsPerPage?: any
): UseQueryOptions<NewsPaginationInterface, Error> => ({
  queryKey: [
    QUERY_KEYS.DOCUMENTS,
    { page, type, searchTerm, region, organization, rowsPerPage },
  ],
  queryFn: async (): Promise<any> => {
    const response: AxiosResponse<any> = await api.getDocuments(
      page,
      type,
      searchTerm,
      region,
      rowsPerPage
    );
    return response.data;
  },
  placeholderData: keepPreviousData,
  refetchOnWindowFocus: true,
}); */

// export const getLatestNewsQueryOptions = (): UseQueryOptions<NewsPaginationInterface, Error> => ({
// 	queryKey: [QUERY_KEYS.LATEST_NEWS],
// 	queryFn: async (): Promise<any> => {
//         const response: AxiosResponse<any> = await api.fetchLatestNews( );
//         return response.data;
//     },
// 	placeholderData: keepPreviousData,
// });
