import { useEffect, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import Avatar from '../../shared/Avatar';
import Icon from '../../shared/Icon';

import {
  FORUM_MEMBERS_PATH,
  LOGOUT_TEXT,
  VISIT_PROFILE_TEXT,
} from '../../../constants';
import { useLogout } from '../../../hooks/useLogout';
import { useAuth } from '../../../context/auth-context';

interface UserAvatarProps {
  name?: string;
  id?: string;
}

const JS_CLASSES = { popupToggler: 'js-header-avatar-icon' };

const UserAvatarPopup: React.FC<UserAvatarProps> = ({ name, id }) => {
  const location = useLocation(); // Track the current route
  const { handleLogout } = useLogout();
  const { avatarUrl } = useAuth();

  const popupRef = useRef<HTMLDivElement | null>(null);
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const handleClick = () => setIsPopupOpen((prevState) => !prevState);
  const handleClosePopup = () => setIsPopupOpen(false);

  useEffect(() => {
    handleClosePopup();
  }, [location]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as HTMLElement;

      // Do not close if the clicked target is the search icon or the close button
      if (target.classList.contains(JS_CLASSES.popupToggler)) {
        return;
      }

      // Close the container if clicking outside of it
      if (popupRef.current && !popupRef.current.contains(target)) {
        handleClosePopup();
      }
    };

    if (isPopupOpen) {
      document.addEventListener('click', handleClickOutside);
    } else {
      document.removeEventListener('click', handleClickOutside);
    }

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [isPopupOpen]);

  return (
    <div className="relative h-full flex items-center select-none hover:cursor-pointer">
      <div
        className={`w-11 flex justify-center js-header-avatar-icon ${JS_CLASSES.popupToggler}`}
        onClick={handleClick}
      >
        <Avatar image={avatarUrl} name={name} rounded />
      </div>

      {isPopupOpen && (
        <div
          ref={popupRef}
          className="absolute top-[80px] right-0 w-auto min-w-[240px] py-2.5 shadow-card rounded-lg border bg-white"
        >
          <div className="flex w-full items-center gap-3 px-4 pt-1.5 pb-2.5">
            <Avatar image={avatarUrl} name={name} rounded />
            <div>
              <span className="block w-auto">{name}</span>
              <Link
                to={`${FORUM_MEMBERS_PATH}/${id}`}
                onClick={handleClosePopup}
                className="block text-sm text-secondary-400 hover:text-primary-300"
              >
                {VISIT_PROFILE_TEXT}
              </Link>
            </div>
          </div>
          <button
            className="w-full border-t border-t-secondary-200 px-4 pt-2.5 pb-1.5 flex gap-2 items-center hover:text-primary-300"
            onClick={handleLogout}
          >
            <Icon name="logOut" />
            <span>{LOGOUT_TEXT}</span>
          </button>
        </div>
      )}
    </div>
  );
};

export default UserAvatarPopup;
