import { QueryClient, QueryClientConfig } from '@tanstack/react-query';

// Define the configuration for QueryClient
const queryClientConfig: QueryClientConfig = {
  defaultOptions: {
    queries: {
      staleTime: 5 * 60 * 1000, // Data will be fresh for 5 minutes
      gcTime: 10 * 60 * 1000, // Data will remain in cache for 10 minutes
      retry: false, // Optional: Disable retrying failed requests
    },
  },
};

const queryClient = new QueryClient(queryClientConfig);

export default queryClient;
