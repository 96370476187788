import { useEffect, useState } from 'react';
import {
  useForm,
  FormProvider,
  SubmitHandler,
  FieldValues,
} from 'react-hook-form';

import Input from '../../../components/shared/Input';
import Button from '../../../components/shared/Button';
import Pagination from '../../../components/shared/Pagination';
import Comments from '../../../components/shared/Comments';

import { REQUIRED_VALIDATION_RULE, SUBMITTING_TEXT } from '../../../constants';
import { formatDateWithPoints, showToast } from '../../../utils';

import FraudCaseStore from '../services/fraudCases.store';
import ForumMembersStore from '../../forumMembers/services/forumMembers.store';
import useScrollToTop from '../../../hooks/useScrollTop';

const fraudCaseStore: FraudCaseStore = new FraudCaseStore();
const forumMembersStore: ForumMembersStore = new ForumMembersStore();

const FraudCaseComments = ({ id }: { id?: string }) => {
  const [page, setPage] = useState(1);
  const [comments, setComments] = useState<any[]>([]);

  const {
    data: fraudCaseCommentsQuery,
    error,
    isLoading,
    refetch,
  } = fraudCaseStore.useGetFraudCaseComments(id, page);

  const currentPage = fraudCaseCommentsQuery?._page || 1;
  const totalPages = fraudCaseCommentsQuery?._page_count || 1;

  const [initMembersPage, setInitMembersPage] = useState(1);
  const {
    data: usersQuery,
    isLoading: isLoadingUsers,
    isFetching: isFetchingUsers,
  } = forumMembersStore.useGetForumMembers(initMembersPage);
  const [users, setUsers] = useState<any[]>([]);

  useEffect(() => {
    if (fraudCaseCommentsQuery) {
      const transformedComments = fraudCaseCommentsQuery?._embedded?.comments
        .map((comment: any) => {
          return {
            id: comment.id,
            description: comment.description,
            date: formatDateWithPoints(
              comment.date_edited?.date || comment.date_created?.date
            ),
            createdBy: comment.created_by
              ? `${comment.created_by.firstName} ${comment.created_by.lastName} `
              : 'Unknown User',
            organization: comment.created_by?.organization?.name,
          };
        })
        .sort((a: any, b: any) => {
          const dateA = new Date(a.date).getTime();
          const dateB = new Date(b.date).getTime();
          return dateB - dateA; // Sort in descending order
        });

      setComments(transformedComments);
    }
  }, [fraudCaseCommentsQuery, users]);

  useEffect(() => {
    if (!usersQuery || isLoadingUsers || isFetchingUsers) return;

    const newUsers = usersQuery?._embedded?.users || [];
    const currentPage = usersQuery?._page || 1;
    const usersPageCount = usersQuery?._page_count || 1;

    const extractedUsers = newUsers.map((user: any) => ({
      id: user.id,
      firstName: user?.first_name,
      lastName: user?.last_name,
    }));

    setUsers((prevUsers) => [...prevUsers, ...extractedUsers]);

    if (currentPage < usersPageCount) {
      setInitMembersPage(currentPage + 1);
    }
  }, [usersQuery, isLoadingUsers, isFetchingUsers]);

  const methods = useForm({
    defaultValues: {
      description: '',
    },
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
    reset,
  } = methods;

  const onSubmit: SubmitHandler<FieldValues> = async (data) => {
    const { success, message } = await fraudCaseStore.addComment(
      Number(id),
      data
    );
    if (success) {
      reset();
      setPage(1); // Optionally, reset the page to 1 after adding a new comment
    }

    showToast(message, success);
  };

  const handlePageChange = (newPage: number) => {
    setPage(newPage);
  };

  useEffect(() => {
    refetch(); // Refetch data when the page changes
  }, [page, refetch]);

  useScrollToTop([page]);

  return (
    <div className="pks-layout-col-xl">
      <FormProvider {...methods}>
        <form className="pks-layout-col-md" onSubmit={handleSubmit(onSubmit)}>
          <Input
            id="description"
            name="description"
            label="Postavi komentar, pitanje"
            placeholder="Upiši komentar/pitanje"
            autoComplete="off"
            asTextarea
            validationRules={REQUIRED_VALIDATION_RULE}
          />
          <div className="text-right">
            <Button type="submit" disabled={isSubmitting} className="w-[200px]">
              {isSubmitting ? SUBMITTING_TEXT : 'Postavi komentar'}
            </Button>
          </div>
        </form>
      </FormProvider>
      {!error && !isLoading && <Comments comments={comments} />}
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
      />
    </div>
  );
};

export default FraudCaseComments;
