import { useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';

import NewsActionDropdown from './NewsActionDropDown';
import NewsImage from './NewsImage';

import { NEWS_PAGE_LINK } from '../../../constants';
import { ViewType, ViewEnum, StatusEnum } from '../../../types';
import { NewsArticleInterface } from '../services/index.types';

/**
 * NewsListProps Interface
 *
 * Defines the props for the NewsList component.
 * @interface
 * @property {NewsArticleInterface[]} news - The array of news articles to display.
 * @property {ViewType} [view] - The view type for displaying the news, either compact, detailed, or grid.
 */
interface NewsListProps {
  news: NewsArticleInterface[];
  view?: ViewType;
}

const NewsList: React.FC<NewsListProps> = ({
  news,
  view = ViewEnum.Compact,
}: NewsListProps): React.JSX.Element => {
  const location = useLocation();
  const isNewsPage = /^\/education\/news(\/)?$/.test(location.pathname);

  const eagerImgsNumRef = useRef<number | undefined>();

  if (typeof window !== 'undefined') {
    const screenWidth = window.innerWidth;
    eagerImgsNumRef.current = screenWidth < 1024 ? 0 : 5;
  }

  const newsHolderClassName = `pks-layout-col-md ${
    view === ViewEnum.Compact
      ? ''
      : `sm:grid sm:grid-cols-2 ${view === 'detailed' ? 'lg:grid-cols-1' : ''}`
  }`;

  const newsItemClassName = `group ${
    view === ViewEnum.Compact
      ? 'flex items-start gap-2 pb-4 border-b border-b-secondary-200'
      : ''
  }`;

  const newsImgHolderClassName = `relative overflow-hidden rounded-lg bg-gray-300 ${
    view === ViewEnum.Compact ? 'flex-none aspect-square' : 'aspect-video mb-4'
  }`;

  const newsTitleClassName =
    'overflow-ellipses line-clamp-2 group-hover:text-primary-300 h3';

  return (
    <ul className={newsHolderClassName}>
      {news?.map((article, index) => {
        const firstGridItemClassName =
          view === 'grid' && index === 0 ? 'col-span-full' : 'w-full';

        return (
          <li
            key={article.id || index}
            className={`relative ${firstGridItemClassName}`}
          >
            {view === ViewEnum.Grid && article.id && (
              <div className="absolute top-4 right-4 z-10">
                <NewsActionDropdown
                  id={article.id}
                  status={article.status ?? StatusEnum.Inactive}
                />
              </div>
            )}

            <Link
              to={`${NEWS_PAGE_LINK}/${article.id}`}
              state={{
                backTo: isNewsPage
                  ? `${location.pathname}${location.search}`
                  : '/education/news',
              }}
              title={article.title}
              className={newsItemClassName}
            >
              <div className={newsImgHolderClassName}>
                {article.id && article.title && (
                  <NewsImage
                    id={article.id}
                    title={article.title}
                    index={index}
                    eagerNumber={eagerImgsNumRef.current}
                    view={view}
                  />
                )}
              </div>
              <h2 className={newsTitleClassName}>{article.title}</h2>
            </Link>
          </li>
        );
      })}
    </ul>
  );
};

export default NewsList;
