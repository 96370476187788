import React from 'react';

interface ToggleSwitchProps {
  checked?: boolean; // Optional prop
  id: string;
  isTitle?: boolean;
  label: string;
  disabled?: boolean;
  disabledText?: string;
  onChange?: (checked: boolean) => void;
}

const ToggleSwitch: React.FC<ToggleSwitchProps> = ({
  checked = false, // Default to false if not provided
  id,
  isTitle = false,
  label,
  disabled = false,
  disabledText = undefined,
  onChange,
}) => {
  return (
    <label
      htmlFor={id}
      className={`flex items-center gap-2 ${!isTitle ? 'mb-2' : ''} ${
        disabled ? 'cursor-not-allowed' : 'cursor-pointer'
      }`}
      title={disabled ? disabledText : undefined}
    >
      <div className="relative">
        <input
          id={id}
          type="checkbox"
          className="sr-only"
          checked={checked}
          disabled={disabled}
          onChange={(e) => onChange?.(e.target.checked)}
        />
        <div
          className={`relative w-10 h-5 bg-gray-300 rounded-2xl shadow-inner ${
            checked ? 'bg-primary-300' : 'bg-secondary-300'
          } transition-colors duration-300 p-1`}
        >
          <div
            className={`dot absolute top-1 left-1 w-3 h-3 bg-white rounded-full shadow transition-transform duration-300 transform ${
              checked ? 'translate-x-5' : ''
            }`}
          ></div>
        </div>
      </div>
      {isTitle ? (
        <h3
          className={`select-none ${!disabled ? 'hover:text-primary-300' : ''}`}
        >
          {label}
        </h3>
      ) : (
        <span
          className={`select-none ${!disabled ? 'hover:text-primary-300' : ''}`}
        >
          {label}
        </span>
      )}
    </label>
  );
};

export default ToggleSwitch;
