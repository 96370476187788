import { StatusEnum } from '../../../types';
import http from '../../../utils/http';

const URL_PARAMS = {
  LATEST_NEWS: {
    limit: 4,
  },
  NEWS: {
    page: 1,
    limit: 5,
    showLimitInUrl: false,
  },
};

class NewsApi {
  // Fetch latest news
  getNews = async ({
    page,
    region,
    rowsPerPage,
    inactive = false,
  }: {
    page?: number;
    region?: string;
    rowsPerPage?: any;
    inactive?: boolean;
  }) => {
    const params = new URLSearchParams();

    if (page) {
      params.append('page', page.toString());
    }

    if (region) {
      params.append('regionIds[]', region);
    }

    if (rowsPerPage) {
      params.append('rowsPerPage', rowsPerPage);
    }

    if (inactive) {
      params.append('status', '0');
    }

    const url = `/news${params.toString() ? `?${params.toString()}` : ''}`;

    return http.get(url);
  };

  getNewsPhoto = (id?: number) => {
    return http.get(`/news/${id}/photo`, { responseType: 'blob' });
  };

  storeNews = ({ data, id }: { data: any; id?: number }) => {
    if (id) {
      return http.post(`/news/${id}`, data);
    }

    return http.post(`/news`, data);
  };

  changeStatus = ({ id, status }: { id: number; status: StatusEnum }) => {
    return http.post(`/news/${id}/status`, { status });
  };

  getSingleNews = (id: number) => {
    return http.get(`/news/${id}`);
  };

  deleteNews(id: number) {
    return http.delete(`/news/${id}`);
  }
}

export { URL_PARAMS };
export default NewsApi;
