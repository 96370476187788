import { useRef } from 'react';
import {
  useForm,
  useFieldArray,
  FormProvider,
  FieldValues,
} from 'react-hook-form';

import Icon from '../../../components/shared/Icon';
import Input from '../../../components/shared/Input';
import Button from '../../../components/shared/Button';

import {
  CONFIRM_TEXT,
  REQUIRED_VALIDATION_RULE,
  SUBMITTING_TEXT,
} from '../../../constants';
import { useRegions } from '../../../context/regions-context';
import { FAQItem } from '../services/info.types';
import TextEditor from '../../../components/shared/TextEditor';

interface FaqFormProps {
  onSubmit: (data: FieldValues) => void;
  defaultValues: { questions: FAQItem[] | null; regionId: number } | null;
}

const QuestionsAndAnswersForm: React.FC<FaqFormProps> = ({
  defaultValues,
  onSubmit,
}) => {
  const { activeRegion } = useRegions();
  const methods = useForm({
    defaultValues: {
      questions: defaultValues?.questions || [{ question: '', answer: '' }],
      regionId: activeRegion?.id || defaultValues?.regionId,
    },
  });

  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
    watch,
  } = methods;

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'questions',
  });

  const fieldRefs = useRef<(HTMLDivElement | null)[]>([]);
  const watchedFields = watch('questions');

  const shouldAddQuestion = () =>
    watchedFields.every(
      (field: { question: string; answer: string }) =>
        field.question.trim() !== '' && field.answer.trim() !== ''
    );

  const handleAddFAQ = () => {
    append({ question: '', answer: '' });
    setTimeout(() => {
      const lastFieldRef = fieldRefs.current[fields.length];
      lastFieldRef?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }, 0);
  };

  return (
    <FormProvider {...methods}>
      <form
        method="post"
        className="pks-layout-col-md w-full"
        onSubmit={handleSubmit(onSubmit)}
      >
        {/* Q&A Fields */}
        {fields.map((field, index) => (
          <div
            key={field.id}
            ref={(el) => (fieldRefs.current[index] = el)}
            className="pks-layout-col"
          >
            {/* Question Field */}
            <Input
              id={`questions.${index}.question`}
              name={`questions.${index}.question`}
              label={`Pitanje ${index + 1}`}
              placeholder="Unesi pitanje"
              validationRules={REQUIRED_VALIDATION_RULE}
            />

            {/* Answer Field */}
            {/* <Input
              id={`questions.${index}.answer`}
              name={`questions.${index}.answer`}
              label={`Odgovor ${index + 1}`}
              validationRules={REQUIRED_VALIDATION_RULE}
              asTextarea
            /> */}

            {/* Answer TextEditor */}
            <TextEditor
              id={`questions.${index}.answer`}
              label={`Odgovor ${index + 1}`}
              value={methods.getValues(`questions.${index}.answer`)}
              onChange={(value) =>
                methods.setValue(`questions.${index}.answer`, value)
              }
            />

            {/* Delete Button */}
            {fields.length > 1 && (
              <div>
                <Button
                  variant="danger"
                  type="button"
                  className="w-full sm:w-auto sm:min-w-48"
                  onClick={() => remove(index)}
                >
                  <div className="flex gap-2 items-center">
                    <Icon
                      name="trashCurrentColor"
                      className={`${
                        fields.length === 1 ? 'text-gray-500' : 'text-white'
                      }`}
                    />
                    <span>Obriši FAQ {index + 1}</span>
                  </div>
                </Button>
              </div>
            )}
          </div>
        ))}

        {/* Add Button */}
        <div>
          <Button
            type="button"
            onClick={handleAddFAQ}
            className="w-full sm:w-auto sm:min-w-48 focus:outline-none group text-left"
            disabled={!shouldAddQuestion()}
            isDisabled={!shouldAddQuestion()}
          >
            <div className="flex gap-2 items-center">
              <Icon name="plus"></Icon>Dodaj FAQ
            </div>
          </Button>
        </div>

        {/* Submit Button */}
        <Button type="submit" disabled={isSubmitting}>
          {isSubmitting ? SUBMITTING_TEXT : CONFIRM_TEXT}
        </Button>
      </form>
    </FormProvider>
  );
};

export default QuestionsAndAnswersForm;
