import PageLayout from '../../layouts/PageLayout';
import SearchResults from '../../modules/search/UI/index';

function SearchResultsPage() {
	return (
		<PageLayout>
			<SearchResults />
		</PageLayout>
	);
}

export default SearchResultsPage;
