import {
  keepPreviousData,
  QueryKey,
  useQuery,
  UseQueryOptions,
} from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import queryClient from '../../../config/tanstackQueryConfig';
import { handleErrors } from '../../../utils';
import RegionsApi from './regions.api';

import { ActionResponse } from '../../../types';
import {
  RegionAddInterface,
  RegionEditInterface,
  RegionInterface,
  RegionsResponseDataInterface,
} from './regions.types';

const QUERY_KEY = 'regions';

const api: RegionsApi = new RegionsApi();

class RegionsStore {
  preloadRegions = async ({
    page,
    searchTerm,
    shouldFetch = true,
  }: {
    page?: number;
    searchTerm?: string;
    shouldFetch?: boolean;
  }) => {
    return await queryClient.fetchQuery(
      regionsQueryOptions({ page, searchTerm, shouldFetch })
    );
  };

  useGetRegions = ({
    page,
    searchTerm,
    shouldFetch = true,
  }: {
    page?: number;
    searchTerm?: string;
    shouldFetch?: boolean;
  }) => {
    return useQuery(regionsQueryOptions({ page, searchTerm, shouldFetch }));
  };

  addRegion = async ({
    name,
    status,
  }: RegionAddInterface): Promise<ActionResponse<RegionInterface>> => {
    try {
      const res = await api.addRegion({ name, status });
      const resData = res.data;
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
      return {
        success: true,
        message: resData?.message || 'Region je uspešno dodat!',
        data: resData,
      };
    } catch (error) {
      const { message, success } = handleErrors(error);
      return { message, success };
    }
  };

  updateRegion = async ({
    id,
    name,
    status,
  }: RegionEditInterface): Promise<ActionResponse<RegionInterface>> => {
    try {
      const res = await api.updateRegion({ id, name, status });
      const resData = res.data;
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
      return {
        success: true,
        message: resData?.message || 'Region je uspešno izmenjen!',

        data: resData,
      };
    } catch (error) {
      const { message, success } = handleErrors(error);
      return { message, success };
    }
  };
}

/**
 * Returns query options for fetching / prefetching regions.
 */
export const regionsQueryOptions = ({
  page,
  searchTerm,
  shouldFetch = true,
}: {
  page?: number;
  searchTerm?: string;
  shouldFetch?: boolean;
}): UseQueryOptions<RegionsResponseDataInterface, Error> => ({
  queryKey: [QUERY_KEY, { page, searchTerm }] as QueryKey,
  queryFn: async (): Promise<RegionsResponseDataInterface> => {
    const response: AxiosResponse<RegionsResponseDataInterface> =
      await api.getRegions(page, searchTerm);
    return response.data;
  },
  placeholderData: keepPreviousData,
  enabled: !!shouldFetch,
});

export default RegionsStore;
