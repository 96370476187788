import {
  LoaderFunctionArgs,
  Params,
  redirect,
  useLoaderData,
  useNavigate,
} from 'react-router-dom';
import { FieldValues, SubmitHandler } from 'react-hook-form';
import { toast } from 'react-toastify';

import Button from '../../components/shared/Button';
import Icon from '../../components/shared/Icon';
import Info from '../../modules/info/UI';
import InfoForm from '../../modules/info/UI/InfoForm';
import Meta from '../../components/layout/Meta';
import NoResultsInfoMessage from '../../components/shared/InfoMessage/NoResults';
import PageLayout from '../../layouts/PageLayout';
import PageTitleWithActions from '../../layouts/PageLayout/TitleWithActions';

import queryClient from '../../config/tanstackQueryConfig';
import { useModal } from '../../context/modal-context';
import {
  decodeHtmlEntities,
  extractMultipleErrors,
  hasNestedObjects,
  showToast,
} from '../../utils';

import InfoStore, {
  infoQueryOptions,
} from '../../modules/info/services/info.store';
import { ActionResponse } from '../../types';
import { InfoResponse } from '../../modules/info/services/info.types';

const infoStore = new InfoStore();

// Enums for slug and type mapping
export enum InfoPageSlugEnum {
  TNC = 'terms-and-conditions',
  UserManual = 'user-manual',
}

export enum InfoPageTypeEnum {
  TNC = 'termsandconditions',
  UserManual = 'uermanual',
}

// Helper functions
const INFO_PAGES_VALID_PATHS = Object.values(InfoPageSlugEnum);

const findKeyBySlug = (
  slug: InfoPageSlugEnum
): InfoPageTypeEnum | undefined => {
  switch (slug) {
    case InfoPageSlugEnum.TNC:
      return InfoPageTypeEnum.TNC;
    case InfoPageSlugEnum.UserManual:
      return InfoPageTypeEnum.UserManual;
    default:
      return undefined;
  }
};

// Component
const InfoPage = () => {
  const navigate = useNavigate();
  const { data, type } = useLoaderData() as LoaderData;
  const { openModal, closeModal } = useModal();

  const pageTitle =
    type === InfoPageTypeEnum.UserManual
      ? 'Korisničko uputstvo'
      : 'Opšti uslovi rada foruma';

  const defaultValues = data
    ? {
        content: decodeHtmlEntities(data.content),
        regionId: data.regionId,
      }
    : null;

  const handleSubmit: SubmitHandler<FieldValues> = async (formData) => {
    if (!type) return;

    const response = await infoStore.addInfo({ data: formData, type });
    const { success, message } = response;

    if (success) {
      typeof message === 'string' && toast.success(message);
      closeModal();
      navigate('.', { replace: true, state: { key: Date.now() } });
    } else {
      if (hasNestedObjects(message)) {
        const errorMessages = extractMultipleErrors(message);
        toast.error(Object.values(errorMessages)[0] || 'Greška!');
      } else {
        showToast(message, success, true);
      }
    }
  };

  const handleOpenModal = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    openModal(
      <InfoForm defaultValues={defaultValues} onSubmit={handleSubmit} />,
      data ? 'Izmeni opis' : 'Dodaj opis'
    );
  };

  return (
    <>
      <Meta title={pageTitle} />
      <PageLayout isCms>
        <PageTitleWithActions title={pageTitle}>
          <Button className="flex gap-2 items-center" onClick={handleOpenModal}>
            <Icon name="pencil" />
            {data ? 'Izmeni' : 'Dodaj'}
          </Button>
        </PageTitleWithActions>
        {data?.date && <p>{data.date}</p>}
        <Info>
          {data?.content ? (
            <div
              dangerouslySetInnerHTML={{
                __html: decodeHtmlEntities(data.content),
              }}
            />
          ) : (
            <NoResultsInfoMessage />
          )}
        </Info>
      </PageLayout>
    </>
  );
};

export default InfoPage;

// Loader Function
interface LoaderData {
  type: InfoPageTypeEnum;
  data: InfoResponse | null;
}

export async function loader({
  params,
  request,
}: LoaderFunctionArgs<Params>): Promise<LoaderData> {
  let { slug } = params;

  // Check the URL for specific endings if no slug is provided
  if (!slug) {
    const url = new URL(request.url);
    const path = url.pathname;

    if (
      path.endsWith('organization') ||
      path.endsWith('terms-and-conditions')
    ) {
      slug = InfoPageSlugEnum.TNC;
    } else {
      slug = InfoPageSlugEnum.UserManual;
    }
  }

  if (!INFO_PAGES_VALID_PATHS.includes(slug as InfoPageSlugEnum)) {
    slug = InfoPageSlugEnum.UserManual;
  }

  const type = findKeyBySlug(slug as InfoPageSlugEnum);

  if (!type) {
    throw redirect('/404');
  }

  const response = (await queryClient.fetchQuery(
    infoQueryOptions(type)
  )) as ActionResponse<InfoResponse>;

  return {
    type,
    data: response.success ? response.data || null : null,
  };
}
