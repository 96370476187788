import { useEffect, useState } from 'react';
import { usePaginatedDropdown } from '../../../hooks/usePaginatedDropdown';
import { ChatTabsEnum } from '../../../types/Chat';
import RegionsStore from '../../regions/services/regions.store';
import ChatGroupConversation from './ChatGroupConversation';
import { set } from 'react-hook-form';
import { useRegions } from '../../../context/regions-context';

interface ChatGroupConversationsListProps {
  activeTab: ChatTabsEnum;
  regionChatConversations: any;
  onSelectCurrentChat: any;
  currentOpenChat: any;
  allRegions: any;
  countUnseenForRegion: any;
  allUsers: any;
}

const ChatRegionConversationsList = ({
  activeTab,
  regionChatConversations,
  onSelectCurrentChat,
  currentOpenChat,
  countUnseenForRegion,
  allRegions,
  allUsers,
}: ChatGroupConversationsListProps) => {
  const [regionName, setRegionName] = useState<any>('');
  const { activeRegion } = useRegions();

  useEffect(() => {
    if (!activeRegion) return;
    const region = allRegions.find(
      (region: any) => +region?.id === +activeRegion?.id
    );
    setRegionName(region?.label);
  }, [activeRegion, activeRegion?.id, allRegions]);

  return (
    <div className="space-y-1 mt-4">
      {regionChatConversations.map((chat: any, index: number) => {
        return (
          <div
            className=""
            key={index}
            onClick={() => onSelectCurrentChat(chat)}
          >
            <ChatGroupConversation
              chat={chat}
              allUsers={allUsers}
              groupName={regionName}
              countUnseenForRegion={countUnseenForRegion}
              currentOpenChat={currentOpenChat}
            />
          </div>
        );
      })}
    </div>
  );
};

export default ChatRegionConversationsList;
