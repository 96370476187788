import InfoMessage from '../InfoMessage';

const Comments = ({
	title = 'Komentari, pitanja...',
	comments,
}: {
	title?: string;
	comments?: any[];
}) => {
	function decodeHtml(html: string) {
		const textArea = document.createElement('textarea');
		textArea.innerHTML = html;
		return textArea.value;
	}

	return (
		<div className='pks-layout-col-md'>
			<h3>{title}</h3>
			<div className='relative'>
				<ul className='pks-layout-col-xl'>
					{comments && comments?.length === 0 ? (
						<li className='pb-4'>
							<InfoMessage
								icon='info'
								message='Ne postoje komentari.'
							/>
						</li>
					) : (
						comments?.map((comment) => (
							<li
								key={comment.id}
								className='pb-4 border-b border-secondary-400'>
								<div className='mb-1'>
									{decodeHtml(comment?.description)}
								</div>
								<div className='flex gap-2 justify-between text-secondary-400'>
									<div className='flex-1'>
										{comment?.createdBy && (
											<span className='block font-bold'>
												{comment.createdBy}
											</span>
										)}
										{comment?.organization && (
											<span className='block'>
												{comment.organization}
											</span>
										)}
									</div>
									<span className='flex items-end'>
										{comment?.date}
									</span>
								</div>
							</li>
						))
					)}
				</ul>
			</div>
		</div>
	);
};

export default Comments;
