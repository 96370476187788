import { useEffect, useState } from 'react';
import { globalWS } from '../../../init';
import { Chat, ChatTabsEnum } from '../../../types/Chat';
import ChatDirectConversation from './ChatDirectConversation';
import { useAuth } from '../../../context/auth-context';

interface ChatDirectConversationsListProps {
  activeTab: ChatTabsEnum;
  conversations: any;
  unseenChats: any;
  onSelectCurrentChat: any;
  // avatarsList: any;
  currentOpenChat: Chat | null;
}

const ChatDirectConversationsList = ({
  activeTab,
  conversations,
  // avatarsList,
  onSelectCurrentChat,
  unseenChats,
  currentOpenChat,
}: ChatDirectConversationsListProps) => {
  const unseenChatIds = new Set(
    Array.isArray(unseenChats) ? unseenChats.map((chat: any) => chat._id) : []
  );
  const { userId } = useAuth();

  const [latestMessages, setLatestMessages] = useState<any[]>([]);

  const transformLatestMessages = (data: any, userId: any) => {
    const arr = data.map((entry: any) => {
      const otherId = entry.memberIds.find(
        (id: any) => String(id) !== String(userId)
      );

      return {
        lastMessage: entry.lastElem.message,
        timestamp: entry.lastElem.timestamp,
        chatPartnerId: otherId ?? userId,
      };
    });
    return arr;
  };

  useEffect(() => {
    const getLatestMessages = async () => {
      try {
        const response = await globalWS.getLastMessages();
        if (!response) return;
        const latestMessagesArr = transformLatestMessages(
          response.data,
          userId
        );
        setLatestMessages(latestMessagesArr);
      } catch (error) {
        console.error('Error fetching latest messages:', error);
      }
    };

    getLatestMessages();

    const handleTextMessage = () => {
      getLatestMessages();
    };

    globalWS.on('textMessageReceived', handleTextMessage);

    return () => {
      globalWS.off('textMessageReceived', handleTextMessage);
    };
  }, [userId]);

  useEffect(() => {
    const renderNewLatestMessages = (message: any) => {
      const latestMessagesArr = transformLatestMessages(message, userId);
      setLatestMessages(latestMessagesArr);
    };

    globalWS.on('latestMessagesReceived', (message: any) => {
      renderNewLatestMessages(message);
    });
    return () => {
      globalWS.off('latestMessagesReceived', renderNewLatestMessages);
    };
  }, [userId]);

  return (
    <div className="space-y-1 mt-4">
      {conversations.map((chat: any, index: number) => {
        // TODO: DELETE?
        if (!chat?.chatPartnerName) return;
        const latestMessageData = latestMessages.find(
          (message) =>
            String(message.chatPartnerId) === String(chat.chatPartnerId)
        );

        return (
          <div key={index} onClick={() => onSelectCurrentChat(chat)}>
            <ChatDirectConversation
              chat={chat}
              // avatarsList={avatarsList}
              currentOpenChat={currentOpenChat}
              isUnseen={
                unseenChatIds.has(Number(chat.chatPartnerId)) &&
                unseenChats.find(
                  (unseenChat: any) =>
                    Number(unseenChat._id) === Number(chat.chatPartnerId)
                )
              }
              lastMessage={latestMessageData?.lastMessage ?? ''}
              timestamp={latestMessageData?.timestamp ?? ''}
            />
          </div>
        );
      })}
    </div>
  );
};

export default ChatDirectConversationsList;
