import {
  keepPreviousData,
  QueryKey,
  queryOptions,
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  useQuery,
  UseQueryOptions,
} from '@tanstack/react-query';
import { AxiosResponse } from 'axios';

import queryClient from '../../../config/tanstackQueryConfig';
import { handleErrors } from '../../../utils';
import RolesApi from './roles.api';

import { ActionResponse } from '../../../types';
import {
  RoleInterface,
  RoleAddInterface,
  RoleEditInterface,
  RolesResponseDataInterface,
  PermissionsResponseDataInterface,
} from './role.types';

const QUERY_KEY = 'roles';
const QUERY_KEY_PERMISSIONS = 'permissions';
const QUERY_KEY_PERMISSIONS_PER_ROLE = 'permissions-per-role';

const api: RolesApi = new RolesApi();

class RolesStore {
  preloadRoles = async (page?: number) => {
    return await queryClient.fetchQuery(rolesQueryObject(page));
  };

  useGetRoles = (page?: number) => {
    return useQuery(rolesQueryObject(page));
  };

  addRole = async ({
    name,
    allPermissions,
    status,
  }: RoleAddInterface): Promise<ActionResponse<RoleInterface>> => {
    try {
      const res = await api.addRole({ name, allPermissions, status });
      const resData = res.data;
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
      return {
        success: true,
        message: resData?.message || 'Rola je uspešno dodata!',
        data: resData,
      };
    } catch (error) {
      const { message, success } = handleErrors(error);
      return { message, success };
    }
  };

  updateRole = async ({
    id,
    name,
    allPermissions,
    status,
  }: RoleEditInterface): Promise<ActionResponse<RoleInterface>> => {
    try {
      const res = await api.updateRole({ id, name, allPermissions, status });
      const resData = res.data;
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
      return {
        success: true,
        message: resData?.message || 'Rola je uspešno izmenjena!',
        data: resData,
      };
    } catch (error) {
      const { message, success } = handleErrors(error);
      return { message, success };
    }
  };

  useGetPermissions = (page?: number) => {
    return useInfiniteQuery(permissionsInfiniteQueryObject(page));
  };

  useGetPermissionsPerRole = (id?: number) => {
    return useQuery(permissionsPerRoleQueryObject(id));
  };

  updateRolePermissions = async (id: number, permissionsIds: number[]) => {
    try {
      const res = await api.updateRolePermissions(id, permissionsIds);
      const resData = res.data;
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY_PERMISSIONS_PER_ROLE, { id }],
      });
      return {
        success: true,
        message: resData?.message || 'Permissions successfully edited!',
        data: resData,
      };
    } catch (error) {
      const { success } = handleErrors(error);
      return {
        message: 'Svaka rola treba imati barem jednu izabranu permisiju.',
        success,
      };
    }
  };
}

/**
 * Returns query options for fetching roles with a standard query.
 *
 * @param {number} [page] - The page number to fetch. Defaults to `undefined` if not provided.
 * @returns {UseQueryOptions<any, Error>} The query options object for use with `useQuery`.
 */
export const rolesQueryObject = (
  page?: number
): UseQueryOptions<RolesResponseDataInterface, Error> =>
  queryOptions({
    queryKey: [QUERY_KEY, { page }] as QueryKey,
    queryFn: async (): Promise<any> => {
      const response: AxiosResponse<RolesResponseDataInterface> =
        await api.getRoles(page);
      return response.data;
    },
    placeholderData: keepPreviousData,
  });

/**
 * Returns query options for fetching permissions with an infinite query.
 *
 * @param {number} [page] - The initial page number to fetch. Defaults to `1` if not provided.
 * @returns {UseInfiniteQueryOptions<any, Error>} The query options object for use with `useInfiniteQuery`.
 */
export const permissionsInfiniteQueryObject = (
  page?: number
): UseInfiniteQueryOptions<any, Error> => ({
  queryKey: [QUERY_KEY_PERMISSIONS, { page }], // Include page in the query key for caching
  queryFn: async ({ pageParam = page ?? 1 }) => {
    const pageToFetch = typeof pageParam === 'number' ? pageParam : page ?? 1;
    const response: AxiosResponse<PermissionsResponseDataInterface> =
      await api.getPermissions(pageToFetch);
    return response.data;
  },
  initialPageParam: page ?? 1, // Use page or default to 1
  getNextPageParam: (lastPage) => {
    const { _page, _page_count } = lastPage;
    return _page < _page_count ? _page + 1 : undefined;
  },
});

/**
 * Returns query options for fetching permissions per role with a standard query.
 *
 * @param {number} id - The id of selected role.
 * @returns {UseQueryOptions<any, Error>} The query options object for use with `useQuery`.
 */
export const permissionsPerRoleQueryObject = (
  id?: number
): UseQueryOptions<any, Error> =>
  queryOptions({
    queryKey: [QUERY_KEY_PERMISSIONS_PER_ROLE, { id }] as QueryKey,
    queryFn: async (): Promise<any> => {
      const response: AxiosResponse<any> = await api.getPermisionsPerRole(id!);
      return response.data;
    },
    placeholderData: keepPreviousData,
    enabled: !!id,
  });

export default RolesStore;
