import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { useParams, useSearchParams } from 'react-router-dom';

import ConfirmDenyDialog from '../../../components/shared/ModalNew/ConfirmDenyDialog';
import DocumentModalContent from './EducationDocumentModalContent';
import Pagination from '../../../components/shared/Pagination';
import Table from '../../../components/shared/Table';
import TransitionLoader from '../../../components/shared/TransitionLoader/TransitionLoader';

import { useModal } from '../../../context/modal-context';
import { TableCellWithIdInterface } from '../../../types';
import { formatDateWithPoints, showToast } from '../../../utils';

import { DocumentProps } from '../services/education.types';

/* import { DOCUMENTATION_TYPE_OPTIONS } from '../../../constants'; */
/* import DropDownSelect from '../../../components/shared/DropdownSelect'; */

import EducationStore from '../services/education.store';
import { EDUCATION_PATH } from '../../../constants';
import NoResultsInfoMessage from '../../../components/shared/InfoMessage/NoResults';

const educationStore: EducationStore = new EducationStore();

const TABLE_HEADINGS = [
  { content: undefined, className: 'w-[50px] min-w-[50px]' },
  { content: 'Dokument' },
  { content: 'Opis dokumenta' },
  { content: 'Datum unosa', className: 'w-[150px]' },
  { content: '', className: 'w-[50px]' }, // edit
  { content: '', className: 'w-[50px]' }, // delete
];

type LoadingDocumentState =
  | false
  | {
      id: number | null;
      type: 'delete' | 'open' | null;
    };

const EducationReports = ({
  slug,
}: {
  slug?: string;
}): React.JSX.Element | null => {
  /* const { slug } = useParams(); */
  const [searchParams, setSearchParams] = useSearchParams();
  const page = Number(searchParams.get('page')) || 1;
  const region = searchParams.get('regionIds[]') || '';
  const organization = searchParams.get('organizationIds[]') || '';

  const { openModal, closeModal, isOpen } = useModal();

  const [currentType, setCurrentType] = useState<string>(
    slug || 'regular_sessions'
  );
  const [searchValue] = useState(searchParams.get('name') || '');

  const [isLoadingFile, setIsLoadingFile] =
    useState<LoadingDocumentState>(false);

  const { data: documentsQueryData, isFetching } =
    educationStore.useGetDocuments(
      page,
      currentType,
      searchValue,
      region,
      organization
    );

  const educationReports = documentsQueryData?._embedded?.documents || [];
  const transformedEducationReports = educationReports.map(
    (report: any) =>
      ({
        id: report.id,
        data: [
          {
            content: '',
            isLoadingFile: report.id === isLoadingFile,
            type: 'doc',
            className: 'cursor-pointer',
            onClick: (e: React.MouseEvent<HTMLButtonElement>) =>
              handleOpenFile(e, report.id, report.type),
          },
          {
            content: report.title || '',
            type: 'heading',
          },
          {
            content: report.details || '',
            type: 'truncate',
          },
          {
            content: formatDateWithPoints(report.date_created.date) || '',
          },
          {
            type: 'edit',
            link: `${EDUCATION_PATH}/${currentType}/${report.id}`,
          },
          {
            type: 'delete',
            onClick: (e: React.MouseEvent<HTMLButtonElement>) =>
              handleDeleteFileModal(e, {
                id: report.id,
                type: report.type,
                title: report.title,
              }),
          },
        ],
      } as TableCellWithIdInterface)
  );

  const totalPages = documentsQueryData?._page_count || 1;

  useEffect(() => {
    setSearchParams(searchParams);
  }, [searchParams, setSearchParams]);

  useEffect(() => {
    if (slug) {
      setCurrentType(slug);
    }
  }, [slug]);

  const handleOpenFile = async (
    e: React.MouseEvent<HTMLButtonElement>,
    id: number,
    type: string
  ) => {
    e.stopPropagation();
    setIsLoadingFile({ id, type: 'open' });

    try {
      const { success, data } = await educationStore.getDocument(id, type);
      if (!success) {
        throw new Error('No URL found');
      }
      window.open(data.fileUrl, '_blank');
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setIsLoadingFile(false);
    }
  };

  const handleDeleteFileModal = async (
    e: React.MouseEvent<HTMLButtonElement>,
    { id, type, title }: DocumentProps
  ) => {
    e.stopPropagation();
    openModal(
      <ConfirmDenyDialog
        infoMessage={`Da li zaista želite da izbrišete dokument ${
          title || ''
        }? Ova akcija je nepovratna i ne može se poništiti.`}
        onConfirm={async () => {
          setIsLoadingFile({ id, type: 'delete' });
          const { success, message } = await educationStore.deleteDocument({
            id,
            type,
          });

          showToast(message, success);
          setIsLoadingFile(false);
        }}
        onDeny={closeModal}
        closeModal={closeModal}
      />,
      'Brisanje dokumenta'
    );
  };

  const handlePageChange = (newPage: number) => {
    if (newPage !== page) {
      searchParams.set('page', newPage.toString());
      setSearchParams(searchParams);
    }
  };

  // const handleFilterChange = (selectedFilter: string) => {
  // 	setCurrentType(selectedFilter);
  // 	searchParams.set('page', '1');
  // 	setSearchParams(searchParams);
  // };

  // const selectedOptionIndex = DOCUMENTATION_TYPE_OPTIONS.findIndex(
  // 	(option) => option.id === currentType
  // );

  const handleRowClick = (
    e: React.MouseEvent<HTMLTableRowElement>,
    id: number | string
  ) => {
    e.stopPropagation();
    const rowItem = educationReports.find((report: any) => report.id === id);
    openModal(
      <DocumentModalContent document={rowItem} onOpenFile={handleOpenFile} />,
      'Dokument'
    );
  };

  const loadingMessage = isLoadingFile
    ? isLoadingFile.type === 'open'
      ? 'Otvaranje...'
      : isLoadingFile.type === 'delete'
      ? 'Brisanje...'
      : 'Učitavanje...'
    : 'Učitavanje...';

  return (
    <div className="pks-layout-col-md">
      <div className="max-w-xs">
        {/* <DropDownSelect
					label='Izaberi tip dokumentacije'
					options={DOCUMENTATION_TYPE_OPTIONS}
					onChange={handleFilterChange}
					selectedOptionIndex={selectedOptionIndex > -1 ? selectedOptionIndex : undefined}
				/> */}
      </div>
      {(isLoadingFile || (isFetching && !isOpen)) &&
        createPortal(
          isOpen ? (
            <div className="relative z-50">
              <TransitionLoader message={loadingMessage} isModalOpen={isOpen} />
            </div>
          ) : (
            <TransitionLoader message={loadingMessage} />
          ),
          document.getElementById('loader')!
        )}

      {!isFetching && transformedEducationReports.length > 0 && (
        <>
          <Table
            headings={TABLE_HEADINGS}
            rows={transformedEducationReports}
            onRowClick={handleRowClick}
          />
          <Pagination
            currentPage={page}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        </>
      )}
      {!isFetching && educationReports.length === 0 && <NoResultsInfoMessage />}
    </div>
  );
};

export default EducationReports;
