import { FieldValues, FormProvider, useForm } from 'react-hook-form';

import Button from '../../../components/shared/Button';
import TextEditor from '../../../components/shared/TextEditor';
import TransitionLoader from '../../../components/shared/TransitionLoader/TransitionLoader';

import { useRegions } from '../../../context/regions-context';
import { CONFIRM_TEXT, SUBMITTING_TEXT } from '../../../constants';

interface InfoFormProps {
  onSubmit: (data: FieldValues) => void;
  defaultValues: { content: string | undefined; regionId: number } | null;
}

const InfoForm: React.FC<InfoFormProps> = ({ onSubmit, defaultValues }) => {
  const { activeRegion } = useRegions();
  const methods = useForm<FieldValues>({
    defaultValues: {
      content: defaultValues?.content || '',
      regionId: defaultValues?.regionId || activeRegion?.id,
    },
  });

  const {
    handleSubmit,
    watch,
    formState: { isSubmitting },
  } = methods;

  return (
    <>
      {isSubmitting && <TransitionLoader message="Kreiranje..." />}
      <FormProvider {...methods}>
        <form className="pks-layout-col-md" onSubmit={handleSubmit(onSubmit)}>
          <div className="pks-layout-col-md">
            <TextEditor
              label="Opis"
              value={watch('content')}
              onChange={(value) => methods.setValue('content', value)}
            />
          </div>
          <div>
            <Button type="submit">
              {isSubmitting ? SUBMITTING_TEXT : CONFIRM_TEXT}
            </Button>
          </div>
        </form>
      </FormProvider>
    </>
  );
};

export default InfoForm;
