import { useNavigate, useParams } from 'react-router-dom';
import Meta from '../../components/layout/Meta';
import PageLayout from '../../layouts/PageLayout';
import Button from '../../components/shared/Button';
import { BACK_TO_LIST, ORGANIZATION_SECTION_PATH } from '../../constants';
import DocumentEntryForm from '../../modules/documents/UI/DocumentsEntryForm';

function DocumentEntryPage() {
  const navigate = useNavigate();
  const { slug, id } = useParams();

  const pageTitle = id
    ? slug === 'decisions'
      ? 'Izmeni odluku'
      : 'Izmeni izveštaj'
    : slug === 'decisions'
    ? 'Unos odluke'
    : 'Unos izveštaja';

  const currentType =
    slug === 'decisions' || slug === 'workreports' ? slug : 'decisions';

  return (
    <>
      <p
        onClick={() => navigate(`${ORGANIZATION_SECTION_PATH}/${slug}`)}
        className="mb-8 flex gap-2 items-center w-fit justify-center b sm:flex-none focus:outline-none group"
      >
        <Button type="button" variant="back" tabIndex={-1}>
          {BACK_TO_LIST}
        </Button>
      </p>
      <Meta title={pageTitle} />
      <PageLayout title={pageTitle}>
        <DocumentEntryForm currentType={currentType} />
      </PageLayout>
    </>
  );
}

export default DocumentEntryPage;
