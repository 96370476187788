import { useEffect, useRef } from 'react';
import { Controller, RegisterOptions } from 'react-hook-form';
import {
  Label,
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
} from '@headlessui/react';

import Icon from '../Icon';

import useInputContext from '../../../hooks/useInputContext';
import { getListboxButtonClassName, handleListboxButtonKeyDown } from './utils';
import { DropDownItemInterface } from '../../../types';

interface DropDownInputProps {
  id: string | number;
  name: string;
  options: DropDownItemInterface[];
  label?: string;
  defaultValue?: any;
  labelToShow?: any;
  placeholder?: string;
  validationRules?: RegisterOptions;
  multiple?: boolean; // New prop for multiple selection
  isLoading?: boolean;
  isDisabled?: boolean;
}

const DropDownSelect: React.FC<DropDownInputProps> = ({
  id,
  name,
  options,
  defaultValue,
  labelToShow = 'label',
  label = 'Izaberi opciju',
  placeholder = 'Izaberi opciju',
  validationRules,
  multiple = false, // Default to false
  isLoading = false,
  isDisabled,
}) => {
  const { control, error, isSubmitted, setValue } = useInputContext(name);

  const listboxButtonRef = useRef<HTMLButtonElement | null>(null);
  const listboxButtonState = error
    ? 'error'
    : isSubmitted
    ? 'submitted'
    : 'initial';

  // Keep the original type of the option id while handling the DOM as string
  const getOptionById = (id: string | number) =>
    options.find(
      (option) => option.id === id || option.id.toString() === id.toString()
    );

  // Normalize the value to always be an array of IDs for multiple, or a single ID for single selection
  const normalizeValue = (value: any) => {
    if (multiple) {
      // If multiple, ensure the value is an array of IDs
      return Array.isArray(value)
        ? value.map((item) => (typeof item === 'object' ? item.id : item))
        : [];
    } else {
      // For single selection, return the ID or an empty string if nothing is selected
      return typeof value === 'object' ? value?.id : value || '';
    }
  };

  const getSelectedOptionsLabel = (selected: any) => {
    if (Array.isArray(selected) && multiple) {
      const selectedLabels = selected
        .map((id: string | number) => {
          const option = getOptionById(id);
          return option ? option.label : '';
        })
        .filter(Boolean) // Filter out any empty strings
        .join(', ');
      return selectedLabels || placeholder;
    } else if (selected !== '') {
      const selectedOption = getOptionById(selected);
      return selectedOption ? selectedOption.label : placeholder;
    }
    return placeholder;
  };

  const handleLabelClick = () => listboxButtonRef.current?.click();

  useEffect(() => {
    if (defaultValue) {
      const normalizedDefaultValue = normalizeValue(defaultValue);
      setValue(name, normalizedDefaultValue);
    }
  }, [defaultValue, control, name]);

  return (
    <div className="w-full">
      <Controller
        name={name}
        control={control}
        defaultValue={defaultValue}
        rules={validationRules || undefined}
        render={({ field: { onChange, onBlur, value } }) => (
          <Listbox
            value={normalizeValue(value)} // Normalize value to always be IDs
            onChange={(newValue) => {
              onChange(newValue); // Update form value
            }}
            multiple={multiple}
          >
            {({ open }) => (
              <>
                <Label
                  onClick={handleLabelClick}
                  className="block mb-1 hover:cursor-pointer"
                >
                  {label}
                </Label>
                <div className="relative">
                  <ListboxButton
                    tabIndex={0}
                    ref={listboxButtonRef}
                    id={id.toString()} // Ensure it's a string for the DOM
                    onBlur={onBlur}
                    disabled={isLoading || isDisabled}
                    onKeyDown={(e) =>
                      handleListboxButtonKeyDown(e, listboxButtonRef)
                    }
                    className={getListboxButtonClassName({
                      open: open,
                      state: listboxButtonState,
                      isDisabled: isDisabled,
                    })}
                  >
                    <span
                      className={`block truncate pr-6 ${
                        isDisabled
                          ? 'text-secondary-300'
                          : !value || value.length === 0
                          ? 'text-secondary-300'
                          : ''
                      }`}
                    >
                      {getSelectedOptionsLabel(normalizeValue(value))}
                    </span>

                    {!isDisabled && (
                      <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-4">
                        {isLoading ? (
                          <Icon
                            name="searchSpinner"
                            className="absolute top-0 right-4 h-full flex items-center [&>svg]:h-5 [&>svg]:w-5 animate-spin"
                          />
                        ) : (
                          <Icon
                            name={'chevronDown'}
                            className={`transition-transform ${
                              open ? 'rotate-180' : ''
                            }`}
                            aria-hidden="true"
                          />
                        )}
                      </span>
                    )}
                  </ListboxButton>
                  <ListboxOptions
                    modal={false}
                    transition
                    className="absolute z-10 overflow-auto w-full max-h-60 mt-2 pt-2 rounded-lg bg-white border border-secondary-300 focus:outline-none data-[closed]:data-[leave]:opacity-0 data-[leave]:transition data-[leave]:duration-150 data-[leave]:ease-in"
                  >
                    {options.map((option, i) => (
                      <ListboxOption
                        key={`${option.id}-${i}`}
                        id={option.id.toString()} // Use the string representation of the id for the DOM
                        value={option.id} // Keep original type for logic
                        className="group mb-1.5 relative select-none py-1.5 px-4 data-[focus]:bg-secondary-100 cursor-pointer"
                      >
                        <span className="block truncate font-normal">
                          {option.label}
                        </span>
                      </ListboxOption>
                    ))}
                  </ListboxOptions>
                </div>
              </>
            )}
          </Listbox>
        )}
      />
      {/* Error */}
      {error && <div className="text-danger">{error.message}</div>}
    </div>
  );
};

export default DropDownSelect;
