import { useEffect, useState } from 'react';

import LazyImage from '../../../components/shared/LazyImage';
import placeholder from '../../../assets/images/placeholders/news.png';
import placeholderCompact from '../../../assets/images/placeholders/news-square.png';
import placeholderLarge from '../../../assets/images/placeholders/news-large.png';

import NewsStore from '../services/news.store';
import { ViewEnum } from '../../../types';

const newsStore: NewsStore = new NewsStore();

const NewsImage = ({
  id,
  title,
  view,
  eagerNumber,
  index,
}: {
  id: number;
  title?: string;
  view: ViewEnum;
  eagerNumber?: number;
  index: number;
}) => {
  const [image, setImage] = useState<string | undefined | null>(undefined);

  const placeholderImage =
    view === ViewEnum.Compact
      ? placeholderCompact
      : view === 'grid' && index === 0
      ? placeholderLarge
      : placeholder;

  const newsImgClassName = `object-cover ${
    image === undefined ? 'animate-pulse' : 'animate-fade-in'
  } ${
    view === ViewEnum.Compact
      ? 'w-[60px] h-[60px]'
      : 'w-full h-full transition-transform transform group-hover:scale-105'
  }`;

  useEffect(() => {
    const fetchImage = async () => {
      const response = await newsStore.useGetNewsPhoto(id);
      if (response.success) {
        setImage(response.data.fileUrl);
      } else {
        setImage(null);
      }
    };

    fetchImage();
  }, [id]);

  return (
    <LazyImage
      alt={title || 'Nedostupna slika'}
      src={image ? image : placeholderImage}
      loading={
        eagerNumber !== undefined && index < eagerNumber ? 'eager' : 'lazy'
      }
      className={newsImgClassName}
    />
  );
};

export default NewsImage;
