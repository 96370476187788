import { useState } from 'react';

import { useDebounce } from './useDebounce';

export const useSearchTerm = ({
	value = '',
	minChars,
}: {
	value?: string;
	minChars: number;
}) => {
	const [searchTerm, setSearchTerm] = useState(value);
	const debouncedSearchTerm = useDebounce(searchTerm, 500);
	const isSearchTermTooShort = searchTerm && searchTerm.length < minChars;
	const searchInputPrompt = `Molimo unesite najmanje ${minChars} karaktera.`;

	return {
		searchTerm,
		setSearchTerm,
		debouncedSearchTerm,
		isSearchTermTooShort,
		searchInputPrompt,
	};
};
