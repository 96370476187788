import { useState } from 'react';
import { createPortal } from 'react-dom';
import {
  FieldValues,
  FormProvider,
  SubmitHandler,
  useForm,
} from 'react-hook-form';
import { toast } from 'react-toastify';

import FileInput, {
  ALLOWED_DOC_TYPES,
} from '../../../components/shared/FileInput';
import Icon from '../../../components/shared/Icon';
import TransitionLoader from '../../../components/shared/TransitionLoader/TransitionLoader';
import UploadProgressModal, {
  useUploadProgress,
} from '../../../components/shared/UploadProgressModal';

import {
  extractMultipleErrors,
  formatDateWithPoints,
  hasNestedObjects,
  showToast,
} from '../../../utils';

import FraudCaseStore from '../services/fraudCases.store';
const fraudCaseStore: FraudCaseStore = new FraudCaseStore();

const FraudCaseSingleDetails = ({
  id,
  data: fraudCaseDetailsData,
}: {
  id?: string;
  data: any;
}) => {
  const {
    uploadDetails,
    handleUploadProgress,
    handleUploadResponse,
    resetUploadProgress,
  } = useUploadProgress();

  //const fraudCaseDetailsData: any = useLoaderData();
  const [isLoadingFile, setIsLoadingFile] = useState(false);
  const [isDeletingFile, setIsDeletingFile] = useState<{
    [key: number]: boolean;
  }>({});

  const inputDateStart = formatDateWithPoints(
    fraudCaseDetailsData?.date_created?.date
  );
  // const inputDateEnd = formatDateWithPoints(
  //   fraudCaseDetailsData?.input_date_end?.date
  // );
  const fraudDateStart = formatDateWithPoints(
    fraudCaseDetailsData?.fraud_date_start?.date
  );
  // const fraudDateEnd = formatDateWithPoints(
  //   fraudCaseDetailsData?.fraud_date_end?.date
  // );

  // Use the `useGetFraudCaseFiles` hook from the fraud cases store to fetch fraud case files data
  const { data: fraudCaseFilesData } = fraudCaseStore.useGetFraudCaseFiles(
    Number(id)
  );
  const allFiles = fraudCaseFilesData?._embedded?.files || [];

  // Use the `useGetFraudCase` hook from the fraud cases store to fetch fraud case type data
  const { data: fraudCaseTypesData } = fraudCaseStore.useGetFraudCasesTypes();
  const sourceType = fraudCaseDetailsData?.source_type;
  const type = fraudCaseTypesData?.[fraudCaseDetailsData?.type_id];

  // Use the `useGetFraudCasesTerminalTypes` hook from the fraud cases fetch fraud case terminal type data
  const { data: fraudCaseTerminalTypesData } =
    fraudCaseStore.useGetFraudCasesTerminalTypes();

  const terminalType =
    fraudCaseTerminalTypesData?.[fraudCaseDetailsData?.terminal_type_id];

  const handleOpenFile = async (fileId: number) => {
    try {
      setIsLoadingFile(true);
      const url = await fraudCaseStore.getFraudCaseFile(fileId);
      if (!url) {
        throw new Error('No URL found');
      }
      window.open(url, '_blank');
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setIsLoadingFile(false);
    }
  };

  const deleteFile = async ({ id, name }: { id: number; name: string }) => {
    try {
      setIsDeletingFile((prev) => ({ ...prev, [id]: true }));
      await fraudCaseStore.deleteFraudCaseFile(id);
      toast.success(`Dokument ${name} je uspešno uklonjen.`);
    } catch (error) {
      toast.error(`Došlo je do greške prilikom uklanjanja dokumenta ${name}.`);
    } finally {
      setIsDeletingFile({});
    }
  };

  const methods = useForm<FieldValues>({
    defaultValues: {
      documents: [],
    },
  });

  const {
    handleSubmit,
    reset,
    setError,
    formState: { isSubmitting },
  } = methods;

  const onSubmit: SubmitHandler<FieldValues> = async (data, e) => {
    e?.preventDefault();

    const payload = {
      documents: [...data.documents],
    };

    const handleError = (message: any) => {
      if (hasNestedObjects(message)) {
        const errorMessages = extractMultipleErrors(message);

        for (const key in errorMessages) {
          setError(key as any, {
            type: 'backend',
            message: errorMessages[key],
          });
        }
      } else {
        showToast(message, false, true);
      }
    };

    const response = await fraudCaseStore.addFraudCaseFiles({
      id: Number(id),
      data: payload,
      onUpload: handleUploadProgress,
    });

    const { success, message } = response;

    if (success) {
      handleUploadResponse({ success: true });
      reset({}, { keepValues: false });
    } else {
      handleUploadResponse({ success: false, code: response.code });

      if (message === null) return;
      handleError(message);

      if (response.code === 400) {
        reset({}, { keepValues: false, keepErrors: true });
      }
    }
  };

  return (
    <>
      {isLoadingFile &&
        !isSubmitting &&
        createPortal(
          <TransitionLoader message={'Otvaranje...'} />,
          document.getElementById('loader')!
        )}
      {uploadDetails.percentage && (
        <UploadProgressModal
          percentage={uploadDetails.percentage}
          error={uploadDetails.error}
          files={uploadDetails.files}
          onReset={resetUploadProgress}
          onSubmit={handleSubmit(onSubmit)}
        />
      )}
      <div>
        <ul className="flex flex-col gap-1">
          <li>
            <span>
              {sourceType === 1 ? 'Issuer' : sourceType === 2 ? 'Acquirer' : ''}
            </span>
          </li>
          <li>
            <span className="font-bold">Vrsta prevare:</span> {type}
          </li>
          {fraudCaseDetailsData?.card_type_names &&
            Object.keys(fraudCaseDetailsData.card_type_names).length > 0 && (
              <li>
                <span className="font-bold">Tip kartice:</span>{' '}
                {Object.values(fraudCaseDetailsData.card_type_names).join(', ')}
              </li>
            )}
          <li>
            <span className="font-bold">Tip terminala:</span> {terminalType}
          </li>
        </ul>

        <div className="pks-divider-dashed"></div>

        <ul className="flex flex-col gap-1">
          <li>
            <span className="font-bold">BIN:</span>{' '}
            {fraudCaseDetailsData?.card_number
              ? '*'.repeat(fraudCaseDetailsData.card_number.length)
              : ''}
          </li>
          <li>
            <span className="font-bold">Acquirer ID:</span>{' '}
            {fraudCaseDetailsData?.acquirer_id}
          </li>
          <li>
            <span className="font-bold">MID:</span> {fraudCaseDetailsData?.mid}
          </li>
          <li>
            <span className="font-bold">TID:</span>
            {fraudCaseDetailsData?.tid}
          </li>
        </ul>

        <div className="pks-divider-dashed"></div>

        <ul className="flex flex-col gap-1">
          <li>
            <span className="font-bold">Datum unosa:</span> {inputDateStart}
          </li>
          <li>
            <span className="font-bold">Datum prevare:</span> {fraudDateStart}
          </li>
        </ul>

        <div className="pks-divider-dashed"></div>

        <ul className="flex flex-col gap-1">
          <li>
            <span className="font-bold">Region:</span>{' '}
            {fraudCaseDetailsData?.regions[0]?.name}
          </li>
        </ul>

        <div className="pks-divider-dashed"></div>

        <div className="flex flex-col gap-1">
          <p className="font-bold">Otpremljeni fajlovi:</p>

          <div className="pks-layout-col">
            <FormProvider {...methods}>
              <form onChange={handleSubmit(onSubmit)}>
                <FileInput
                  showFiles={false}
                  id="documents"
                  name="documents"
                  label="Dodaj dokument/e:"
                  maxSizeMB={100}
                  multiple
                  allowedTypes={ALLOWED_DOC_TYPES}
                />
              </form>
            </FormProvider>
            <p className="text-sm">
              Napomena: Veličina dokumenta ne sme biti veća od 100MB.
            </p>
            {allFiles.length > 0 && (
              <ul>
                {allFiles.map((file: any, index: number) => (
                  <li key={index} className="">
                    <span className="flex items-center gap-4">
                      <button
                        className="cursor-pointer underline"
                        onClick={() => handleOpenFile(file.id)}
                      >
                        {file.filename}
                      </button>
                      {isDeletingFile[file.id] ? (
                        <span>
                          <Icon
                            name="loadingSpinner"
                            className="flex items-center size-6"
                          />
                        </span>
                      ) : (
                        <button
                          onClick={() =>
                            deleteFile({ id: file.id, name: file.name })
                          }
                        >
                          <Icon name="trash" className="flex items-center" />
                        </button>
                      )}
                    </span>
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>

        <div className="pks-divider-dashed"></div>

        <div>
          <p className="font-bold">Napomena/Opis:</p>
          <div>{fraudCaseDetailsData?.description}</div>
        </div>
      </div>
    </>
  );
};

export default FraudCaseSingleDetails;
