import { useLocation, useNavigate, useParams } from 'react-router-dom';

import Meta from '../../components/layout/Meta';
import Button from '../../components/shared/Button';
import NewsForm from '../../modules/news/UI/NewsForm';
import PageLayout from '../../layouts/PageLayout';

import { BACK_TO_NEWS } from '../../constants';

function NewsEntryPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();

  const pageTitle = id ? 'Izmeni vest' : 'Unos nove vesti';

  const handleBack = () => {
    const backTo = location.state?.backTo || '/education/news';
    navigate(backTo);
  };
  return (
    <>
      <span className="mb-8 flex gap-2 items-center w-fit justify-center b sm:flex-none focus:outline-none group">
        <Button onClick={handleBack} type="button" variant="back" tabIndex={-1}>
          {BACK_TO_NEWS}
        </Button>
      </span>
      <Meta title={pageTitle} />
      <PageLayout title={pageTitle}>
        <NewsForm />
      </PageLayout>
    </>
  );
}

export default NewsEntryPage;
