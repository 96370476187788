import { ReactNode } from 'react';

interface PageTitleWithActionsProps {
    title: string;
    children: ReactNode;
    isCol?: boolean;
}

const PageTitleWithActions: React.FC<PageTitleWithActionsProps> = ({
    title,
    children,
    isCol = false,
}) => {
    return (
        <div className={`flex gap-4 ${isCol ? 'flex-col' : 'flex-wrap justify-between items-center'}`}>
            <h1>{title}</h1>
            <div>{children}</div>
        </div>
    );
};

export default PageTitleWithActions;
