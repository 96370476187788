import { useEffect, useState } from 'react';
import { LoaderFunctionArgs, useSearchParams } from 'react-router-dom';

import DropDownSelect from '../../../components/shared/DropdownSelect';
import Pagination from '../../../components/shared/Pagination';
import FraudCasesList from './FraudCasesList';

import { usePaginatedDropdown } from '../../../hooks/usePaginatedDropdown';
import { DropDownItemInterface } from '../../../types';

import FraudCaseStore from '../services/fraudCases.store';
import OrganizationsStore from '../../organizations/services/organizations.store';

const fraudCasesStore: FraudCaseStore = new FraudCaseStore();
const organizationsStore = new OrganizationsStore();

const FraudCases = ({
  onTitleChange,
}: {
  onTitleChange: (title: string) => void;
}) => {
  const [searchParams, setSearchParams] = useSearchParams();

  // Parse URL search parameters
  const page = Number(searchParams.get('page')) || 1;
  const category = searchParams.get('category') || '';
  const organization = searchParams.get('organizationIds[]') || '';

  const { data: fraudCasesData } = fraudCasesStore.useGetFraudCases(
    page,
    category,
    organization
  );

  const fraudCases = fraudCasesData?._embedded?.fraudcases || [];
  const currentPage = fraudCasesData?._page || 1;
  const totalPages = fraudCasesData?._page_count || 1;

  const { data: fraudCaseTypesData, isLoading } =
    fraudCasesStore.useGetFraudCasesTypes();

  const [availableFraudCaseOptions, setAvailableFraudCaseOptions] = useState<
    DropDownItemInterface[]
  >([]);

  useEffect(() => {
    if (!fraudCaseTypesData || isLoading) return;

    const fraudCaseOptions: DropDownItemInterface[] = [
      {
        id: 'all',
        label: 'Sve vrste prevara',
      },
      ...(fraudCaseTypesData
        ? Object.keys(fraudCaseTypesData).map((key) => ({
            id: key,
            label: fraudCaseTypesData[key],
          }))
        : []),
    ];

    setAvailableFraudCaseOptions(fraudCaseOptions);
  }, [fraudCaseTypesData, isLoading]);

  /**
   * Handles page changes in pagination.
   * Updates the URL with the new page number.
   *
   * @param {number} newPage - The new page number to navigate to.
   */
  const handlePageChange = (newPage: number) => {
    if (newPage.toString() !== currentPage) {
      searchParams.set('page', newPage.toString()); // Update the page parameter

      // Conditionally set category if it exists
      if (category) {
        searchParams.set('category', category);
      }
      if (organization) {
        searchParams.set('organizationIds[]', organization);
      }

      // Update the search parameters in the URL
      setSearchParams(searchParams);
    }
  };

  // ORGANIZATIONS
  const useOrganizationsDropdown = () => {
    return usePaginatedDropdown({
      fetchData: (page) => organizationsStore.useGetOrganizations(page, ''),
      extractItems: (data) => {
        return (data?.organizations || []).map((org: any) => ({
          id: org.id.toString(),
          label: org.name,
        }));
      },
    });
  };
  const {
    finalItems: finalOrganizations,
    isLoadingRef: isLoadingOrganizations,
  } = useOrganizationsDropdown();

  const organizationsOptions: DropDownItemInterface[] = [
    {
      id: 'all',
      label: 'Sve institucije',
    },
    ...finalOrganizations,
  ];

  const selectedCategoryIndex = availableFraudCaseOptions.findIndex(
    (option) => option.id === category
  );

  const pageTitle = availableFraudCaseOptions[selectedCategoryIndex]?.label;

  useEffect(() => {
    onTitleChange?.(pageTitle || '');
  }, [pageTitle, onTitleChange]);

  const selectedOrganizationIndex = organizationsOptions.findIndex(
    (option) => option.id === organization
  );

  // Handler for changing the category
  const handleFilterChange = (selectedFilter: string, paramName: string) => {
    searchParams.set('page', '1');
    if (selectedFilter === 'all') {
      searchParams.delete(paramName);
    } else {
      searchParams.set(paramName, selectedFilter);
    }
    setSearchParams(searchParams);
  };

  const handleCategoryChange = (selectedCategory: string) => {
    handleFilterChange(selectedCategory, 'category');
  };

  const handleOrganizationChange = (selectedOrganization: string) => {
    handleFilterChange(selectedOrganization, 'organizationIds[]');
  };

  const handleCategoryDelete = () => {
    searchParams.delete('category');
    searchParams.set('page', '1');
    setSearchParams(searchParams);
  };
  const handleOrganizationDelete = () => {
    searchParams.delete('organizationIds[]');
    searchParams.set('page', '1');
    setSearchParams(searchParams);
  };

  return (
    <>
      {/* Fraud Cases List with Dropdown per Fraud Cases Types  and Pagination */}
      <div className="pks-layout-col-md">
        <div className=" flex lg:flex-row flex-col items-center lg:gap-8 gap-1 mb-2 w-full">
          <span className="lg:w-6/12 w-full">
            <DropDownSelect
              label="Izaberi vrstu prevare"
              options={availableFraudCaseOptions}
              onChange={handleCategoryChange}
              onRemoveFilter={handleCategoryDelete}
              selectedOptionIndex={
                selectedCategoryIndex > -1 ? selectedCategoryIndex : 0
              }
              isLoading={isLoading}
            />
          </span>
          {/* <span className='lg:w-4/12 w-full'>

            <DropDownSelect
              label='Izaberi instituciju'
              options={organizationsOptions}
              isLoading={isLoadingOrganizations.current}
              onChange={handleOrganizationChange}
              onRemoveFilter={handleOrganizationDelete}
              selectedOptionIndex={selectedOrganizationIndex > -1 ? selectedOrganizationIndex : 0}
            />
          </span> */}
        </div>
        <FraudCasesList preview={false} data={fraudCases} />
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      </div>
    </>
  );
};

export default FraudCases;

export const loader = async ({ request }: LoaderFunctionArgs): Promise<any> => {
  const { searchParams } = new URL(request.url);
  const page = Number(searchParams.get('page')) || 1;
  const category = searchParams.get('category') || '';
  const organization = searchParams.get('organizationIds[]') || '';

  await fraudCasesStore.preloadFraudCases(page, category, organization);
  return null;
};
