import {
  useNavigate,
  Form,
  useLoaderData,
  LoaderFunctionArgs,
} from 'react-router-dom';
import {
  useForm,
  FormProvider,
  SubmitHandler,
  FieldValues,
} from 'react-hook-form';

import AvatarInput from '../../../components/shared/AvatarInput';
import Button from '../../../components/shared/Button';
import CheckboxInput from '../../../components/shared/CheckboxInput';
import DropDownSelect from '../../../components/shared/DropdownSelect/DropDownSelectWithIndicators';
import Input from '../../../components/shared/Input';

import {
  EMAIL_VALIDATION_RULES,
  REQUIRED_VALIDATION_RULE,
  SUBMITTING_TEXT,
  CANCEL,
  FORUM_MEMBERS_PATH,
  RESET_PASSWORD_PATH,
} from '../../../constants';
import { useAuth } from '../../../context/auth-context';
import { usePaginatedDropdown } from '../../../hooks/usePaginatedDropdown';
import { ActionResponse } from '../../../types';
import {
  extractMultipleErrors,
  hasNestedObjects,
  showToast,
} from '../../../utils';

import ForumMembersStore from '../services/forumMembers.store';
import OrganizationsStore from '../../organizations/services/organizations.store';
import RolesStore from '../../roles/services/roles.store';
import { useRegions } from '../../../context/regions-context';
import { NavLink } from 'react-router-dom';

const forumMembersStore: ForumMembersStore = new ForumMembersStore();
const organizationsStore: OrganizationsStore = new OrganizationsStore();
const rolesStore: RolesStore = new RolesStore();

const ForumMemberForm = () => {
  const navigate = useNavigate();
  const { availableRegionsDropdownItems } = useRegions();

  const loaderData = useLoaderData() as ActionResponse<any> | null;
  const editFormData = loaderData?.success ? loaderData.data : undefined;

  const { userId, setAvatarUrl } = useAuth();

  const methods = useForm<any>({
    defaultValues: {
      email: editFormData?.email || '',
      organizationId: editFormData?.organization_id || '',
      roleIds: editFormData?.roles || [],
      firstName: editFormData?.first_name || '',
      lastName: editFormData?.last_name || '',
      phone: editFormData?.phone || '',
      regionIds: editFormData?.regions_list?.regions || [],
      mobilePhone: editFormData?.mobile_phone || '',
      organizationFunction: editFormData?.organization_function || '',
      avatar: '',
      allRegions: editFormData?.regions_list?.allRegions || 0,
    },
  });

  const {
    handleSubmit,
    setError,
    watch,
    formState: { isSubmitting },
  } = methods;

  const areAllRegionsSelected = watch('allRegions');

  const useRolesDropdown = (editFormData: any) => {
    return usePaginatedDropdown({
      fetchData: (page) => rolesStore.useGetRoles(page),
      extractItems: (data) => {
        return (data?.roles || []).map((role: any) => ({
          id: role.id,
          label: role.name,
          selected: editFormData?.roles.some(
            (selectedRole: any) => selectedRole.id === role.id
          ),
        }));
      },
      dependencies: [editFormData?.roles],
    });
  };

  const useOrganizationsDropdown = () => {
    return usePaginatedDropdown({
      fetchData: (page) => organizationsStore.useGetOrganizations(page, ''),
      extractItems: (data) => {
        return (data?.organizations || []).map((org: any) => ({
          id: org.id,
          label: org.name,
        }));
      },
    });
  };

  const { finalItems: finalRoles, isLoadingRef: isLoadingRolesRef } =
    useRolesDropdown(editFormData);
  const {
    finalItems: finalOrganizations,
    isLoadingRef: isLoadingOrganizationsRef,
  } = useOrganizationsDropdown();

  // const handleRoles = (roles: any) => {
  // 	// Check if it's an array
  // 	if (Array.isArray(roles)) {
  // 		// Case 1: Array of objects (with `id` field)
  // 		if (roles.length > 0 && typeof roles[0] === 'object') {
  // 			const roleIds = roles.map((role) => role.id);
  // 			return roleIds;
  // 		}
  // 		// Case 2: Simple array of numbers (IDs)
  // 		if (roles.length > 0 && typeof roles[0] === 'number') {
  // 			return roles;
  // 		}

  // 		return []; // Return an empty array if it's an empty array or unexpected content
  // 	}

  // 	return []; // Return an empty array if roles is not an array
  // };

  const onSubmit: SubmitHandler<FieldValues> = async (data) => {
    const handleError = (message: any) => {
      if (hasNestedObjects(message)) {
        const errorMessages = extractMultipleErrors(message);
        for (const key in errorMessages) {
          setError(key as any, {
            type: 'backend',
            message: errorMessages[key],
          });
        }
      } else {
        showToast(message, false, true);
      }
    };

    let formData = new FormData();

    Object.keys(data).forEach((key) => {
      if (!['regionIds', 'roleIds', 'allRegions'].includes(key)) {
        formData.append(key, data[key]);
      }
    });

    // Append the allRegions field as 1 or 0
    formData.append('allRegions', areAllRegionsSelected ? '1' : '0');

    // Append regionIds conditionally if all regions are not selected
    if (!areAllRegionsSelected) {
      data.regionIds.forEach((regionId: any) => {
        formData.append(
          'regionIds[]',
          typeof regionId === 'string' || typeof regionId === 'number'
            ? +regionId
            : regionId.id
        );
      });
    } else {
      availableRegionsDropdownItems?.forEach((region) => {
        formData.append('regionIds[]', region.id.toString());
      });
    }

    data.roleIds.forEach((roleId: any) => {
      formData.append(
        'roleIds[]',
        typeof roleId === 'number' ? roleId : roleId.id
      );
    });

    const response = editFormData?.id
      ? await forumMembersStore.updateForumMember(editFormData.id, formData)
      : await forumMembersStore.addForumMember(formData);

    const { success, message } = response;

    if (success) {
      showToast(message, success);
      if (userId === editFormData?.id && data.avatar) {
        setAvatarUrl(URL.createObjectURL(data.avatar));
        console.log(data.allRegions);
      }
      navigate(FORUM_MEMBERS_PATH);
    } else {
      // TODO check again because its hardcoded...
      if (message === 'Account with email already exists.') {
        setError('email', {
          type: 'manual',
          message: 'Već postoji član sa ovom email adresom',
        });
      }
      handleError(message);
    }
  };

  const handleDelete = async () => {
    const { success, message } = await forumMembersStore.deleteForumMember(
      Number(editFormData.id)
    );

    if (success) {
      showToast(message, success);
      navigate(FORUM_MEMBERS_PATH);
    } else {
      showToast(message, success);
    }
  };

  return (
    <FormProvider {...methods}>
      <Form
        method="post"
        className="pks-layout-col-xl"
        onSubmit={handleSubmit(onSubmit)}
      >
        {/* Avatar Upload */}
        <AvatarInput
          userId={editFormData?.id}
          userName={`${editFormData?.first_name || ''} ${
            editFormData?.last_name || ''
          }`}
          userFunction={editFormData?.organization_function}
        />

        {userId === editFormData?.id && (
          <NavLink to={RESET_PASSWORD_PATH} className="underline">
            Promeni lozinku
          </NavLink>
        )}

        <div className="pks-layout-col-md">
          <div className="pks-layout-col">
            {/* First and Last Name Name Input Fileds */}
            <div className="flex flex-col gap-2 sm:flex-row">
              <div className="flex-1">
                <Input
                  type="text"
                  id="firstName"
                  name="firstName"
                  label="Ime*"
                  placeholder="Unesi ime"
                  autoComplete="off"
                  validationRules={REQUIRED_VALIDATION_RULE}
                />
              </div>

              {/* Last Name Input Field */}
              <div className="flex-1">
                <Input
                  type="text"
                  id="lastName"
                  name="lastName"
                  label="Prezime*"
                  placeholder="Unesi prezime"
                  autoComplete="off"
                  validationRules={REQUIRED_VALIDATION_RULE}
                />
              </div>
            </div>

            {/* Email Input Field */}
            <Input
              type="text"
              id="email"
              name="email"
              label="Email*"
              placeholder="Unesi email"
              autoComplete="off"
              validationRules={EMAIL_VALIDATION_RULES}
            />

            {/* Phone Input Field */}
            <Input
              type="text"
              id="phone"
              name="phone"
              label="Telefon"
              placeholder="Unesi telefon"
              autoComplete="off"
              numeric
            />

            {/* Mobile Phone Input Field */}
            <Input
              type="text"
              id="mobilePhone"
              name="mobilePhone"
              label="Mobilni telefon*"
              placeholder="Unesi mobilni telefon"
              autoComplete="off"
              validationRules={REQUIRED_VALIDATION_RULE}
              numeric
            />

            {/* Roles Dropdown Field */}
            <DropDownSelect
              id="roleIds"
              name="roleIds"
              label="Uloga*"
              placeholder="Izaberi ulogu"
              options={finalRoles}
              isLoading={isLoadingRolesRef.current}
              validationRules={REQUIRED_VALIDATION_RULE}
              multiple
            />

            {/* Organization Function Input Field */}
            <Input
              type="text"
              id="function"
              name="organizationFunction"
              label="Funkcija*"
              placeholder="Unesi funkciju"
              autoComplete="off"
              validationRules={REQUIRED_VALIDATION_RULE}
            />

            {/* Organizations Dropdown Field */}
            <DropDownSelect
              id="organizationId"
              name="organizationId"
              label="Institucija*"
              placeholder="Izaberi instituciju"
              options={finalOrganizations}
              isLoading={isLoadingOrganizationsRef.current}
            />

            <div className="flex flex-col sm:flex-row gap-2 sm:items-center">
              {/* Regions Dropdown Field */}
              <div className="w-full sm:w-auto sm:flex-grow sm:max-w-[60%]">
                <DropDownSelect
                  id="regionIds"
                  name="regionIds"
                  label={`Izbor regiona`}
                  placeholder="Izaberi regione"
                  options={availableRegionsDropdownItems}
                  multiple
                  isDisabled={areAllRegionsSelected}
                />
              </div>
              {/* All Regions Checkbox Field */}
              <div className="mt-2 sm:mt-8">
                <CheckboxInput
                  id="allRegions"
                  label="Izaberi sve regione"
                  name="allRegions"
                />
              </div>
            </div>
          </div>

          {/* Form Actions */}
          <div className="w-full inline-flex gap-4 flex-wrap sm:flex-nowrap">
            {/* Submit Button */}
            <Button className="flex-grow" wide disabled={isSubmitting}>
              {isSubmitting
                ? SUBMITTING_TEXT
                : editFormData
                ? 'Izmeni člana'
                : 'Dodaj člana'}
            </Button>
            {/* Delete Button */}
            {editFormData?.id && (
              <Button
                type="button"
                variant="secondary"
                wide
                onClick={handleDelete}
              >
                Obriši člana
              </Button>
            )}
            {/* Back Button */}
            <span className="flex-auto sm:flex-none focus:outline-none group">
              <Button
                onClick={() => navigate(FORUM_MEMBERS_PATH)}
                type="button"
                variant="secondary"
                wide
                tabIndex={-1}
              >
                {CANCEL}
              </Button>
            </span>
          </div>
        </div>
      </Form>
    </FormProvider>
  );
};

export default ForumMemberForm;

export async function loader({
  params,
}: LoaderFunctionArgs): Promise<ActionResponse<any> | null> {
  const slug = Number(params.slug);

  if (slug) {
    const data = await forumMembersStore.preloadForumMember(slug);
    const roles = await forumMembersStore.getForumMemberRole(slug);

    if (data && data.success) {
      data.data.roles = [...roles.data.roles];
    }

    return data;
  }

  return null;
}
