import { useRef } from 'react';
import {
  useForm,
  useFieldArray,
  FormProvider,
  FieldValues,
} from 'react-hook-form';

import Button from '../../../components/shared/Button';
import DropDownSelectWithSearch from '../../../components/shared/DropdownSelect/DropDownSelectWithSearch';
import Icon from '../../../components/shared/Icon';
import Input from '../../../components/shared/Input';

import {
  CONFIRM_TEXT,
  REQUIRED_VALIDATION_RULE,
  SUBMITTING_TEXT,
} from '../../../constants';
import { useRegions } from '../../../context/regions-context';
import { DropDownItemInterface } from '../../../types';

export interface UserField {
  id?: number;
  position: string;
}

export interface ForumCouncilFormProps {
  onSubmit: (data: FieldValues) => void;
  defaultValues: {
    users: UserField[] | null;
    regionId: number;
  } | null;
  users: DropDownItemInterface[]; // Assumes users have `id` and `label` properties
  isLoading: boolean;
}

const ForumCouncilForm: React.FC<ForumCouncilFormProps> = ({
  defaultValues,
  users,
  isLoading = false,
  onSubmit,
}) => {
  const { activeRegion } = useRegions();
  const methods = useForm({
    defaultValues: {
      users: defaultValues?.users || [{ id: undefined, position: '' }],
      regionId: activeRegion?.id || defaultValues?.regionId,
    },
  });

  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
    watch,
  } = methods;

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'users',
  });

  const fieldRefs = useRef<(HTMLDivElement | null)[]>([]);
  const watchedFields = watch('users') as UserField[];

  const shouldAddEntry = () =>
    watchedFields.every(
      (field) => field.id !== null && field.position.trim() !== ''
    );

  const handleAddEntry = () => {
    append({ id: undefined, position: '' }); // Append a new field with empty values
    setTimeout(() => {
      const lastFieldRef = fieldRefs.current[fields.length];
      lastFieldRef?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }, 0);
  };

  return (
    <FormProvider {...methods}>
      <form
        method="post"
        className="pks-layout-col-md w-full"
        onSubmit={handleSubmit(onSubmit)}
      >
        {/* Council Members Fields */}
        {fields.map((field, index) => (
          <div
            key={field.id || index}
            ref={(el) => (fieldRefs.current[index] = el)}
            className="pks-layout-col"
          >
            {/* Users Dropdown Select */}
            <DropDownSelectWithSearch
              id={`users.${index}.id`}
              name={`users.${index}.id`}
              label={`Član ${index + 1}`}
              placeholder="Izaberi člana"
              options={users}
              validationRules={REQUIRED_VALIDATION_RULE}
              isLoading={isLoading}
            />

            {/* Position Input Field */}
            <Input
              id={`users.${index}.position`}
              name={`users.${index}.position`}
              label={`Pozicija člana ${index + 1}`}
              placeholder="Unesi poziciju"
              validationRules={REQUIRED_VALIDATION_RULE}
            />

            {fields.length > 1 && (
              <div>
                <Button
                  variant="danger"
                  type="button"
                  className="w-full sm:w-auto sm:min-w-48"
                  onClick={() => remove(index)}
                >
                  <div className="flex gap-2 items-center">
                    <Icon name="trashCurrentColor" />
                    <span>Obriši {index + 1}</span>
                  </div>
                </Button>
              </div>
            )}
          </div>
        ))}

        {/* Add Button */}
        <div>
          <Button
            type="button"
            onClick={handleAddEntry}
            className="w-full sm:w-auto sm:min-w-48 focus:outline-none group text-left"
            disabled={!shouldAddEntry()}
            isDisabled={!shouldAddEntry()}
          >
            <div className="flex gap-2 items-center">
              <Icon name="plus" />
              Dodaj člana
            </div>
          </Button>
        </div>

        <Button type="submit" disabled={isSubmitting}>
          {isSubmitting ? SUBMITTING_TEXT : CONFIRM_TEXT}
        </Button>
      </form>
    </FormProvider>
  );
};

export default ForumCouncilForm;
