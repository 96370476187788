import { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';

import ConfirmDenyDialog from '../../../components/shared/ModalNew/ConfirmDenyDialog';
import SquareIcon, {
  SquareIconEnum,
} from '../../../components/shared/Icon/SquareIcon';

import { EDIT_NEWS_PATH } from '../../../constants';
import { useModal } from '../../../context/modal-context';
import { StatusEnum } from '../../../types';
import { showToast } from '../../../utils';

import NewsStore from '../services/news.store';
const newsStore: NewsStore = new NewsStore();

enum ActionTypeEnum {
  Delete = 'delete',
  Deactivate = 'deactivate',
}

const NewsActionDropdown = ({
  id,
  status,
}: {
  id: number;
  status: StatusEnum;
}) => {
  const { openModal, closeModal } = useModal();

  const [isDropdownActive, setIsDropdownActive] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const dropdownClassName = `flex flex-col gap-1 w-40 pt-2 pb-2 bg-white rounded-lg border border-secondary-300`;
  const dropdownItemContentClassName =
    'block w-full px-4 py-1.5  text-left hover:bg-secondary-100 hover:cursor-pointer';

  const handleDelete = async () => {
    const { success, message } = await newsStore.deleteNews(Number(id));
    showToast(message, success);
  };

  const handleStatus = async () => {
    const { success, message } = await newsStore.changeStatus({
      id: Number(id),
      status: status === StatusEnum.Active ? 0 : 1,
    });
    showToast(message, success);
  };

  const handleOpenActionModal = (e: React.MouseEvent, type: ActionTypeEnum) => {
    e.stopPropagation();
    setIsDropdownActive(false);

    const modalData =
      type === ActionTypeEnum.Delete
        ? {
            title: 'Brisanje vesti',
            message:
              'Da li zaista želite da izbrišete vest? Ova akcija je nepovratna i ne može se poništiti.',
            onConfirm: handleDelete,
          }
        : {
            title: status ? 'Deaktiviranje vesti' : 'Aktiviranje vesti',
            message: `Da li zaista želite da ${
              status ? 'deaktivirate' : 'aktivirate'
            } vest?`,
            onConfirm: handleStatus,
          };

    openModal(
      <ConfirmDenyDialog
        infoMessage={modalData.message}
        onConfirm={modalData.onConfirm}
        onDeny={closeModal}
        closeModal={closeModal}
      />,
      modalData.title
    );
  };

  const handleDropdownButtonClick = () => {
    setIsDropdownActive((prevState) => !prevState);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsDropdownActive(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  return (
    <div className="flex flex-col items-end" ref={dropdownRef}>
      <SquareIcon
        onClick={handleDropdownButtonClick}
        type={SquareIconEnum.Dots}
        isActive={isDropdownActive}
      />
      {isDropdownActive && (
        <ul className={dropdownClassName}>
          <li>
            <Link
              to={`${EDIT_NEWS_PATH}/${id}`}
              className={`${dropdownItemContentClassName}`}
              onClick={handleDropdownButtonClick}
            >
              Izmeni
            </Link>
          </li>
          <li>
            <button
              className={`${dropdownItemContentClassName}`}
              onClick={(e) =>
                handleOpenActionModal(e, ActionTypeEnum.Deactivate)
              }
            >
              {status ? 'Deaktiviraj' : 'Aktiviraj'}
            </button>
          </li>
          <li>
            <button
              className={`text-danger ${dropdownItemContentClassName}`}
              onClick={(e) => handleOpenActionModal(e, ActionTypeEnum.Delete)}
            >
              Obriši
            </button>
          </li>
        </ul>
      )}
    </div>
  );
};

export default NewsActionDropdown;
