import { StatisticInterface } from '../services/statistics.types';

/** Reusable class names for Statistics List and Skeleton components */
const STATICTICS_CLASS_NAME = {
  holder: (sidebar: boolean) =>
    `flex flex-wrap gap-4 ${
      sidebar ? '' : 'sm:flex-row lg:flex-col xl:flex-row flex-wrap'
    }`,
  item: (index: number) =>
    `p-4 pb-6 rounded-lg bg-secondary-100 ${
      index < 2 ? ' flex-1  w-full lg:w-1/2' : 'w-full '
    }`,
  period: 'inline-block mb-4 px-4 py-2 rounded-lg bg-secondary-200 h3',
  value: 'block h1 text-primary-300',
};

/**
 * StatisticsListProps Interface
 *
 * Defines the props for the StatisticsList component.
 * @interface
 * @property {StatisticInterface[]} data - An array of statistics data to be displayed in the list.
 * @property {boolean} [sidebar] - Optional flag indicating whether the list is displayed in a sidebar (default: `false`).
 */
export interface StatisticsListProps {
  data: StatisticInterface;
  sidebar?: boolean;
}

/** Statistics List Component
 * Displays a list of statistics with periods, values, and descriptions.
 * @param {StatisticsListProps} props - The props for the component.
 * @returns {React.JSX.Element} - The rendered component.
 */
const StatisticsList: React.FC<StatisticsListProps> = ({
  data,
  sidebar = false,
}: StatisticsListProps): React.JSX.Element => {
  const transformedData = [
    {
      id: 'stastistics-2',
      period: 'u 24 sata',
      value: data.last24_hours,
    },
    {
      id: 'stastistics-1',
      period: 'u 7 dana',
      value: data.last7_days,
    },
    {
      id: 'stastistics-3',
      period: 'Ukupno',
      value: data.total,
    },
  ];

  return (
    <>
      <h3 className="h2">Slučajevi u brojevima</h3>
      <div className={STATICTICS_CLASS_NAME.holder(sidebar)}>
        {transformedData.map((item, index) => (
          <div key={index} className={STATICTICS_CLASS_NAME.item(index)}>
            <span className={STATICTICS_CLASS_NAME.period}>{item.period}</span>
            <span className={STATICTICS_CLASS_NAME.value}>{item.value}</span>
          </div>
        ))}
      </div>
    </>
  );
};

export default StatisticsList;

/** Statistics Skeleton Component
 * Provides a placeholder for loading states in the Statistics List.
 * @param {object} props - The props for the component.
 * @param {number} [props.count=3] - The number of skeleton items to display.
 * @param {boolean} [props.sidebar=false] - Determines if the skeleton is displayed in a sidebar.
 * @returns {React.JSX.Element} - The rendered skeleton component.
 */
export const StatisticsSkeleton = ({
  count = 3,
  sidebar = false,
}: {
  count?: number;
  sidebar?: boolean;
}): React.JSX.Element => {
  const skeletonItems = Array.from({ length: count }, (_, index) => {
    return (
      <li key={index} className={`animate-pulse ${STATICTICS_CLASS_NAME.item}`}>
        <span
          className={`${STATICTICS_CLASS_NAME.period} min-w-10 min-h-[44px]`}
        ></span>
        <span className={`${STATICTICS_CLASS_NAME.value} min-h-10`}></span>
        <span className="flex flex-col justify-center h-[47px] space-y-2">
          <span className="block h-4 bg-gray-300 rounded"></span>
          <span className={`block h-4 bg-gray-300 rounded w-3/4`}></span>
        </span>
      </li>
    );
  });

  return (
    <ul className={STATICTICS_CLASS_NAME.holder(sidebar)}>{skeletonItems}</ul>
  );
};
