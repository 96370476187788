import React, { useEffect, useRef } from 'react';
import Quill from 'quill';
import 'quill/dist/quill.snow.css';

interface TextEditorProps {
  id?: string;
  label?: string;
  value?: string;
  onChange: (payload: string) => void;
}

const TextEditor: React.FC<TextEditorProps> = ({
  id = undefined,
  label,
  value = '',
  onChange,
}) => {
  const editorRef = useRef<HTMLDivElement | null>(null);
  const quillRef = useRef<Quill | null>(null);

  useEffect(() => {
    if (editorRef.current && quillRef.current === null) {
      quillRef.current = new Quill(editorRef.current, {
        theme: 'snow',
        modules: {
          toolbar: [
            [{ header: [1, 2, 3, 4, 5, false] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [
              { color: ['#e00000', '#FFFFFF', '#000000'] },
              { background: ['#e00000'] },
            ],
            [
              { list: 'ordered' },
              { list: 'bullet' },
              { indent: '-1' },
              { indent: '+1' },
            ],
            ['link', 'image'],
            ['clean'],
          ],
        },
      });
    }
  }, []);

  useEffect(() => {
    if (quillRef.current) {
      // Update content only if the value has changed
      if (value && value !== quillRef.current.root.innerHTML) {
        quillRef.current.root.innerHTML = value;
      }

      // Ensure the "text-change" listener is attached
      quillRef.current.off('text-change'); // Remove existing listener to avoid duplicates
      quillRef.current.on('text-change', () => {
        onChange(quillRef.current?.root.innerHTML || '');
      });
    }
  }, [onChange, value]);

  return (
    <div className="pks-quill">
      {label && (
        <label className="block mb-1 hover:cursor-pointer">{label}</label>
      )}
      <div
        ref={editorRef}
        id={id?.toString() || undefined}
        className="pks-quill min-h-[300px] max-h-[300px]"
      />
    </div>
  );
};

export default TextEditor;
