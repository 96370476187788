import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import ForumMembersStore from '../../forumMembers/services/forumMembers.store';
import SearchInputNoBorder from '../../../components/shared/SearchInputNoBorder';
import ChatUserIcon from './ChatUserIcon';

const forumMembersStore = new ForumMembersStore();

const ChatSearchBar = ({ onSelectCurrentChat, conversations }: any) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchValue, setSearchValue] = useState(
    searchParams.get('name') || ''
  );

  const { data: forumMembersQueryData, isFetching } =
    forumMembersStore.useGetForumMembers(1, searchValue);
  const forumMembers = forumMembersQueryData?._embedded?.users || [];

  const handleSearch = (debouncedValue: string): void => {
    setSearchValue(debouncedValue);
    searchParams.set('name', debouncedValue);
    setSearchParams(searchParams);
  };

  const createNewChat = (id: any, firstName: any, lastName: any) => {
    const existingChat = conversations.find(
      (conversation: any) => +conversation.chatPartnerId === +id
    );

    if (existingChat) {
      onSelectCurrentChat(existingChat);
    } else {
      const newChat = {
        userId: id,
        firstName,
        lastName,
      };
      onSelectCurrentChat(newChat);
    }
  };

  useEffect(() => {
    return () => {
      searchParams.delete('name');
      setSearchParams(searchParams);
    };
  }, [searchParams, setSearchParams]);

  return (
    <>
      <SearchInputNoBorder
        value={searchValue}
        onSearch={handleSearch}
        autofocus
        isFetching={isFetching}
      />

      {searchValue && (
        <div className="rounded-lg max-h-[256px] overflow-auto scrollbar bg-white z-10  border border-secondary-200  h-auto space-y-1  w-11/12  absolute top-[76px] left-1/2 -translate-x-1/2">
          {isFetching && <p className="p-2">Pretraga...</p>}
          {!isFetching &&
            forumMembers.length > 0 &&
            forumMembers.map((member: any) => (
              <div
                onClick={() =>
                  createNewChat(
                    String(member.id),
                    member.first_name,
                    member.last_name
                  )
                }
                key={member.id}
                className="flex items-center gap-2 hover:bg-secondary-100 hover:cursor-pointer p-2"
              >
                <ChatUserIcon
                  userName={`${member.first_name + ' ' + member.last_name}`}
                />
                <p className="font-bold " key={member.id}>
                  {member.first_name + ' ' + member.last_name}{' '}
                  <span className="font-normal text-secondary-400">
                    {' ' + member.organization.name}
                  </span>
                </p>
              </div>
            ))}
          {forumMembers.length === 0 && !isFetching && (
            <p className=" p-2">Forum member not found</p>
          )}
        </div>
      )}
    </>
  );
};

export default ChatSearchBar;
