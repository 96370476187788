export const EDUCATION_HEADINGS = [
  {
    id: 'regular_sessions',
    title: 'Redovne sednice Foruma',
  },
  {
    id: 'regional_sessions',
    title: 'Regionalne sednice foruma',
  },
  {
    id: 'conferences',
    title: 'Konferencije sa učešćem Foruma',
  },
  {
    id: 'seminars_by_forum',
    title: 'Seminari u organizaciji Foruma',
  },
  {
    id: 'seminars_participation',
    title: 'Seminari sa učešćem Foruma',
  },
  {
    id: 'education',
    title: 'Edukacije u organizaciji Foruma',
  },
  {
    id: 'east',
    title: 'EAST',
  },
  {
    id: 'laws',
    title: 'Zakoni i propisi',
  },
];
