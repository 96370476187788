import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from 'react';

import queryClient from '../config/tanstackQueryConfig';
import {
  AvailableRegionInterface,
  AvailableRegionsInterface,
  DropDownItemInterface,
} from '../types';
import { getAvailableRegionsStateFromLocalStorage } from '../utils';
import { ACTIVE_REGION_KEY, REGIONS_KEY } from '../constants';
import { useAuth } from './auth-context';
import { globalWS } from '../init';

// Define the context interface
interface RegionsContextType {
  availableRegionsData: AvailableRegionsInterface | undefined;
  setAvailableRegionsData: React.Dispatch<
    React.SetStateAction<AvailableRegionsInterface | undefined>
  >;
  availableRegionsDropdownItems: DropDownItemInterface[];
  activeRegion: AvailableRegionInterface | undefined;
  setActiveRegion: (region: AvailableRegionInterface) => void;
  isDropdownActive: boolean;
  onCloseDropdown: () => void;
  toggleDropdown: () => void;
}

const RegionsContext = createContext<RegionsContextType | undefined>(undefined);

interface RegionsProviderProps {
  children: ReactNode;
}

// Create a provider for the context
const RegionsProvider: React.FC<RegionsProviderProps> = ({ children }) => {
  const { isAuth } = useAuth();
  const [availableRegionsData, setAvailableRegionsData] = useState<
    AvailableRegionsInterface | undefined
  >(undefined);
  const [activeRegion, setActiveRegion] = useState<
    AvailableRegionInterface | undefined
  >(undefined);
  const [isDropdownActive, setIsDropdownActive] = useState(false);

  const availableRegionsDropdownItems: DropDownItemInterface[] =
    availableRegionsData?.regions.map((region) => ({
      id: region.id,
      label: region.name,
    })) || [];

  useEffect(() => {
    if (!isAuth) {
      setAvailableRegionsData(undefined);
      setActiveRegion(undefined);
      return;
    }

    const storedRegions = getAvailableRegionsStateFromLocalStorage();

    if (storedRegions) {
      const availableRegionsList = storedRegions.regions;

      setActiveRegion(
        availableRegionsList.find(
          (region) => region.id === storedRegions.activeRegion
        ) || availableRegionsList[0]
      );
      setAvailableRegionsData(storedRegions);
    } else {
      console.log('No regions found in localStorage.');
    }
  }, [isAuth]);

  const toggleDropdown = () => {
    setIsDropdownActive((prevState) => !prevState);
  };

  const handleRegionSelect = ({ id, name, init }: AvailableRegionInterface) => {
    // Update the active region
    setActiveRegion({ id, name });
    setIsDropdownActive(false);

    // Update the regions in localStorage
    const storedRegions = getAvailableRegionsStateFromLocalStorage();
    if (storedRegions) {
      const updatedRegions = {
        ...storedRegions,
        activeRegion: +id,
      };

      // Save the updated regions back to localStorage
      localStorage.setItem(REGIONS_KEY, JSON.stringify(updatedRegions));
      localStorage.setItem(ACTIVE_REGION_KEY, JSON.stringify(+id));
      setTimeout(() => {
        !init && queryClient.invalidateQueries(); // Invalidate the queries after region change
      }, 0);
    }
  };

  const handleClose = () => setIsDropdownActive(false);

  return (
    <RegionsContext.Provider
      value={{
        availableRegionsData,
        setAvailableRegionsData,
        availableRegionsDropdownItems,
        activeRegion,
        setActiveRegion: handleRegionSelect,
        isDropdownActive,
        onCloseDropdown: handleClose,
        toggleDropdown,
      }}
    >
      {children}
    </RegionsContext.Provider>
  );
};

// Create a custom hook to use the context
const useRegions = (): RegionsContextType => {
  const context = useContext(RegionsContext);
  if (!context) {
    throw new Error('useRegions must be used within a RegionsProvider');
  }
  return context;
};

export { RegionsProvider, useRegions };
