import { useState } from 'react';

import Icon from '../Icon';
import ToggleSwitch from '../ToggleSwitch';

interface CheckboxAccordionProps {
  groupId: string;
  label: string;
  labelChecked: boolean;
  labelDisabled?: boolean;
  disabledText?: string;
  items: {
    id: number;
    name: string;
    checked?: boolean;
    disabled?: boolean;
    disabledText?: string;
  }[];
  onCheckboxChange?: (
    groupId: string,
    itemId: number,
    checked: boolean
  ) => void;
  onLabelCheckboxChange?: (groupId: string, checked: boolean) => void;
}

const CheckboxAccordion: React.FC<CheckboxAccordionProps> = ({
  groupId,
  label,
  labelChecked,
  labelDisabled = false,
  disabledText,
  items,
  onCheckboxChange,
  onLabelCheckboxChange,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  /*
   * Handlers
   */

  // Toggles Accordion
  const handleToggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  // Handles `Label` Checkbox
  const handleLabelCheckboxChange = (checked: boolean) => {
    if (onLabelCheckboxChange) {
      onLabelCheckboxChange(groupId, checked);
    }
  };

  // Handles `Item` Checkbox
  const handleItemCheckboxChange = (itemId: number, checked: boolean) => {
    if (onCheckboxChange) {
      onCheckboxChange(groupId, itemId, checked);
    }
  };

  return (
    <div className="pks-card-bg pks-card-bg-hover overflow-hidden rounded-lg cursor-pointer group">
      <div className="ml-[6px] bg-white">
        <div
          className="flex justify-between pl-[22px] pr-4 py-3 cursor-pointer w-full group"
          onClick={handleToggleAccordion}
        >
          <ToggleSwitch
            checked={labelChecked}
            id={`${groupId}-label-toggle`}
            isTitle
            label={label}
            disabled={labelDisabled}
            disabledText={disabledText}
            onChange={handleLabelCheckboxChange}
          />
          {isOpen ? (
            <Icon className="group-hover:text-primary-300" name="minus" />
          ) : (
            <Icon className="group-hover:text-primary-300" name="plus" />
          )}
        </div>
        {isOpen && (
          <ul className="pl-[22px] pr-4 py-3">
            {items.map((item) => (
              <li key={item.id}>
                <ToggleSwitch
                  checked={item.checked || false}
                  id={`${groupId}-item-${item.id}`}
                  label={item.name}
                  onChange={(checked) =>
                    handleItemCheckboxChange(item.id, checked)
                  }
                  disabled={item.disabled}
                  disabledText={item.disabledText}
                />
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

export default CheckboxAccordion;
