import { UploadFileInterface, UploadPercentage } from '../../../types';
import http from '../../../utils/http';

import { DocumentProps } from './education.types';

class EducationApi {
  getEducationDocument = async ({ id, type }: DocumentProps) => {
    return http.get(`/documents/${type}/${id}`);
  };

  getDocuments = async (
    page?: number,
    type?: string,
    searchTerm?: string,
    region?: string,
    organization?: string,
    rowsPerPage?: any
  ) => {
    const params = new URLSearchParams();

    if (page) {
      params.append('page', page.toString());
    }
    if (searchTerm) {
      params.append('title', searchTerm);
    }
    if (region) {
      params.append('regionIds[]', region);
    }
    if (organization) {
      params.append('organizationIds[]', organization);
    }
    if (rowsPerPage) {
      params.append('rowsPerPage', rowsPerPage);
    }

    const url = `/documents/${type}${
      params.toString() ? `?${params.toString()}` : ''
    }`;

    return http.get(url);
  };

  getDocument = async (fileId: number, type: string) => {
    return http.get(`/documents/${type}/${fileId}/file`, {
      responseType: 'blob',
    });
  };

  storeDocument({
    id,
    data,
    type,
    onUpload,
  }: {
    id?: number;
    data: FormData;
    type: string;
    onUpload?: (
      percentage: UploadPercentage,
      files?: UploadFileInterface[]
    ) => void;
  }) {
    const files: UploadFileInterface[] = [];

    const documents = data.getAll('document') as File[];

    documents.length > 0 &&
      documents.forEach((file: File) => {
        const sizeInMB = file.size / 1024 / 1024;
        if (!file) return;
        files.push({
          name: file.name,
          size: sizeInMB,
        });
      });

    const url = id ? `/documents/${type}/${id}` : `/documents/${type}`;

    if (files.length === 0 || !onUpload) {
      return http.post(url, data);
    }

    let filesAdded = false;

    return http.post(url, data, {
      onUploadProgress: (progressEvent) => {
        if (
          typeof progressEvent.loaded !== 'undefined' &&
          typeof progressEvent.total !== 'undefined'
        ) {
          if (!filesAdded) {
            filesAdded = true;
            onUpload?.(0, [...files]);
          }

          const percentCompleted = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );

          onUpload?.(percentCompleted, files);
        } else {
          onUpload?.(null, []);
        }
      },
    });
  }

  deleteDocument({ id, type }: { id: number; type: string }) {
    return http.delete(`/documents/${type}/${id}`);
  }
}

export default EducationApi;
