import { useState } from 'react';

import Button from '../../../components/shared/Button';

import { DOCUMENTATION_TYPE_OPTIONS } from '../../../constants';
import { formatDateWithPoints } from '../../../utils';

const OPEN_FILE_BUTTON_TEXT = {
  idle: 'Otvori fajl',
  loading: 'Otvaranje...',
};

const EMPTY_PROP_TEXT = '/';

const DocumentModalContent = ({
  document,
  onOpenFile,
  showType = false,
}: {
  showType?: boolean;
  document: any;
  onOpenFile: (
    e: React.MouseEvent<HTMLButtonElement>,
    id: number,
    type: string
  ) => Promise<void>;
}) => {
  const [openBtnTxt, setOpenBtnTxt] = useState(OPEN_FILE_BUTTON_TEXT.idle);

  const documentLabel = DOCUMENTATION_TYPE_OPTIONS.find(
    (option) => option.id === document.type
  )?.label;

  const handleClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
    setOpenBtnTxt(OPEN_FILE_BUTTON_TEXT.loading);
    onOpenFile(e, document.id, document.type).finally(() => {
      setOpenBtnTxt(OPEN_FILE_BUTTON_TEXT.idle);
    });
  };

  const className = 'h3 font-normal flex flex-col gap-1 ';
  return (
    <>
      <ul className="space-y-3">
        <li className={className}>
          <b>Naslov</b> {document?.title || EMPTY_PROP_TEXT}
        </li>
        <li className={className}>
          <b>Opis</b> {document.details || EMPTY_PROP_TEXT}
        </li>
        {showType && (
          <li className={className}>
            <b>Tip dokumenta</b> {documentLabel || EMPTY_PROP_TEXT}
          </li>
        )}
        <li className={className}>
          <b>Kreirano od</b>{' '}
          {document.user_created?.firstName +
            ' ' +
            document.user_created?.lastName || EMPTY_PROP_TEXT}
        </li>
        <li className={className}>
          <b>Datum unosa</b>{' '}
          {formatDateWithPoints(document.date_created.date) || EMPTY_PROP_TEXT}
        </li>

        <li className={className}>
          <b>{document.regions.length <= 1 ? 'Region' : 'Regioni'}</b>
          <div className="flex flex-wrap gap-2">
            {document.regions.length === 0 ? (
              <p>{EMPTY_PROP_TEXT}</p>
            ) : (
              document.regions.map((region: any, i: number) => (
                <p
                  key={i}
                  className="bg-secondary-100 w-fit py-1 px-2 rounded-lg"
                >
                  {region.name}
                </p>
              ))
            )}
          </div>
        </li>
      </ul>
      <div className="mt-8">
        <Button className="min-w-[200px]" onClick={handleClick}>
          {openBtnTxt}
        </Button>
      </div>
    </>
  );
};

export default DocumentModalContent;
