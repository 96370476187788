import useInputContext from '../../../hooks/useInputContext';

interface CheckboxInputProps {
  id: string | number;
  name: string;
  label: string;
  defaultChecked?: boolean;
}

/**
 * CheckboxInput component for rendering a styled checkbox input with a label.
 *
 * This component integrates with the `useInputContext` hook, allowing it to
 * register the checkbox input with form handling logic. It supports an optional
 * `defaultChecked` state, enabling it to start as checked or unchecked based
 * on the provided props.
 *
 * @component
 * @param {CheckboxInputProps} props - The props object for this component.
 * @param {string} props.id - The unique identifier for the checkbox input.
 * @param {string} props.name - The name attribute for the checkbox input, used for form submission.
 * @param {string} props.label - The label text displayed alongside the checkbox.
 * @param {boolean} [props.defaultChecked=false] - Determines whether the checkbox is checked by default.
 *
 * @returns {JSX.Element} The rendered checkbox input component.
 */
const CheckboxInput = ({
  id,
  label,
  name,
  defaultChecked = false,
}: CheckboxInputProps): JSX.Element => {
  const { register } = useInputContext(name);

  const stringId = typeof id === 'number' ? id.toString() : id;
  return (
    <div className="pks-cbx">
      <input
        type="checkbox"
        id={stringId}
        {...register(name)}
        defaultChecked={defaultChecked}
        className="inp-cbx"
      />
      <label className="cbx" htmlFor={stringId} tabIndex={0}>
        <span>
          <svg width="12px" height="10px">
            <use xlinkHref="#check"></use>
          </svg>
        </span>
        <span>{label}</span>
      </label>
      <svg className="inline-svg">
        <symbol id="check" viewBox="0 0 12 10">
          <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
        </symbol>
      </svg>
    </div>
  );
};

export default CheckboxInput;
