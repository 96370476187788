import { EventEmitter } from 'events';

enum ServiceRequestType {
  UnseenCount = 'unseen_count',
  UnseenCountBySender = 'unseen_count_by_sender',
  UnseenCountForRegion = 'unseen_count_for_region',
  ChatHistory = 'chat_history',
  SetSeen = 'set_seen',
  LastMessages = 'last_messages',
  GetRegionChat = 'get_region_chat',
  CreateChat = 'create_chat',
  KeepAlive = 'keep_alive',
}

interface RequestData {
  page?: number;
  id?: any;
  regionId?: any;
  memberIds?: any;
}

interface MessageLayout {
  jwt: string;
  authid: string;
  type: 'service';
  request: ServiceRequestType;
  from?: any;
  data?: RequestData;
  chatType?: 'individual' | 'group';
  chatId?: string;
  memberIds?: any;
  tmpUniqueId?: string;
}

class WebSocketStore extends EventEmitter {
  private socket: WebSocket | null = null;
  private isConnected: boolean = false;
  private isLoggedIn: boolean = false;
  private jwtToken: string | null = null;
  private regionId: string | null = null;
  private authId: string | null = null;
  private manualDisconnect: boolean = false;

  private reconnectAttempts: number = 0;
  private maxReconnectAttempts: number = 5;
  private reconnectDelay: number = 5000;

  messages: any[] = [];
  currentChat: any = { data: { messages: [] } };
  allUnseenMessagesCount: number = 0;
  unseenMessagesBySenderCount: any = [];
  unseenMessagesForRegionCount: any = [];
  latestMessages: any = [];
  regionChats: any = [];

  getConnectionStatus(): boolean {
    return this.isConnected;
  }

  connect(jwt: string, authId: string, regionId: string): Promise<void> {
    this.manualDisconnect = false;

    return new Promise((resolve, reject) => {
      if (this.isConnected) {
        // console.log('WebSocket is already connected.');
        resolve();
        return;
      }

      this.jwtToken = jwt;
      this.authId = authId;
      this.regionId = regionId;
      this.socket = new WebSocket(`${process.env.REACT_APP_WEB_SOCKET_URL}`);

      this.socket.onopen = () => {
        this.isConnected = true;
        this.reconnectAttempts = 0;
        this.getConnectionStatus();
        this.waitForOpenAndSendLoginMessage();
        this.startKeepAlive();
      };

      this.socket.onmessage = (event) => {
        const message = this.parseMessage(event.data);
        if (typeof message === 'string' && message === 'Access denied') {
          // console.error('Access denied received. Closing socket.');
          // this.socket?.close();
          this.reconnect();

          // this.manualDisconnect = true;
          return;
        }

        if (message) {
          switch (message.type) {
            case 'message':
              if (message) {
                this.emit('textMessageReceived', message);
                this.getUnseenCount();
                this.getUnseenCountForRegion();
                this.getUnseenCountBySender();
              }
              break;

            case 'service':
              this.emit('chatConnectionStatusListener', this.isLoggedIn);

              if (message?.message === ServiceRequestType.LastMessages) {
                this.latestMessages = message.data;
                this.emit('latestMessagesReceived', this.latestMessages);
              }

              if (message?.message === ServiceRequestType.ChatHistory) {
                this.emit('chatHistoryReceived', message.data.messages);
              }

              if (message?.message === ServiceRequestType.CreateChat) {
                this.emit('createChatReceived', message.data);
              }

              if (message?.message === ServiceRequestType.GetRegionChat) {
                this.emit('regionChatsReceived', message.data);
                this.regionChats = message.data;
              }

              if (
                message?.message === ServiceRequestType.UnseenCountForRegion
              ) {
                this.unseenMessagesForRegionCount = message.data.unseen;
                this.emit(
                  'messagesUnseenForRegionListener',
                  this.unseenMessagesForRegionCount
                );
                this.allUnseenMessagesCount =
                  this.allUnseenMessagesCount +
                  this.unseenMessagesForRegionCount;

                this.emit(
                  'allUnseenMessagesListener',
                  this.allUnseenMessagesCount
                );
              }

              if (message?.data?.unseen && !message.message) {
                this.allUnseenMessagesCount =
                  message.data.unseen + this.unseenMessagesForRegionCount;
                this.emit(
                  'allUnseenMessagesListener',
                  this.allUnseenMessagesCount
                );
                this.getUnseenCountBySender();
              }

              if (message?.message === ServiceRequestType.UnseenCount) {
                this.allUnseenMessagesCount = message.data.unseen;

                this.emit(
                  'allUnseenMessagesListener',
                  this.allUnseenMessagesCount
                );
                this.getUnseenCountBySender();

                // if (this.unseenMessagesBySenderCount.length === 0) {
                //   this.getUnseenCountBySender();
                // }
              }

              if (message?.message === ServiceRequestType.UnseenCountBySender) {
                this.unseenMessagesBySenderCount = message.data;
                this.emit(
                  'messagesUnseenBySenderListener',
                  this.unseenMessagesBySenderCount
                );
              }

              break;

            case 'login':
              if (message?.type === 'login' && message.status === true) {
                this.isLoggedIn = message.status;

                this.allUnseenMessagesCount = message.data.unseen;
                this.getLastMessages();
                this.getRegionChat();
                this.getUnseenCountForRegion();
              }
              break;

            default:
              // console.log('No handler found for message type:', message.type);
              break;
          }
        }
      };

      this.socket.onclose = () => {
        this.isConnected = false;
        this.isLoggedIn = false;
        // console.log('WebSocket connection closed');
        this.reconnect();
      };

      this.socket.onerror = (error) => {
        console.error('WebSocket error:', error);
        reject(error);
        this.reconnect();
      };
    });
  }

  private waitForOpenAndSendLoginMessage(): void {
    const checkReadyState = setInterval(() => {
      if (this.socket && this.socket.readyState === WebSocket.OPEN) {
        this.sendLoginMessage();
        clearInterval(checkReadyState);
      }
    }, 100);
  }

  async getCurrentChat(
    token: any,
    fromId: any,
    chatId: any,
    page: any
  ): Promise<void> {
    const chatHistory = await this.sendMessage({
      jwt: token,
      type: 'service',
      authid: this.authId,
      from: fromId,
      chatId: chatId,
      request: ServiceRequestType.ChatHistory,
      data: { page: page },
    });

    this.currentChat = chatHistory;
    return this.currentChat;
  }

  async sendLoginMessage(): Promise<void> {
    if (this.socket && this.isConnected && this.jwtToken && this.authId) {
      const loginMessage = {
        jwt: this.jwtToken,
        authId: this.authId,
        type: 'login',
      };
      this.socket.send(JSON.stringify(loginMessage));
    } else {
      console.error(
        'Cannot send login message: WebSocket is not open or JWT/authId is missing'
      );
    }
  }

  startKeepAlive() {
    if (this.socket && this.isConnected && this.jwtToken && this.authId) {
      const keepAliveMessage = {
        jwt: this.jwtToken,
        type: 'service',
        authid: this.authId,
        request: ServiceRequestType.KeepAlive,
        data: {},
      };
      setInterval(() => {
        if (this.socket && this.isConnected && this.jwtToken && this.authId) {
          this.socket.send(JSON.stringify(keepAliveMessage));
        }
      }, 40000);
    } else return;
  }

  parseMessage(data: string) {
    try {
      return JSON.parse(data);
    } catch {
      return data;
    }
  }

  async sendMessage(message: any): Promise<any> {
    return new Promise((resolve, reject) => {
      if (!this.socket || this.socket.readyState !== WebSocket.OPEN) {
        reject(new Error('WebSocket is not connected.'));
        return;
      }

      const responseHandler = (event: MessageEvent) => {
        const response = this.parseMessage(event.data);
        if (response) {
          resolve(response);
          this.socket?.removeEventListener('message', responseHandler);
        }
      };

      this.socket.addEventListener('message', responseHandler);

      try {
        this.socket.send(JSON.stringify(message));
      } catch (error) {
        console.error('Error:', error);
        this.socket?.removeEventListener('message', responseHandler);
        reject(error);
      }
    });
  }

  createServiceMessageLayout(
    type: ServiceRequestType,
    data?: RequestData,
    chatType?: 'individual' | 'group',
    chatId?: string,
    regionId?: string
  ): MessageLayout | null {
    if (this.socket && this.isConnected && this.jwtToken && this.authId) {
      switch (type) {
        case ServiceRequestType.UnseenCount:
        case ServiceRequestType.UnseenCountBySender:
          return {
            jwt: this.jwtToken,
            authid: String(this.authId),
            type: 'service',
            request: type,
          };

        case ServiceRequestType.ChatHistory:
          if (!data?.page) {
            console.error("Parameter 'page' is required for chat_history");
            return null;
          }
          return {
            jwt: this.jwtToken,
            authid: this.authId,
            type: 'service',
            chatId: chatId,
            request: ServiceRequestType.ChatHistory,
            data: { page: data.page },
          };

        case ServiceRequestType.SetSeen:
          if (!data?.id) {
            console.error("Parameter 'id' is required for set_seen");
            return null;
          }
          return {
            jwt: this.jwtToken,
            authid: this.authId,
            type: 'service',
            request: ServiceRequestType.SetSeen,
            data: { id: data.id },
          };

        case ServiceRequestType.LastMessages:
          return {
            jwt: this.jwtToken,
            authid: this.authId,
            type: 'service',
            request: ServiceRequestType.LastMessages,
            data: {},
          };

        case ServiceRequestType.GetRegionChat:
          return {
            jwt: this.jwtToken,
            authid: this.authId,
            tmpUniqueId: 'TEST!ID!!!',
            type: 'service',
            request: ServiceRequestType.GetRegionChat,
            data: {
              regionId: this.regionId,
            },
          };

        case ServiceRequestType.UnseenCountForRegion:
          return {
            jwt: this.jwtToken,
            authid: this.authId,
            type: 'service',
            request: ServiceRequestType.UnseenCountForRegion,
            data: {
              regionId: this.regionId,
            },
          };

        default:
          console.error('Invalid service request type');
          return null;
      }
    } else return null;
  }

  async getUnseenCount(): Promise<any> {
    const getUnseenCountMessage = this.createServiceMessageLayout(
      ServiceRequestType.UnseenCount
    );
    this.sendMessage(getUnseenCountMessage);
  }

  async getUnseenCountBySender(): Promise<any> {
    const getUnseenCountMessage = this.createServiceMessageLayout(
      ServiceRequestType.UnseenCountBySender
    );
    this.sendMessage(getUnseenCountMessage);
  }

  async getUnseenCountForRegion(): Promise<any> {
    const messageLayout = this.createServiceMessageLayout(
      ServiceRequestType.UnseenCountForRegion
    );
    await this.sendMessage(messageLayout);
  }

  async setMessageAsSeen(id?: string): Promise<any> {
    const setSeenMessage = this.createServiceMessageLayout(
      ServiceRequestType.SetSeen,
      { id: id }
    );
    await this.sendMessage(setSeenMessage);
    this.getUnseenCount();
    this.getUnseenCountBySender();
  }

  private reconnect(): void {
    if (this.manualDisconnect) return;

    setTimeout(() => {
      this.connect(this.jwtToken!, this.authId!, this.regionId!)
        .then(() => {
          this.reconnectAttempts = 0;
          console.log('Reconnected successfully.');
        })
        .catch((error) => {
          this.reconnectAttempts++;
          console.error(
            `Reconnect attempt ${this.reconnectAttempts} failed: ${error?.message}`
          );

          this.reconnect();
        });
    }, this.reconnectDelay);
  }

  async getLastMessages(): Promise<any> {
    const messageLayout = this.createServiceMessageLayout(
      ServiceRequestType.LastMessages
    );
    const messages = await this.sendMessage(messageLayout);
    this.latestMessages = messages;
    return this.latestMessages;
  }

  async getRegionChat(): Promise<any> {
    const messageLayout = this.createServiceMessageLayout(
      ServiceRequestType.GetRegionChat
    );
    await this.sendMessage(messageLayout);
  }

  async refreshRegionChat(regionId: string): Promise<any> {
    const messageLayout = {
      jwt: this.jwtToken,
      authid: this.authId,
      tmpUniqueId: 'TEST!ID!!!',
      type: 'service',
      request: ServiceRequestType.GetRegionChat,
      data: {
        regionId: regionId,
      },
    };
    await this.sendMessage(messageLayout);
  }

  async createChat(
    token: any,
    userId: any,
    currentOpenChat: any
  ): Promise<any> {
    const tmpUniqueId = `createChat${currentOpenChat.id}${currentOpenChat.firstName}`;

    const requestPayload = {
      jwt: token,
      authid: String(userId),
      type: 'service',
      data: {
        memberIds: [String(userId), String(currentOpenChat.userId)],
      },
      chatType: 'individual',
      tmpUniqueId: tmpUniqueId,
      from: String(userId),
      request: 'create_chat',
    };

    await this.sendMessage(requestPayload);
  }

  disconnect(): Promise<void> {
    return new Promise((resolve) => {
      if (this.socket) {
        this.manualDisconnect = true;
        this.isConnected = false;
        this.isLoggedIn = false;
        this.socket.close();
        resolve();
      }
    });
  }
  // async breakConnection(id?: string): Promise<any> {
  //   const setSeenMessage = this.createServiceMessageLayout(
  //     ServiceRequestType.SetSeen
  //   );
  //   await this.sendMessage(setSeenMessage);
  //   this.getUnseenCount();
  //   this.getUnseenCountBySender();
  // }
}

export default WebSocketStore;
