import { ReactNode } from 'react';

interface PageLayoutProps {
  title?: string;
  children: ReactNode;
  className?: string;
  isCms?: boolean;
}

const PageLayout: React.FC<PageLayoutProps> = ({
  title,
  children,
  className,
  isCms = false,
}) => {
  const wrapperClassName = `pks-layout-col-xl h-full ${
    className ? className : ''
  } ${isCms ? 'pks-cms' : ''}`.trim();

  return (
    <div className={wrapperClassName}>
      {title && <h1>{title}</h1>}
      <div
        className={isCms ? 'pks-layout-col-xl' : 'pks-layout-col-xl h-full '}
      >
        {children}
      </div>
    </div>
  );
};

export default PageLayout;
