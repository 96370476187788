import { useEffect, useState } from 'react';
import ForumMembersStore from '../../forumMembers/services/forumMembers.store';
import useScrollToTop from '../../../hooks/useScrollTop';
import { ClientRequest } from 'http';
import queryClient from '../../../config/tanstackQueryConfig';

interface ChatUserIconProps {
  id?: string | number;
  userName?: string;
  // userAvatar?: any;
}
const forumMembersStore: ForumMembersStore = new ForumMembersStore();

const ChatUserIcon = ({ id, userName }: ChatUserIconProps) => {
  function getInitials(name: string) {
    const namesArray = name.split(' ');

    const initials = namesArray
      .slice(0, 2)
      .map((n) => n.charAt(0).toUpperCase())
      .join('');

    return initials;
  }
  const initials = getInitials((userName && userName) || '');
  const { data: image, isLoading } = forumMembersStore.useGetForumMemberAvatar(
    Number(id)
  );

  return (
    <div className=" size-10  bg-secondary-700  rounded-full relative flex items-center justify-center ">
      <div className="overflow-hidden size-10 rounded-full flex items-center justify-center">
        {image?.data && !isLoading ? (
          <img src={image.data} alt="User Avatar" />
        ) : (
          <p className="font-bold">{initials}</p>
        )}
      </div>
      {/* <div className="size-4 bg-success rounded-full absolute bottom-0 right-0 border border-white"></div> */}
    </div>
  );
};

export default ChatUserIcon;
