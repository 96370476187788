import {
  useNavigate,
  Form,
  useLoaderData,
  LoaderFunctionArgs,
} from 'react-router-dom';
import {
  useForm,
  FormProvider,
  SubmitHandler,
  FieldValues,
} from 'react-hook-form';

import Input from '../../../components/shared/Input';
/* import CheckboxInput from '../../../components/shared/CheckboxInput'; */
import DropDownSelect from '../../../components/shared/DropdownSelect/DropDownSelectWithIndicators';
import Button from '../../../components/shared/Button';
import FileInput, {
  ALLOWED_IMAGE_TYPES,
  FileTypeEnum,
} from '../../../components/shared/FileInput';
import FilePreview from '../../../components/shared/FilePreview';

import { showToast } from '../../../utils';
import OrganizationsStore from '../services/organizations.store';
//import RegionsStore from '../../regions/services/regions.store';

import {
  ActionResponse,
  /* DropDownItemInterface, */
  // RegionStatusEnum,
} from '../../../types';

import {
  OrganizationFormInterface,
  OrganizationInterface,
} from '../services/organizations.types';

/* Fields Validation */
import {
  REQUIRED_VALIDATION_RULE,
  LETTERS_VALIDATION_RULES,
  TITLE_VALIDATION_RULE,
  /* DOMAIN_VALIDATION_RULES, */
  POSTAL_CODE_VALIDATION_RULES,
  SUBMITTING_TEXT,
  ADD_ORGANIZATION,
  EDIT_ORGANIZATION,
  CANCEL,
  ORGANIZATION_PATH,
} from '../../../constants';
import TransitionLoader from '../../../components/shared/TransitionLoader/TransitionLoader';
import { useRegions } from '../../../context/regions-context';
import { toast } from 'react-toastify';
import { useEffect } from 'react';

const organizationsStore: OrganizationsStore = new OrganizationsStore();
//const regionsStore: RegionsStore = new RegionsStore();

const OrganizationForm = () => {
  const navigate = useNavigate();
  const { availableRegionsDropdownItems } = useRegions();

  // Get loader data to populate 'edit' form
  const loaderData =
    useLoaderData() as ActionResponse<OrganizationInterface> | null;
  let editFormData: OrganizationInterface | undefined = undefined;

  if (loaderData && loaderData.success) {
    editFormData = loaderData?.data;
    if (editFormData) {
      editFormData.logo = loaderData.logo;
    }
  }

  useEffect(() => {
    if (loaderData?.success === false) {
      toast.error('Institucija ne pripada izabranom regionu!');

      const timer = setTimeout(() => {
        navigate(ORGANIZATION_PATH);
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [loaderData?.success, navigate]);

  // React Hook Form methods and state initialization
  const methods = useForm<FieldValues>({
    defaultValues: {
      name: editFormData?.name || '',
      address: editFormData?.address || '',
      city: editFormData?.city || '',
      postalCode: editFormData?.postal_code || '',
      country: editFormData?.country || '',
      regionId: editFormData?.region_id || '',
      // status: editFormData?.status || '',
      logo: editFormData?.logo || '',
      domain: editFormData?.domain || '',
    },
  });

  let file;
  if (editFormData) {
    file = {
      filename: 'Logo',
      url: editFormData.logo,
    };
  }

  const {
    handleSubmit,
    reset,
    formState: { isSubmitting },
  } = methods;

  // Use the `useGetRegions` hook from the regions store to fetch regions data
  /* const [initRegionsPage, setInitRegionsPage] = useState(1);
  const { data, isLoading, isFetching } = regionsStore.useGetRegions({
    page: initRegionsPage,
    searchTerm: '',
  }); */
  /* const [availableRegions, setAvailableRegions] = useState<
    DropDownItemInterface[]
  >([]); */

  // Effect to fetch all pages of regions data to be displayed as dropdown options
  /* useEffect(() => {
    if (!data || isLoading || isFetching) return;

    const regions = data?._embedded?.regions || [];
    const currentPage = data?._page || 1;
    const regionsPageCount = data?._page_count || 1;

    // Extract and map regions
    const extractedRegions = regions
      // .filter(
      // 	(region: {
      // 		id: number;
      // 		name: string;
      // 		status: RegionStatusEnum;
      // 	}) => region.status === 1
      // )
      .map((region: any) => ({
        id: region.id,
        label: region.name,
      }));

    // Update available regions with new data
    setAvailableRegions((prevRegions) => [...prevRegions, ...extractedRegions]);

    // Update the page to fetch next set of regions
    if (currentPage < regionsPageCount) {
      setInitRegionsPage(currentPage + 1);
    }
  }, [data, isLoading, isFetching]); */

  /**
   * Handles form submission.
   */
  const onSubmit: SubmitHandler<FieldValues> = async (data) => {
    const {
      name,
      address,
      city,
      postalCode,
      country,
      regionId,
      domain,
      logo,
      // status,
    } = data as OrganizationFormInterface;
    // const transformedStatus = status
    // 	? RegionStatusEnum.Active
    // 	: RegionStatusEnum.Inactive;

    let organizationData: any = {
      name,
      address,
      city,
      postalCode,
      country,
      regionId,
      domain,
      // status: transformedStatus,
    };

    if (editFormData && editFormData?.id) {
      if (typeof logo !== 'string') {
        organizationData.logo = logo;
      }
    } else {
      organizationData.logo = logo;
    }

    const { success, message } =
      editFormData && editFormData?.id
        ? await organizationsStore.updateOrganization(
            editFormData.id,
            organizationData
          )
        : await organizationsStore.addOrganization(organizationData);

    if (success) {
      navigate(-1);
    }

    showToast(message, success);
    reset({}, { keepValues: true });
  };

  return (
    <div>
      {isSubmitting && <TransitionLoader message="Kreiranje..." />}
      <FormProvider {...methods}>
        <Form className="pks-layout-col-md" onSubmit={handleSubmit(onSubmit)}>
          <div className="pks-layout-col-md">
            <div className="pks-layout-col">
              {/* Name Input */}
              <Input
                id="name"
                name="name"
                label="Naziv institucije*"
                placeholder="Unesi naziv institucije"
                autoFocus
                autoComplete="off"
                validationRules={TITLE_VALIDATION_RULE}
              />

              {/* Address Input */}
              <Input
                id="address"
                name="address"
                label="Adresa sedišta institucije"
                placeholder="Unesi adresu"
                autoComplete="off"
                validationRules={LETTERS_VALIDATION_RULES}
              />

              <div className="flex flex-col gap-2 sm:flex-row">
                {/* City Input */}
                <div className="flex-grow">
                  <Input
                    id="city"
                    name="city"
                    label="Grad"
                    placeholder="Unesi grad"
                    autoComplete="off"
                    validationRules={LETTERS_VALIDATION_RULES}
                  />
                </div>
                {/* Postal Code Input */}
                <div className="sm:w-1/3">
                  <Input
                    id="postalCode"
                    name="postalCode"
                    label="Poštanski broj"
                    placeholder="Unesi kod"
                    autoComplete="off"
                    validationRules={POSTAL_CODE_VALIDATION_RULES}
                    maxLength={5}
                    numeric
                  />
                </div>
              </div>
              {/* County Input */}
              <Input
                id="country"
                name="country"
                label="Država"
                placeholder="Unesi državu"
                autoComplete="off"
                validationRules={LETTERS_VALIDATION_RULES}
              />
            </div>

            <div className="pks-layout-col">
              {/* Logo File Input */}
              <FileInput
                id="logo"
                name="logo"
                label="Logo institucije:"
                allowedTypes={ALLOWED_IMAGE_TYPES}
                maxSizeMB={5}
                type={FileTypeEnum.Logo}
              />
              <p className="text-sm">
                Napomena: Veličina logoa ne sme biti veća od 5MB, a format mora
                biti PNG, JPG ili JPEG.
              </p>
              {/* File Preview */}
              {editFormData?.logo && <FilePreview file={file} />}
            </div>

            {/* Domain and Region */}
            <div className="pks-layout-col">
              {/* Domain Input */}
              <Input
                id="domain"
                name="domain"
                label="Domen institucije"
                placeholder="Unesi domen"
                autoComplete="off"
                //validationRules={DOMAIN_VALIDATION_RULES}
              />

              {/* Region Input */}
              <DropDownSelect
                id="regionId"
                name="regionId"
                options={availableRegionsDropdownItems}
                label="Matični region institucije*"
                placeholder="Izaberi region"
                validationRules={REQUIRED_VALIDATION_RULE}
                //isLoading={isLoading || isFetching}
              />

              {/* Status Input */}
              {/* <CheckboxInput
								id='status'
								name='status'
								label='Aktivan'
							/> */}
            </div>
          </div>
          {/* Submit Button and Actions */}
          <div className="w-full inline-flex flex-wrap gap-4">
            <Button type="submit" className="flex-grow" disabled={isSubmitting}>
              {isSubmitting
                ? SUBMITTING_TEXT
                : editFormData
                ? EDIT_ORGANIZATION
                : ADD_ORGANIZATION}
            </Button>
            <span className="flex-auto sm:flex-none focus:outline-none group">
              <Button
                onClick={() => navigate(-1)}
                type="button"
                variant="secondary"
                wide
                tabIndex={-1}
              >
                {CANCEL}
              </Button>
            </span>
          </div>
        </Form>
      </FormProvider>
    </div>
  );
};

export default OrganizationForm;

export async function loader({
  params,
}: LoaderFunctionArgs): Promise<
  ActionResponse<OrganizationInterface> | { success: false } | null
> {
  const slug = Number(params.slug); // Assuming the route uses ":slug" as a param

  if (slug) {
    // Preload form data
    const data = await organizationsStore.preloadOrganization(slug);

    if (!data?.success) {
      return { success: false };
    }

    const logoResData = await organizationsStore.getOrganizationLogo(slug);
    const { success } = logoResData;

    const newData = {
      ...data,
      logo: success ? logoResData.data : undefined,
    };

    return !data.success ? null : newData;
  }

  return null;
}
