import { Form, useNavigate } from 'react-router-dom';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';

import Input from '../../../components/shared/Input';
import Button from '../../../components/shared/Button';

import {
  ADMINISTRATION_PATH,
  PASSWORD_VALIDATION_RULES,
  SEND,
  SUBMITTING_TEXT,
} from '../../../constants';
import {
  extractMultipleErrors,
  hasNestedObjects,
  showToast,
} from '../../../utils';

import ForumMembersStore from '../services/forumMembers.store';
import { useAuth } from '../../../context/auth-context';
import { useState } from 'react';
const forumMembersStore: ForumMembersStore = new ForumMembersStore();

export interface ResetPasswordFormData {
  oldPassword: string;
  password: string;
  repeated_password: string;
}

const UserResetPasswordForm: React.FC = () => {
  const navigate = useNavigate();
  const { userId } = useAuth();
  const [beError, setBeError] = useState(false);

  const methods = useForm<ResetPasswordFormData>({
    defaultValues: {
      oldPassword: '',
      password: '',
      repeated_password: '',
    },
  });

  const {
    handleSubmit,
    formState: { isSubmitting, isValid },
    watch,
    setError,
  } = methods;

  const onSubmit: SubmitHandler<ResetPasswordFormData> = async (data) => {
    setBeError(false);

    if (isValid) {
      const response = await forumMembersStore.setNewPassord(data);
      const { success, message } = response;

      const handleError = (message: any) => {
        if (hasNestedObjects(message)) {
          // Set input fields errors
          const errorMessages = extractMultipleErrors(message);

          for (const key in errorMessages) {
            if (key in methods.getValues()) {
              setError(key as keyof ResetPasswordFormData, {
                type: 'backend',
                message: errorMessages[key],
              });
            }
          }
        } else {
          // Show toast error
          showToast(message, success);
          setBeError(true);
        }
      };

      if (success) {
        showToast(message, true);
        navigate(`${ADMINISTRATION_PATH}/${userId}`);
      } else {
        handleError(message);
      }
    }
  };

  return (
    <>
      <FormProvider {...methods}>
        <Form
          method="post"
          className="pks-layout-col-xl w-full"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="pks-layout-col">
            <Input
              type="password"
              id="oldPassword"
              name="oldPassword"
              label="Stara lozinka"
              placeholder="Stara lozinka"
              autoFocus
              autoComplete="off"
              validationRules={PASSWORD_VALIDATION_RULES}
              beError={beError}
            />
            <Input
              type="password"
              id="password"
              name="password"
              label="Nova lozinka"
              placeholder="Nova lozinka"
              autoComplete="off"
              validationRules={PASSWORD_VALIDATION_RULES}
              beError={beError}
            />
            <Input
              type="password"
              id="repeated_password"
              name="repeated_password"
              label="Potvrdi novu lozinku"
              placeholder="Potvrdi novu lozinku"
              autoComplete="off"
              validationRules={{
                ...PASSWORD_VALIDATION_RULES,
                validate: (value: string): any => {
                  if (watch('password') !== value) {
                    return 'Nova lozinka i potvrda lozinke nisu iste. Molimo vas da unesete iste lozinke.';
                  }
                },
              }}
              beError={beError}
            />
          </div>
          {/* Submit Button */}
          <Button type="submit" wide disabled={isSubmitting}>
            {isSubmitting ? SUBMITTING_TEXT : SEND}
          </Button>
        </Form>
      </FormProvider>
    </>
  );
};

export default UserResetPasswordForm;
