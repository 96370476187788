import { useEffect, useState } from 'react';
import { globalWS } from '../../init';

const useUnseenMessages = () => {
  const [countUnseenMessagesBySender, setCountUnseenMessagesBySender] =
    useState<any[]>([]);
  const [countUnseenMessagesForRegion, setCountUnseenMessagesForRegion] =
    useState<any[]>([]);
  const [countAllUnseenMessages, setCountAllUnseenMessages] =
    useState<number>(0);

  useEffect(() => {
    const handleMessagesUnseenBySender = (numberOfMessages: any[]) => {
      setCountUnseenMessagesBySender(numberOfMessages);
    };

    const handleMessagesUnseenForRegion = (numberOfMessages: any) => {
      setCountUnseenMessagesForRegion(numberOfMessages);
    };

    const handleAllUnseenMessages = (numberOfMessages: number) => {
      setCountAllUnseenMessages(numberOfMessages);
    };

    globalWS.on('messagesUnseenBySenderListener', handleMessagesUnseenBySender);
    globalWS.on(
      'messagesUnseenForRegionListener',
      handleMessagesUnseenForRegion
    );
    globalWS.on('allUnseenMessagesListener', handleAllUnseenMessages);

    return () => {
      globalWS.off(
        'messagesUnseenBySenderListener',
        handleMessagesUnseenBySender
      );
      globalWS.off('allUnseenMessagesListener', handleAllUnseenMessages);
      globalWS.off(
        'messagesUnseenForRegionListener',
        handleMessagesUnseenForRegion
      );
    };
  }, []);

  return {
    countUnseenMessagesBySender,
    countUnseenMessagesForRegion,
    countAllUnseenMessages,
    setCountUnseenMessagesForRegion,
    setCountUnseenMessagesBySender,
  };
};

export default useUnseenMessages;
