import {
  Link,
  LoaderFunctionArgs,
  useLoaderData,
  useNavigate,
} from 'react-router-dom';
import {
  useForm,
  FormProvider,
  SubmitHandler,
  FieldValues,
} from 'react-hook-form';

import Input from '../../../components/shared/Input';
import FileInput, {
  ALLOWED_IMAGE_TYPES,
  FileTypeEnum,
} from '../../../components/shared/FileInput';
import DropDownSelect from '../../../components/shared/DropdownSelect/DropDownSelectWithIndicators';
import Button from '../../../components/shared/Button';

/* Fields Validation */
import {
  CANCEL,
  NEWS_PATH,
  REQUIRED_VALIDATION_RULE,
  SUBMITTING_TEXT,
} from '../../../constants';
import { useRegions } from '../../../context/regions-context';

import {
  decodeHtmlEntities,
  extractMultipleErrors,
  formatDateWithMinus,
  hasNestedObjects,
  showToast,
} from '../../../utils';
import TextEditor from '../../../components/shared/TextEditor';
import TransitionLoader from '../../../components/shared/TransitionLoader/TransitionLoader';

import NewsStore from '../../news/services/news.store';

const newsStore = new NewsStore();

const today = new Date();

const NewsForm = () => {
  const navigate = useNavigate();
  const { availableRegionsDropdownItems } = useRegions();

  const loaderData = useLoaderData() as any;

  const methods = useForm<FieldValues>({
    defaultValues: {
      title: loaderData?.title || '',
      headline: loaderData?.headline || '',
      description: loaderData?.description
        ? decodeHtmlEntities(loaderData.description)
        : '',
      mainPhoto: loaderData?.mainPhoto?.imageFile || '',
      publicationDate: formatDateWithMinus(today),
      regionId: loaderData?.regionId || [],
    },
  });

  const {
    handleSubmit,
    reset,
    setError,
    formState: { isSubmitting },
  } = methods;

  const onSubmit: SubmitHandler<FieldValues> = async (data) => {
    let formData = new FormData();

    Object.keys(data).forEach((key) => {
      formData.append(key, data[key]);
    });

    Object.keys(data).forEach((key) => {
      if (!['regionId'].includes(key)) {
        formData.append(key, data[key]);
      }
    });

    if (data.regionId) {
      data.regionId.forEach((regionId: number) => {
        formData.append('regionIds[]', regionId.toString());
      });
    }

    const handleError = (message: any) => {
      if (hasNestedObjects(message)) {
        const errorMessages = extractMultipleErrors(message);
        for (const key in errorMessages) {
          setError(key as any, {
            type: 'backend',
            message: errorMessages[key],
          });
        }
      } else {
        showToast(message, false, true);
      }
    };

    const response = await newsStore.storeNews({
      data: formData,
      id: loaderData?.id,
    });
    const { success, message } = response;

    if (success) {
      showToast(message, success);
      navigate(NEWS_PATH);
    } else {
      handleError(message);
    }
    reset({}, { keepValues: true });
  };

  return (
    <div className="max-w-[500px]">
      {isSubmitting && <TransitionLoader message="Kreiranje..." />}

      <FormProvider {...methods}>
        <form className="pks-layout-col-md" onSubmit={handleSubmit(onSubmit)}>
          <div className="pks-layout-col-md">
            {/* Region and Doc Type */}
            <div className="pks-layout-col">
              {/* Region DropDown */}
              <DropDownSelect
                id="regionId"
                name="regionId"
                label="Region*"
                placeholder="Izaberi region"
                options={availableRegionsDropdownItems}
                validationRules={REQUIRED_VALIDATION_RULE}
                multiple
              />
              <Input
                id="title"
                name="title"
                label="Naslov novosti*"
                placeholder="Unesi naslov novosti"
                autoComplete="off"
                validationRules={REQUIRED_VALIDATION_RULE}
              />
              <Input
                id="headline"
                name="headline"
                label="Podnaslov novosti*"
                placeholder="Unesi podnaslov novosti"
                autoComplete="off"
                validationRules={REQUIRED_VALIDATION_RULE}
              />
            </div>
            {/* Docs File Input */}
            <div className="pks-layout-col">
              <FileInput
                id="mainPhoto"
                required
                name="mainPhoto"
                label="Dodaj glavnu sliku:*"
                allowedTypes={ALLOWED_IMAGE_TYPES}
                maxSizeMB={16}
                type={FileTypeEnum.Image}
              />
              <p className="text-sm">
                Napomena: Veličina slike ne sme biti veća od 16 MB, a format
                mora biti PNG, JPG ili JPEG.
              </p>
            </div>
            {/* Description */}
            <TextEditor
              label="Opis"
              value={methods.watch('description')}
              onChange={(value) => methods.setValue('description', value)}
            />
          </div>

          {/* Submit Button and Actions */}
          <div className="w-full inline-flex gap-4 flex-wrap sm:flex-nowrap">
            <Button
              type="submit"
              className="flex-grow"
              wide
              disabled={isSubmitting}
            >
              {isSubmitting
                ? SUBMITTING_TEXT
                : loaderData?.id
                ? 'Izmeni vest'
                : 'Unesi novu vest'}
            </Button>
            <Link
              to={NEWS_PATH}
              className="flex-auto sm:flex-none focus:outline-none group"
            >
              <Button type="button" variant="secondary" wide tabIndex={-1}>
                {CANCEL}
              </Button>
            </Link>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default NewsForm;

export async function loader({
  params,
}: LoaderFunctionArgs): Promise<any | null> {
  const { id } = params;

  if (!id) return null;

  const { success, data } = await newsStore.getSingleNews(+id);

  // preload file and preselect in file input

  if (success && data) {
    const { success: fileSuccess, data: fileData } =
      await newsStore.useGetNewsPhoto(Number(id));

    const regions =
      data.regions?.map((region: { id: number; name: string }) => region.id) ||
      [];

    const mainPhoto = fileSuccess && fileData ? fileData : '';

    const loaderData = {
      id: data.id,
      title: data.title,
      headline: data.headline,
      description: data.description,
      mainPhoto: mainPhoto,
      publicationDate: formatDateWithMinus(new Date()),
      regionId: regions,
    };

    return loaderData;
  }

  return null; // Return null if parameters are missing
}
