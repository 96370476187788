import http from '../../../utils/http';
import { ResetPasswordFormDataInterface } from './forumMembers.types';

class ForumMembersApi {
  getForumMembers = async (
    page?: number,
    searchTerm?: string,
    region?: string,
    organization?: string
  ) => {
    const params = new URLSearchParams();

    if (page) {
      params.append('page', page.toString());
    }

    if (searchTerm) {
      params.append('name', searchTerm);
    }
    if (region) {
      params.append('regionIds[]', region);
    }

    if (organization) {
      params.append('organizationIds[]', organization);
    }

    const url = `/users${params.toString() ? `?${params.toString()}` : ''}`;

    return http.get(url);
  };

  getForumMember = async (id: number) => {
    return http.get(`/users/${id}`);
  };

  getForumMemberAvatar = async (id: number) => {
    return http.get(`/users/${id}/avatar`, { responseType: 'blob' });
  };

  addForumMember = async (payload: any) => {
    return http.post('/users', payload);
  };

  updateForumMember = async (id: number, payload: any) => {
    return http.post(`/users/${id}`, payload);
  };

  deleteForumMember = async (id: number) => {
    return http.delete(`/users/${id}`);
  };

  setForumMemberRoles = async (id: number, data: any) => {
    return http.post(`/users/${id}/roles`, { roleIds: data });
  };

  getForumMemberRole = async (id: number) => {
    return http.get(`/users/${id}/roles`);
  };

  setNewPassword = async (data: ResetPasswordFormDataInterface) => {
    return http.post(`/users/resetpassword`, data);
  };
}

export default ForumMembersApi;
