import { LinkProps } from 'react-router-dom';
import Icon from '.';
import { Link } from 'react-router-dom';

export enum SquareIconEnum {
  Delete = 'trash',
  Edit = 'pencil',
  Dots = 'dots',
}

const SquareIcon = ({
  type,
  link,
  isActive = false,
  onClick,
}: {
  type: SquareIconEnum;
  link?: LinkProps['to'];
  isActive?: boolean;
  onClick?: (...args: any[]) => void;
}) => {
  const isLink = link ? true : false;

  let squareIconClassName =
    'w-[40px] h-[40px] flex items-center justify-center rounded-lg border border-secondary-300 ';

  if (type === SquareIconEnum.Delete) {
    squareIconClassName += `text-danger hover:bg-danger-transparent ${
      isActive ? 'bg-danger-transparent' : 'bg-white'
    }`;
  } else {
    squareIconClassName += `text-secondary-300 hover:bg-secondary-100 ${
      isActive ? 'bg-secondary-100' : 'bg-white'
    }`;
  }

  return (
    <>
      {isLink ? (
        <Link to={link ?? '/'} className={squareIconClassName}>
          <Icon name={type} tabIndex={-1} />
        </Link>
      ) : (
        <button className={squareIconClassName} onClick={onClick}>
          <Icon name={type} tabIndex={-1} />
        </button>
      )}
    </>
  );
};

export default SquareIcon;
