import Meta from '../components/layout/Meta';
import { useAuth } from '../context/auth-context';
import { globalWS } from '../init';
import PageLayout from '../layouts/PageLayout';
import ChatLayout from '../modules/chat/UI';
import { useRegions } from '../context/regions-context';
import { usePaginatedDropdown } from '../hooks/usePaginatedDropdown';
import ForumMembersStore from '../modules/forumMembers/services/forumMembers.store';
import RegionsStore from '../modules/regions/services/regions.store';
import TransitionLoader from '../components/shared/TransitionLoader/TransitionLoader';
import { useEffect, useRef, useState } from 'react';
import ChatPageLayout from '../layouts/ChatLayout';
import Button from '../components/shared/Button';

const forumMembersStore: ForumMembersStore = new ForumMembersStore();
const regionsStore: RegionsStore = new RegionsStore();

const ChatPage = () => {
  const pageTitle = 'Chat';
  const { activeRegion } = useRegions();

  const [initForumMembersPage, setInitForumMembersPage] = useState(1);
  const [availableForumMembers, setAvailableForumMembers] = useState<any[]>([]);
  const [finalForumMembers, setFinalForumMembers] = useState<any[]>([]);
  const [isDataResetting, setIsDataResetting] = useState(false);
  const isLoadingForumMembersRef = useRef<boolean>(true);

  const {
    data: forumMembersQueryData,
    refetch,
    isFetching: isFetchingUsers,
  } = forumMembersStore.useGetForumMembers(initForumMembersPage, '');

  useEffect(() => {
    setIsDataResetting(true);
    setInitForumMembersPage(1);
    setAvailableForumMembers([]);
    isLoadingForumMembersRef.current = true;

    refetch().then(() => {
      setIsDataResetting(false);
    });
  }, [activeRegion?.id, refetch]);

  useEffect(() => {
    if (!forumMembersQueryData || isDataResetting) return;

    const {
      _embedded: { users = [] } = {},
      _page = 1,
      _page_count = 1,
    } = forumMembersQueryData;

    const newUsers = users.map((user: any) => ({
      userId: user.id.toString(),
      firstName: user.first_name,
      lastName: user.last_name,
    }));

    setAvailableForumMembers((prevUsers) =>
      _page === 1 ? newUsers : [...prevUsers, ...newUsers]
    );

    if (_page < _page_count) {
      setInitForumMembersPage((prevPage) => prevPage + 1);
    } else {
      isLoadingForumMembersRef.current = false;
    }
  }, [forumMembersQueryData, isDataResetting]);

  useEffect(() => {
    if (!isLoadingForumMembersRef.current && !isDataResetting) {
      setFinalForumMembers([...availableForumMembers]);
    }
  }, [availableForumMembers, isDataResetting]);

  const useGetAllRegions = (editFromData?: any) => {
    return usePaginatedDropdown({
      fetchData: (page) => regionsStore.useGetRegions({ page }),
      extractItems: (data) => {
        return (data?.regions || []).map((region: any) => ({
          id: region.id,
          label: region.name,
        }));
      },
      dependencies: [],
    });
  };
  const { finalItems: allRegions, isLoadingRef: isLoadingRegions } =
    useGetAllRegions();

  const sentinelRegionsRef = isLoadingRegions.current;

  const [isConnected, setIsConnected] = useState<boolean>(true);

  useEffect(() => {
    const checkConnectionStatus = () => {
      const status = globalWS.getConnectionStatus();
      setIsConnected(status);
    };

    const intervalId = setInterval(checkConnectionStatus, 5000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <>
      <Meta title={pageTitle} />

      {/* <PageLayout className="lg:max-w-screen-xl lg:pr-8 lg:pl-8 xl:pr-19 xl:pl-19 mx-auto"> */}
      {/* <ChatPageLayout> */}
      {isConnected ? (
        <div className="lg:w-2/3 w-full mx-auto     ">
          {/* <div className="p-12 h-screen bg-red-500"> */}
          {isLoadingForumMembersRef.current ? (
            <TransitionLoader message="Učitavanje poruka" />
          ) : (
            <ChatLayout
              allUsers={finalForumMembers}
              allRegions={allRegions}
              isLoadingUsers={isLoadingForumMembersRef.current}
              isLoadingRegions={sentinelRegionsRef}
            />
          )}
        </div>
      ) : (
        <div className="h-[calc(100vh/2)] bg-secondary-100 w-3/4 mx-auto  rounded-md flex items-center justify-center flex-col">
          <p className=" font-beld text-xl text-center ">
            Chat konekcija je izgubljena. Molimo vas, sačekajte 5 sekundi ili
            osvežite stranicu.
          </p>
          <Button className="mt-4" onClick={() => window.location.reload()}>
            Osvežite stranicu
          </Button>
        </div>
      )}
      {/* </div> */}
      {/* </ChatPageLayout> */}
      {/* </PageLayout> */}
    </>
  );
};

export default ChatPage;

// const useGetAllForumMembers = () => {
//   return usePaginatedDropdown({
//     fetchData: (page) => forumMembersStore.useGetForumMembers(page, ''),
//     extractItems: (data) => {
//       return (data?.users || []).map((user: any) => ({
//         userId: user.id.toString(),
//         firstName: user.first_name,
//         lastName: user.last_name,
//       }));
//     },
//   });
// };

// const { finalItems: allUsers, isLoadingRef: isLoadingUsers } =
//   useGetAllForumMembers();
// const sentinelUsersRef = isLoadingUsers.current;
