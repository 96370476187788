import { useLoaderData, useNavigate } from 'react-router-dom';
import { FieldValues, SubmitHandler } from 'react-hook-form';
import { toast } from 'react-toastify';

import Button from '../../components/shared/Button';
import FAQAccordion from '../../components/shared/Accordion/FAQAccordion';
import Icon from '../../components/shared/Icon';
import Info from '../../modules/info/UI';
import Meta from '../../components/layout/Meta';
import NoResultsInfoMessage from '../../components/shared/InfoMessage/NoResults';
import PageLayout from '../../layouts/PageLayout';
import PageTitleWithActions from '../../layouts/PageLayout/TitleWithActions';
import QuestionsAndAnswersForm from '../../modules/info/UI/QuestionsAndAnswersForm';

import queryClient from '../../config/tanstackQueryConfig';
import { useModal } from '../../context/modal-context';
import { ActionResponse } from '../../types';
import {
  decodeHtmlEntities,
  extractMultipleErrors,
  hasNestedObjects,
  showToast,
} from '../../utils';

import InfoStore, {
  faqQueryOptions,
} from '../../modules/info/services/info.store';
import { FAQItem, FAQResponse } from '../../modules/info/services/info.types';

const infoStore: InfoStore = new InfoStore();

const PAGE_TITLE = 'Pitanja članova (FAQ)';

const QuestionsAndAnswersPage = () => {
  const navigate = useNavigate();
  const { data } = useLoaderData() as LoaderData;

  const { openModal, closeModal } = useModal();

  const transformContentData = (content: FAQItem[]) => {
    return content.map((item, index) => ({
      id: `faq-${index + 1}`,
      question: decodeHtmlEntities(item.question),
      answer: decodeHtmlEntities(item.answer),
    }));
  };

  const faq = data ? transformContentData(data?.content) : null;

  const defaultValues = data
    ? {
        questions: faq?.map(({ id, ...rest }) => rest) || null,
        regionId: data.regionId,
      }
    : null;

  const handleSubmit: SubmitHandler<FieldValues> = async (formData) => {
    const response = await infoStore.addFAQ({ data: formData });
    const { success, message } = response;

    if (success) {
      typeof message === 'string' && toast.success(message);
      closeModal();
      navigate('.', { replace: true, state: { key: Date.now() } });
    } else {
      if (hasNestedObjects(message)) {
        const errorMessages = extractMultipleErrors(message);
        toast.error(Object.values(errorMessages)[0] || 'Greška!');
      } else {
        showToast(message, success, true);
      }
    }
  };

  const handleOpenModal = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    openModal(
      <QuestionsAndAnswersForm
        defaultValues={defaultValues}
        onSubmit={handleSubmit}
      />,
      data ? 'Izmeni FAQ' : 'Novi FAQ'
    );
  };

  return (
    <>
      <Meta title={PAGE_TITLE} />
      <PageLayout isCms>
        <PageTitleWithActions title={PAGE_TITLE}>
          <Button className="flex gap-2 items-center" onClick={handleOpenModal}>
            <Icon name="pencil" />
            {data ? 'Izmeni FAQ' : 'Novi FAQ'}
          </Button>
        </PageTitleWithActions>
        {data?.date && <p>{data.date}</p>}
        <Info>
          <>
            {faq ? (
              <>
                <FAQAccordion items={faq} />
              </>
            ) : (
              <NoResultsInfoMessage />
            )}
          </>
        </Info>
      </PageLayout>
    </>
  );
};

export default QuestionsAndAnswersPage;

// Loader Function
interface LoaderData {
  data: FAQResponse | null;
}

export async function loader(): Promise<LoaderData> {
  const response = (await queryClient.fetchQuery(
    faqQueryOptions()
  )) as ActionResponse<FAQResponse>;

  return {
    data: response.success ? response.data || null : null,
  };
}
