import { ReactNode } from 'react';

import Icon from '../Icon';

interface ErrorMessageProps {
  message: string | ReactNode;
  icon?: 'danger' | 'info' | 'success';
}

const InfoMessage: React.FC<ErrorMessageProps> = ({
  message,
  icon = 'danger',
}) => {
  return (
    <div className="flex gap-2">
      <Icon className="flex-shrink-0 w-6 h-6" name={icon} />
      <div>{message}</div>
    </div>
  );
};

export default InfoMessage;
