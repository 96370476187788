import {
  keepPreviousData,
  QueryKey,
  queryOptions,
  useQuery,
  UseQueryOptions,
} from '@tanstack/react-query';
import { AxiosResponse } from 'axios';
import { StatisticInterface } from './statistics.types';

import StatisticsApi from './statistics.api';
const api: StatisticsApi = new StatisticsApi();

export const QUERY_KEY_STATISTICS = 'statistics';

class StatisticsStore {
  useGetStatistics = () => {
    return useQuery(statisticsQueryOptions());
  };
}

export default StatisticsStore;

export const statisticsQueryOptions = (): UseQueryOptions<any, Error> =>
  queryOptions({
    queryKey: [QUERY_KEY_STATISTICS] as QueryKey,
    queryFn: async (): Promise<StatisticInterface> => {
      const response: AxiosResponse<StatisticInterface> =
        await api.getStatistics();
      return response.data;
    },
    placeholderData: keepPreviousData,
  });
