import { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';

import PageLayout from '../../layouts/PageLayout';
import PageTitleWithActions from '../../layouts/PageLayout/TitleWithActions';
import Meta from '../../components/layout/Meta';
import Button from '../../components/shared/Button';
import FraudCases from '../../modules/fraudCases/UI';

import { FRAUD_CASE_ADD_PATH } from '../../constants';

const PAGE_TITLE = 'Pregled svih slučajeva';

function FraudCasesPage() {
  const [title, setTitle] = useState(PAGE_TITLE);

  const handleTitleChange = useCallback((newTitle: string) => {
    setTitle(newTitle || PAGE_TITLE);
  }, []);

  return (
    <>
      <Meta title={PAGE_TITLE} />
      <PageLayout>
        <PageTitleWithActions title={title} isCol>
          <div className="inline-flex flex-wrap gap-4">
            <Link
              to={FRAUD_CASE_ADD_PATH}
              className="flex-auto focus:outline-none group"
            >
              <Button wide tabIndex={-1}>
                Novi slučaj
              </Button>
            </Link>
            {/* <Button
                            isDisabled
                            disabled
                            className='flex-auto w-fit'
                        >
                        Generiši izveštaj
                        </Button> */}
          </div>
        </PageTitleWithActions>
        <FraudCases onTitleChange={handleTitleChange} />
      </PageLayout>
    </>
  );
}

export default FraudCasesPage;
