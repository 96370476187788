const ChatTimeBorder = ({ date }: { date: string }) => {
  //DD.MM.YYYY
  const today = new Date().toLocaleDateString('de-DE');

  return (
    <div className="flex items-center justify-center my-4">
      <div className="flex-grow border-b border-b-secondary h-1"></div>
      <p className="px-2 text-secondary text-sm">
        {date === today ? 'Danas' : date}
      </p>
      <div className="flex-grow border-b border-b-secondary h-1"></div>
    </div>
  );
};

export default ChatTimeBorder;
