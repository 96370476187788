import {
  getDateFromTimestamp,
  getTimeFromTimestamp,
} from '../../../components/shared/DropdownSelect/utils';
import ChatUserIcon from './ChatUserIcon';

const today = new Date();
const formattedToday = `${String(today.getDate()).padStart(2, '0')}.${String(
  today.getMonth() + 1
).padStart(2, '0')}.${today.getFullYear()}`;

interface ChatConversationPreviewProps {
  chat: any;
  currentOpenChat: any | null;
  lastMessage: any;
  isUnseen: {
    _id: number;
    count: number;
  };
  // avatarsList: any;
  timestamp: any;
}

const ChatConversationPreview = ({
  chat,
  isUnseen,
  lastMessage,
  // avatarsList,
  timestamp,
  currentOpenChat,
}: ChatConversationPreviewProps) => {
  const isCurrentChat =
    +currentOpenChat?.id === +chat?.id ||
    +currentOpenChat?.userId === +chat?.chatPartnerId ||
    +currentOpenChat?.chatPartnerId === +chat.chatPartnerId;

  const fullName =
    chat?.chatPartnerName?.firstName + ' ' + chat?.chatPartnerName?.lastName;
  function truncateString(str: string): string {
    if (str.length > 15) {
      return str.substring(0, 15) + '...';
    }
    return str;
  }

  const truncatedLastMessage = truncateString(lastMessage);
  const formattedTime = getTimeFromTimestamp(timestamp);
  const formattedDate = getDateFromTimestamp(timestamp);

  // const userAvatarUrl = avatarsList[chat?.chatPartnerId];
  return (
    <div
      className={`hover:cursor-pointer w-full h-[80px] rounded-lg flex items-center justify-between gap-2 p-4 shadow-card border border-secondary-200 ${
        isCurrentChat ? 'bg-primary-300' : 'bg-white'
      }`}
    >
      <div className="flex items-center h-full justify-center">
        <ChatUserIcon
          id={chat?.chatPartnerId}
          userName={fullName}
          // userAvatar={userAvatarUrl}
        />
      </div>
      <div className="flex-1">
        <p
          className={`font-bold ${isCurrentChat ? 'text-white' : 'text-black'}`}
        >
          {fullName}
        </p>
        <p
          className={` ${isCurrentChat ? 'text-white' : 'text-secondary-400'}`}
        >
          {truncatedLastMessage}
        </p>
      </div>
      <div className="flex flex-col items-center justify-between h-full">
        {lastMessage && (
          <p
            className={`text-xs ${
              isCurrentChat ? 'text-white' : 'text-secondary-400'
            }`}
          >
            {formattedToday === formattedDate ? formattedTime : formattedDate}
          </p>
        )}
        {!isCurrentChat && isUnseen && (
          <div className="text-xs size-6  rounded-full flex items-center justify-center bg-primary-300 text-white shadow-card">
            {isUnseen?.count}
          </div>
        )}
      </div>
    </div>
  );
};

export default ChatConversationPreview;
