import { useEffect, useState } from 'react';
import { Link, useLoaderData, useNavigate } from 'react-router-dom';
import {
  useForm,
  FormProvider,
  SubmitHandler,
  FieldValues,
} from 'react-hook-form';
import { toast } from 'react-toastify';
import { DateObject } from 'react-multi-date-picker';

import Button from '../../../components/shared/Button';
import CustomDatePicker from '../../../components/shared/DatePicker';
import DropDownSelect from '../../../components/shared/DropdownSelect/DropDownSelectWithIndicators';
import FileInput, {
  ALLOWED_DOC_TYPES,
} from '../../../components/shared/FileInput';
import Input from '../../../components/shared/Input';
import TransitionLoader from '../../../components/shared/TransitionLoader/TransitionLoader';
import UploadProgressModal, {
  useUploadProgress,
} from '../../../components/shared/UploadProgressModal';
// import CustomDateRangePicker from '../../../components/shared/DateRangePicker';

/* Fields Validation */
import {
  ACQUIRER_ID_VALIDATION_RULES,
  BIN_VALIDATION_RULES,
  CANCEL,
  FRAUD_CASES_LINK,
  MID_VALIDATION_RULES,
  REQUIRED_VALIDATION_RULE,
  SUBMITTING_TEXT,
  TID_VALIDATION_RULES,
} from '../../../constants';
//import { usePaginatedDropdown } from '../../../hooks/usePaginatedDropdown';
import { DropDownItemInterface } from '../../../types';
import {
  extractMultipleErrors,
  formatDateWithMinus,
  formatToISOTYmd,
  hasNestedObjects,
  showToast,
} from '../../../utils';

import FraudCaseStore from '../services/fraudCases.store';
import { useRegions } from '../../../context/regions-context';
//import RegionsStore from '../../regions/services/regions.store';

const fraudCasesStore: FraudCaseStore = new FraudCaseStore();
//const regionsStore = new RegionsStore();

const today = new Date();

const FraudCaseForm = () => {
  const navigate = useNavigate();
  // Get loader data to populate 'edit' form
  const loaderData = useLoaderData() as any;

  const { availableRegionsDropdownItems } = useRegions();

  const {
    uploadDetails,
    handleUploadProgress,
    handleUploadResponse,
    resetUploadProgress,
  } = useUploadProgress();
  const [isSubmitSuccessfull, setIsSubmitSuccessfull] = useState<
    boolean | null
  >(null);

  useEffect(() => {
    if (isSubmitSuccessfull && uploadDetails.error.status === null) {
      navigate(FRAUD_CASES_LINK);
      toast.success('Slučaj prevare je uspešno dodat!');
    }
  }, [isSubmitSuccessfull, navigate, uploadDetails.error]);

  // Use the `useGetFraudCasesTypes` hook from the fraud cases store to fetch fraud case types data
  const { data: fraudCasesTypes, isLoading: isLoadingFraudCasesTypes } =
    fraudCasesStore.useGetFraudCasesTypes();
  let fraudCaseOptions: DropDownItemInterface[] =
    fraudCasesTypes && typeof fraudCasesTypes === 'object'
      ? Object.keys(fraudCasesTypes).map((key) => ({
          id: key,
          label: fraudCasesTypes[key],
          index: key === loaderData?.type_id,
        }))
      : [];

  // Use the `useGetFraudCasesTerminalTypes` hook from the fraud cases store to fetch fraud case terminal types data
  const {
    data: fraudCasesTerminalTypes,
    isLoading: isLoadingFraudCasesTerminalTypes,
  } = fraudCasesStore.useGetFraudCasesTerminalTypes();
  const fraudCaseTerminalOptions: DropDownItemInterface[] =
    fraudCasesTerminalTypes && typeof fraudCasesTerminalTypes === 'object'
      ? Object.keys(fraudCasesTerminalTypes).map((key, index) => ({
          id: index + 1,
          label: fraudCasesTerminalTypes[key], // Automatically get the value associated with the key
        }))
      : [];

  // Use the `useGetFraudCardTypes` hook from the fraud cases store to fetch fraud case card types data
  const { data: fraudCasesCardTypes, isLoading: isLoadingfraudCasesCardTypes } =
    fraudCasesStore.useGetFraudCardTypes();
  const fraudCaseCardOptions: DropDownItemInterface[] =
    fraudCasesCardTypes && typeof fraudCasesCardTypes === 'object'
      ? Object.keys(fraudCasesCardTypes).map((key) => ({
          id: key,
          label: fraudCasesCardTypes[key], // Automatically get the value associated with the key
        }))
      : [];

  // Initialize React Hook Form methods with default values
  const methods = useForm<FieldValues>({
    defaultValues: {
      title: loaderData?.title || '',
      description: loaderData?.description || '',
      typeId: loaderData?.type_id ? loaderData.type_id : '',
      cardTypeIds: loaderData?.card_types || [],
      terminalTypeId: loaderData?.terminal_type_id
        ? loaderData.terminal_type_id.toString()
        : '',
      cardNumber: loaderData?.card_number || '',
      acquirerId: loaderData?.acquirer_id || '',
      mid: loaderData?.mid || '',
      tid: loaderData?.tid || '',
      inputDateStart: loaderData?.input_date_start?.date || '',
      inputDateEnd: loaderData?.input_date_end?.date || '',
      fraudDateStart: loaderData?.fraud_date_start?.date || '',
      fraudDateEnd: loaderData?.fraud_date_end?.date || '',
      regionId: loaderData?.regions[0] || '',
      documents: [],
      sourceType: loaderData?.source_type || 1,
    },
  });

  const {
    getValues,
    handleSubmit,
    reset,
    setError,
    setValue,
    formState: { isSubmitting },
  } = methods;

  /* const useRegionsDropdown = (editFromData?: any) => {
    return usePaginatedDropdown({
      fetchData: (page) => regionsStore.useGetRegions({ page }),
      extractItems: (data) => {
        return (data?.regions || []).map((region: any) => ({
          id: region.id,
          label: region.name,
        }));
      },
    });
  };

  const { finalItems: finalRegions, isLoadingRef: isLoadingRegionsRef } =
    useRegionsDropdown(); */

  /**
   * Handles form submission.
   *
   * @param {FieldValues} data - The form data.
   * @returns {Promise<void>} A promise that resolves when the submission is complete.
   */
  const onSubmit: SubmitHandler<FieldValues> = async (
    data: FieldValues
  ): Promise<void> => {
    setIsSubmitSuccessfull(null);

    const handleError = (message: any) => {
      if (hasNestedObjects(message)) {
        // Set input fields errors
        const errorMessages = extractMultipleErrors(message);

        for (const key in errorMessages) {
          setError(key, {
            type: 'backend',
            message: errorMessages[key],
          });
        }
      } else {
        // Show toast error
        showToast(message, success);
      }
    };

    const formData = new FormData();

    if (data.regionId) formData.append('regionIds[]', data.regionId.toString());

    // Format and append all non-file, non-date fields
    Object.keys(data).forEach((key) => {
      if (
        ![
          'documents',
          'cardTypeIds',
          'inputDateStart',
          'inputDateEnd',
          'fraudDateStart',
          'fraudDateEnd',
        ].includes(key)
      ) {
        formData.append(key, data[key]);
      }
    });

    data.cardTypeIds.forEach((cardTypeId: any) => {
      formData.append('cardTypes[]', cardTypeId);
    });

    // Append formatted dates if they exist
    ['fraudDateStart'].forEach((dateField) => {
      formData.append(dateField, formatToISOTYmd(data[dateField]));
    });
    formData.append('inputDateStart', formatDateWithMinus(today));

    const hasDocs = data.documents && data.documents.length > 0;
    // Append files (if documents are provided)
    if (hasDocs) {
      (Array.from(data.documents) as File[]).forEach((file: File) => {
        formData.append(`documents[]`, file);
      });
    }

    const response = !hasDocs
      ? await fraudCasesStore.addFraudCase(formData)
      : await fraudCasesStore.addFraudCase(formData, handleUploadProgress);
    const { success, message } = response;

    if (success) {
      const resData = response.data;
      hasDocs && handleUploadResponse({ success: true });

      if (!resData) {
        // Show toast error
        showToast(message, !success);
        return;
      }

      setIsSubmitSuccessfull(true);
      reset({}, { keepValues: false });
    } else {
      hasDocs && handleUploadResponse({ success: false, code: response.code });
      console.log(message);
      if (message === null) return;
      handleError(message);

      setIsSubmitSuccessfull(false);
      const currentValues = getValues(); // Get current form values
      const filteredValues = Object.keys(currentValues).reduce(
        (acc: Partial<FormData>, key) => {
          if (key !== 'documents') {
            acc[key as keyof FormData] = currentValues[key]; // Keep only non-'documents' keys
          }
          return acc;
        },
        {}
      );

      if (response.code === 400) {
        reset(filteredValues, { keepValues: false, keepErrors: true });
      }
    }
  };

  const [sourceType, setSourceType] = useState(loaderData?.source_type || 1);

  const handleCheckbox = (key: string, value: number) => {
    setValue(key, value);
    setSourceType(value);
  };

  const maxDate = new DateObject().set('date', new Date());

  return (
    <div className="max-w-[1016px">
      {isSubmitting && !uploadDetails.percentage && (
        <TransitionLoader message="Kreiranje..." />
      )}
      {uploadDetails.percentage && (
        <UploadProgressModal
          percentage={uploadDetails.percentage}
          error={uploadDetails.error}
          files={uploadDetails.files}
          onReset={resetUploadProgress}
          onSubmit={handleSubmit(onSubmit)}
        />
      )}

      {/* Form wrapped in FormProvider to use React Hook Form context */}
      <FormProvider {...methods}>
        <form className="pks-layout-col-md" onSubmit={handleSubmit(onSubmit)}>
          {/* Form inputs */}
          <div className="flex flex-col gap-4 lg:flex-row">
            {/* Left Panel */}
            <div className="pks-layout-col-md w-1/2">
              <div className="pks-layout-col">
                <div className="">
                  {/*Source type  */}
                  <div className="flex items-center gap-6 mb-6">
                    <span
                      className="cursor-pointer flex gap-3 items-center"
                      onClick={() => handleCheckbox('sourceType', 1)}
                    >
                      <div className="size-6 bg-white shadow-sm border border-secondary-300 rounded-full relative">
                        <div
                          className={`size-3 ${
                            sourceType === 1 ? 'bg-primary-300' : 'bg-white'
                          } rounded-full absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2`}
                        ></div>
                      </div>
                      <p>Issuer</p>
                    </span>

                    <span
                      className="cursor-pointer flex gap-3 items-center"
                      onClick={() => handleCheckbox('sourceType', 2)}
                    >
                      <div className="size-6 bg-white shadow-sm border border-secondary-300 rounded-full relative">
                        <div
                          className={`size-3 ${
                            sourceType === 2 ? 'bg-primary-300' : 'bg-white'
                          } rounded-full absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2`}
                        ></div>
                      </div>
                      <p>Acquirer</p>
                    </span>
                  </div>
                </div>

                {/* Title Input Field */}
                <div className="">
                  <Input
                    id="title"
                    name="title"
                    label="Naziv slučaja*"
                    placeholder="Unesi naziv slučaja"
                    autoComplete="off"
                    validationRules={REQUIRED_VALIDATION_RULE}
                  />
                </div>

                {/* Type of Fraud Cases Dropdown Select */}
                <div className="max-w-full">
                  <DropDownSelect
                    id="typeId"
                    name="typeId"
                    options={fraudCaseOptions}
                    label="Vrsta prevare*"
                    placeholder="Izaberi vrstu prevare"
                    validationRules={REQUIRED_VALIDATION_RULE}
                    isLoading={isLoadingFraudCasesTypes}
                  />
                </div>

                {/* Type of Fraud Cases Card Dropdown Select */}
                <div>
                  <DropDownSelect
                    id="cardTypeIds"
                    name="cardTypeIds"
                    options={fraudCaseCardOptions}
                    label="Tip kartice*"
                    multiple
                    placeholder="Izaberi tip kartice*"
                    isLoading={isLoadingfraudCasesCardTypes}
                    validationRules={{
                      ...REQUIRED_VALIDATION_RULE,
                      validate: (value: any) => {
                        const selectedValues = Array.isArray(value)
                          ? value
                          : [value];

                        const hasNotSelected = selectedValues.includes('5');

                        const otherSelectedValues = selectedValues.filter(
                          (v) => v !== '5'
                        );

                        if (hasNotSelected && otherSelectedValues.length > 0) {
                          return 'Ne mozete izabrati "Nista od navedenog" i druge tipe kartice.';
                        }

                        return true;
                      },
                    }}
                  />
                </div>

                {/* Type of Fraud Cases Terminal Dropdown Select */}
                <div>
                  <DropDownSelect
                    id="terminalTypeId"
                    name="terminalTypeId"
                    options={fraudCaseTerminalOptions}
                    label="Tip terminala"
                    placeholder="Izaberi tip terminala"
                    isLoading={isLoadingFraudCasesTerminalTypes}
                  />
                </div>

                {/* Bin and Acquirer ID Input Fields*/}
                <div className="flex flex-col gap-2 lg:flex-row">
                  <div className="flex-1">
                    <Input
                      type="text"
                      id="cardNumber"
                      name="cardNumber"
                      label="BIN"
                      placeholder="Unesi BIN"
                      validationRules={BIN_VALIDATION_RULES}
                      maxLength={8}
                      autoComplete="off"
                      maskedInput
                    />
                  </div>
                  <div className="flex-1">
                    <Input
                      id="acquirerId"
                      name="acquirerId"
                      label="Acquirer ID*"
                      placeholder="Unesi acquirer ID"
                      validationRules={ACQUIRER_ID_VALIDATION_RULES}
                      maxLength={6}
                      autoComplete="off"
                    />
                  </div>
                </div>

                {/* MID and TID Input Fields*/}
                <div className="flex flex-col gap-2 lg:flex-row">
                  <div className="flex-1">
                    <Input
                      id="mid"
                      name="mid"
                      label="MID"
                      placeholder="Unesi MID"
                      autoComplete="off"
                      validationRules={MID_VALIDATION_RULES}
                      maxLength={20}
                    />
                  </div>
                  <div className="flex-1">
                    <Input
                      id="tid"
                      name="tid"
                      label="TID"
                      placeholder="Unesi TID"
                      autoComplete="off"
                      validationRules={TID_VALIDATION_RULES}
                      maxLength={10}
                    />
                  </div>
                </div>

                {/* Fraud Date Input Field */}
                <div className="flex flex-col gap-2 lg:flex-row">
                  <div className="lg:w-1/2 lg:pr-1">
                    <CustomDatePicker
                      maxDate={maxDate}
                      id="fraudDateStart"
                      name="fraudDateStart"
                      label="Datum prevare*"
                      validationRules={REQUIRED_VALIDATION_RULE}
                    />
                  </div>
                </div>

                <div className="">
                  {/* Regions Dropdown Field */}
                  <DropDownSelect
                    id="regionId"
                    name="regionId"
                    label="Region*"
                    placeholder="Izaberi region"
                    options={availableRegionsDropdownItems}
                    validationRules={REQUIRED_VALIDATION_RULE}
                  />
                </div>
              </div>

              {/* Documents File Input Field */}
              <div className="pks-layout-col">
                <FileInput
                  id="documents"
                  name="documents"
                  label="Dodaj dokument/e:"
                  maxSizeMB={100}
                  multiple
                  allowedTypes={ALLOWED_DOC_TYPES}
                />
                <p className="text-sm">
                  Napomena: Veličina dokumenta ne sme biti veća od 100MB.
                </p>
              </div>
              <div className="w-full inline-flex flex-wrap gap-4">
                {/* Submit Button */}
                <Button type="submit" className="flex-grow">
                  {isSubmitting
                    ? SUBMITTING_TEXT
                    : loaderData
                    ? 'Izmeni slučaj'
                    : 'Unesi slučaj'}
                </Button>
                <Link
                  to=".."
                  className="flex-auto sm:flex-none focus:outline-none group"
                >
                  <Button type="button" variant="secondary" wide tabIndex={-1}>
                    {CANCEL}
                  </Button>
                </Link>
              </div>
            </div>

            {/* Right Panel */}
            <div className="w-1/2">
              {/* Additional Note Textarea */}
              <Input
                id="description"
                name="description"
                label="Napomena / Opis *"
                placeholder="Unesi napomenu / opis"
                asTextarea
                height={'200'}
                validationRules={REQUIRED_VALIDATION_RULE}
              />
            </div>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default FraudCaseForm;
