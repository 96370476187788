import InfoMessage from '.';

const NoResultsInfoMessage = () => {
  return (
    <InfoMessage
      icon="info"
      message="Trenutno nema dostupnih rezultata za prikaz."
    />
  );
};

export default NoResultsInfoMessage;
