import { useFormContext, FieldError } from 'react-hook-form';

const getNestedProperty = (obj: any, path: string) => {
  return path.split('.').reduce((acc, part) => acc && acc[part], obj);
};

const useInputContext = (name: string) => {
  const {
    register,
    control,
    formState: { errors, isSubmitted, isSubmitSuccessful },
    setValue,
    getValues,
    watch,
  } = useFormContext();

  const values = getValues();
  const value = getNestedProperty(values, name); // Safely access nested values
  const error = getNestedProperty(errors, name) as FieldError; // Safely access nested errors

  return {
    register,
    control,
    value,
    setValue,
    error,
    isSubmitted,
    isSubmitSuccessful,
    watch,
  };
};

export default useInputContext;
