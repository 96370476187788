import DOMPurify from 'dompurify';

import Icon from '../Icon';

interface AccordionProps {
  item: { id: number | string; question: string; answer: string };
  isOpen: boolean;
  onToggle: () => void;
}

const Accordion: React.FC<AccordionProps> = ({ item, isOpen, onToggle }) => {
  const sanitizedAnswer = DOMPurify.sanitize(item.answer);

  return (
    <div className="overflow-hidden border border-secondary-200 rounded-lg bg-white">
      <div
        className="flex gap-3 justify-between px-4 py-3 w-full cursor-pointer"
        onClick={onToggle}
      >
        <span className="font-bold select-none">{item.question}</span>
        <Icon className="flex items-center" name={isOpen ? 'minus' : 'plus'} />
      </div>
      {isOpen && (
        <div
          className={`px-4 py-3`}
          dangerouslySetInnerHTML={{
            __html: sanitizedAnswer,
          }}
        />
      )}
    </div>
  );
};

export default Accordion;
