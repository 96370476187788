import { toast } from 'react-toastify';
import axios, { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';

import { getBaseUrl } from './global';
import {
  getAuthStateFromLocalStorage,
  getAvailableRegionsStateFromLocalStorage,
} from './auth';
import { ErrorResponse } from '../types';

import {
  SESSION_EXPIRED_MESSAGE,
  SOMETHING_WENT_WRONG_TEXT,
} from '../constants';

// import AuthStore from '../modules/auth/services/auth.store';
// const authStore: AuthStore = new AuthStore();

const config: AxiosRequestConfig = {
  baseURL: getBaseUrl(),
};

interface HeadersCustomInterface {
  [key: string]: string;
}

let headers: HeadersCustomInterface = {
  'Content-Type': 'multipart/form-data',
};

export const setHeaders = (newHeaders: HeadersCustomInterface): void => {
  headers = { ...headers, ...newHeaders };
};

export const removeHeader = (header: string): void => {
  const { [header]: _, ...rest } = headers;
  headers = rest;
};

const http = axios.create(config);

// let isRefreshing = false;
// let failedQueue: Array<(token: string) => void> = [];
//
// const processQueue = (
//   error: AxiosError | null,
//   token: string | null = null
// ) => {
//   if (token) {
//     failedQueue.forEach((cb) => cb(token));
//   }
//   failedQueue = [];
// };

http.interceptors.request.use(
  (config) => {
    const { token } = getAuthStateFromLocalStorage() || {};
    const { activeRegion } = getAvailableRegionsStateFromLocalStorage() || {};

    // Set Authorization header for all requests except the refresh-token endpoint
    if (config.url !== '/refreshtoken' && token) {
      setHeaders({ Authorization: `Bearer ${token}` });
      config.headers['Authorization'] = `Bearer ${token}`;
    }

    if (activeRegion) {
      setHeaders({ 'X-Region': activeRegion.toString() });
      config.headers['X-Region'] = activeRegion;
    }

    for (const header in headers) {
      if (headers.hasOwnProperty(header)) {
        config.headers[header] = headers[header];
      }
    }

    return config;
  },
  (error) => Promise.reject(error)
);

http.interceptors.response.use(
  (response: AxiosResponse) => response,
  async (error: AxiosError) => {
    return Promise.reject(error);

    /* const originalRequest = error.config as AxiosRequestConfig;

    const { refreshToken } = getAuthStateFromLocalStorage();

    if (refreshToken && error.response?.status === 403 && !isRefreshing) {
      isRefreshing = true;
      try {
        const response = await authStore.refreshToken();
        const { success } = response;

        if (success) {
          const data = response.data;
          setHeaders({ Authorization: `Bearer ${data.token}` });
          if (originalRequest) {
            (originalRequest.headers as HeadersCustomInterface)[
              'Authorization'
            ] = `Bearer ${data.token}`;
          }

          processQueue(null, data.token);
          return http(originalRequest);
        }
      } catch (refreshError) {
        processQueue(refreshError as AxiosError, null);
        showSessionExpiredToast();

        window.location.replace('/login');
      } finally {
        isRefreshing = false;
      }
    } else {
      processQueue(error);
      return Promise.reject(error);
    } */
  }
);

export function handleErrors(error: unknown): ErrorResponse {
  const resError = error as AxiosError;
  const data = resError?.response?.data;

  if (data) {
    const { message, success } = data as ErrorResponse;
    return {
      message: message || SOMETHING_WENT_WRONG_TEXT,
      success,
    };
  } else {
    return {
      message: SOMETHING_WENT_WRONG_TEXT,
      success: false,
    };
  }
}

interface ErrorObject {
  [key: string]: any;
}

export const getFirstErrorMessage = (
  errors: ErrorObject,
  success: boolean
): string => {
  const fallbackText = success ? 'Success!' : SOMETHING_WENT_WRONG_TEXT;
  if (!errors || typeof errors !== 'object') {
    return fallbackText;
  }

  // Helper function to recursively find the first error message
  const findFirstError = (obj: ErrorObject): string => {
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const value = obj[key];
        if (typeof value === 'string') {
          return value;
        } else if (typeof value === 'object' && value !== null) {
          const nestedError = findFirstError(value as ErrorObject);
          if (nestedError) {
            return nestedError;
          }
        }
      }
    }
    return '';
  };

  const firstErrorMessage = findFirstError(errors);
  return firstErrorMessage || fallbackText;
};

export const extractMultipleErrors = (
  errorObj: any
): Record<string, string> => {
  const errorState: Record<string, string> = {};

  for (const key in errorObj) {
    if (
      errorObj.hasOwnProperty(key) &&
      errorObj[key] &&
      typeof errorObj[key] === 'object'
    ) {
      const subKeys = Object.keys(errorObj[key]);
      const firstSubPropKey = subKeys[0];

      if (firstSubPropKey) {
        const firstSubPropValue = errorObj[key][firstSubPropKey];
        if (typeof firstSubPropValue === 'string') {
          errorState[key] = firstSubPropValue;
        }
      }
    }
  }

  return errorState;
};

export const showToast = (
  message: string | ErrorObject,
  success: boolean,
  dismiss?: boolean
): void => {
  (success || dismiss) && toast.dismiss();

  if (typeof message === 'string') {
    if (success) {
      toast.success(message);
    } else {
      toast.error(message);
    }
  } else {
    const finalMessage = getFirstErrorMessage(message, success);
    toast.error(finalMessage);
  }
};

export const showSessionExpiredToast = () =>
  toast.error(SESSION_EXPIRED_MESSAGE, {
    position: 'bottom-right',
    autoClose: false, // Keep the toast visible
    hideProgressBar: true, // Disable the progress bar
    closeOnClick: true, // Enable closing the toast on click
    pauseOnHover: true, // Enable closing the toast on click
  });

export default http;
