import { Link } from 'react-router-dom';

import Icon from '../../../components/shared/Icon';
import InfoMessage from '../../../components/shared/InfoMessage';
import { formatDateWithPoints } from '../../../utils';
import { useModal } from '../../../context/modal-context';
import ForumMemberPreview from './ForumMemberPreview';
import { useState } from 'react';
import ForumMembersStore from '../services/forumMembers.store';
import ConfirmDenyDialog from '../../../components/shared/ModalNew/ConfirmDenyDialog';

const forumMembersStore = new ForumMembersStore();

const ForumMembersList = ({ forumMembers }: any) => {
  const { openModal, closeModal } = useModal();

  const handleOpenModal = (e: any, forumMember: any) => {
    e.stopPropagation();
    openModal(<ForumMemberPreview forumMember={forumMember} />, 'Član foruma');
  };

  const deleteForumMember = async (id: number) => {
    await forumMembersStore.deleteForumMember(id);
  };

  const handleOpenDeleteForumMemberModal = (e: any, id: number) => {
    e.stopPropagation();
    openModal(
      <ConfirmDenyDialog
        infoMessage="Da li zaista želite da izbrišete član foruma? Ova akcija je nepovratna i ne može se poništiti."
        onConfirm={() => deleteForumMember(id)}
        onDeny={closeModal}
        closeModal={closeModal}
      />,
      'Brisanje člana'
    );
  };

  return (
    <>
      <ul className="flex flex-col gap-3">
        {forumMembers?.map((member: any) => {
          const formatedDate = formatDateWithPoints(
            member?.date_edited?.date || member?.date_created?.date
          );
          return (
            <li
              onClick={(e: any) => handleOpenModal(e, member)}
              id={member.id}
              key={member.id}
              className="pks-card-bg pks-card-bg-hover overflow-hidden rounded-lg cursor-pointer group"
            >
              <div className="flex gap-4 items-center justify-between ml-[6px] pl-[22px] pr-4 py-3 bg-white">
                <div className="pks-layout-col group">
                  <p className="text-lg font-bold">{member?.name}</p>
                  {member?.organization?.name && (
                    <p className="text-secondary-400">
                      {member?.organization?.name}
                    </p>
                  )}
                  <p className="text-secondary-400">{member?.email}</p>
                </div>
                <div className="inline-flex items-center flex-col lg:flex-row  gap-4">
                  <Link
                    to={`${member.id}`}
                    className="transform scale-125 p-2"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <Icon
                      name="pencil"
                      aria-hidden="true"
                      className="hover:text-primary-300"
                      tabIndex={-1}
                    />
                  </Link>
                  <button
                    onClick={(e) =>
                      handleOpenDeleteForumMemberModal(e, member.id)
                    }
                  >
                    <Icon
                      name="trash"
                      aria-hidden="true"
                      className="text-white"
                      tabIndex={-1}
                    />
                  </button>
                </div>
              </div>
            </li>
          );
        })}
      </ul>
    </>
  );
};

export default ForumMembersList;
