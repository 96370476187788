import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Meta from '../../components/layout/Meta';
import PageLayout from '../../layouts/PageLayout';
import EducationDocumentEntryForm from '../../modules/education/UI/EducationDocumentEntryForm';
import Button from '../../components/shared/Button';
import { BACK_TO_LIST, EDUCATION_PATH } from '../../constants';

function EducationDocumentEntryPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const { slug, id } = useParams();

  const pageTitle = id ? 'Izmeni dokument' : 'Unos dokumenta';

  const currentType = id ? slug : location?.state?.currentType;

  return (
    <>
      <p
        onClick={() => navigate(`${EDUCATION_PATH}/${currentType}`)}
        className="mb-8 flex gap-2 items-center w-fit justify-center b sm:flex-none focus:outline-none group"
      >
        <Button type="button" variant="back" tabIndex={-1}>
          {BACK_TO_LIST}
        </Button>
      </p>
      <Meta title={pageTitle} />
      <PageLayout title={pageTitle}>
        <EducationDocumentEntryForm currentType={currentType} />
      </PageLayout>
    </>
  );
}

export default EducationDocumentEntryPage;
