interface ListboxButtonProps {
  state?: 'error' | 'initial' | 'submitted';
  open?: boolean;
  autoFocus?: boolean;
  isLoading?: boolean;
  isDisabled?: boolean;
}

export const getListboxButtonClassName = ({
  open,
  state,
  autoFocus,
  isDisabled,
}: ListboxButtonProps): string => {
  const className = ['pks-input', 'text-left'];

  if (isDisabled) {
    return 'pks-input-disabled text-left border-gray-300 cursor-not-allowed';
  }

  const initialStateClassName = [
    'border-secondary-300',
    'hover:border-primary-300',
    'focus:border-primary-300-transparent',
    ...(open === true || autoFocus
      ? ['focus:shadow-[0_0_0_2px_var(--color-primary-300-transparent)]']
      : []),
  ];

  const openClassName = {
    error: [
      'border-danger-transparent',
      'shadow-[0_0_0_2px_var(--color-danger-transparent)]',
    ],
    submitted: [
      'border-success-transparent',
      'shadow-[0_0_0_2px_var(--color-success-transparent)]',
    ],
    initial: [
      'border-primary-300-transparent',
      'shadow-[0_0_0_2px_var(--color-primary-300-transparent)]',
    ],
  };

  const closeClassName = {
    error: ['border-danger'],
    submitted: ['border-success'],
    initial: initialStateClassName,
  };

  return [
    ...className,
    ...(open !== undefined
      ? open || autoFocus
        ? openClassName[state ?? 'initial']
        : closeClassName[state ?? 'initial']
      : []),
  ].join(' ');
};

export const handleListboxButtonKeyDown = (
  e: React.KeyboardEvent<HTMLButtonElement>,
  ref: React.MutableRefObject<HTMLButtonElement | null>
): void => {
  if (e.key === 'Enter' || e.key === ' ') {
    e.preventDefault(); // Prevent scrolling when space is pressed
    ref?.current?.click(); // Trigger file input click
  }
};

export const normalizeTimestamp = (timestamp: any) => {
  if (typeof timestamp === 'number' && timestamp < 1e12) {
    return timestamp * 1000;
  }
  if (typeof timestamp === 'number' && timestamp > 1e15) {
    return Math.floor(timestamp / 1000);
  }
  return timestamp ?? Date.now();
};

export function getTimeFromTimestamp(timestamp: any) {
  const normalizedTimestamp = normalizeTimestamp(timestamp);
  const date = new Date(normalizedTimestamp);

  return date.toLocaleTimeString([], {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  });
}

export function getDateFromTimestamp(timestamp: any) {
  const normalizedTimestamp = normalizeTimestamp(timestamp);
  const date = new Date(normalizedTimestamp);

  return date.toLocaleDateString('de-DE', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });
}
