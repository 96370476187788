import { useCallback, useEffect, useRef, useState } from 'react';
import { useSearchParams, LoaderFunctionArgs } from 'react-router-dom';

import Modal, { ModalHandleInterface } from '../../../components/shared/Modal';
import Pagination from '../../../components/shared/Pagination';
import SearchInput from '../../../components/shared/SearchInput';
import OrganizationsList from './OrganizationsList';
import OrganizationPreview from './OrganizationPreview';

import OrganizationsStore from '../services/organizations.store';
/* import RegionsStore from '../../regions/services/regions.store'; */

import { OrganizationInterface } from '../services/organizations.types';
/* import { DropDownItemInterface } from '../../../types'; */
import { useRegions } from '../../../context/regions-context';

interface OrganizationModalInterface {
  organization: OrganizationInterface | undefined;
  isOpen: boolean;
}

const INIT_MODAL_OPTIONS = {
  organization: undefined,
  isOpen: false,
};

const organizationsStore: OrganizationsStore = new OrganizationsStore();
/* const regionsStore: RegionsStore = new RegionsStore(); */

const Organizations = () => {
  const { availableRegionsDropdownItems } = useRegions();
  const [searchParams, setSearchParams] = useSearchParams();

  // Parse URL search parameters
  const page = Number(searchParams.get('page')) || 1;
  const [searchValue, setSearchValue] = useState(
    searchParams.get('search') || ''
  );

  searchParams.delete('search');

  // Create a reference for the modal dialog using a ref hook
  const dialogRef = useRef<ModalHandleInterface>(null);

  // Initialize modal options state with default values
  const [modalOptions, setModalOptions] =
    useState<OrganizationModalInterface>(INIT_MODAL_OPTIONS);

  // Use the `useGetOrganizations` hook from the organizations store to fetch organization data
  const { data: OrganizationsQueryData, isFetching } =
    organizationsStore.useGetOrganizations(page, searchValue);

  const organizations = OrganizationsQueryData?._embedded.organizations;
  const currentPage = OrganizationsQueryData?._page || 1;
  const totalPages = OrganizationsQueryData?._page_count || 1;

  // Use the `useGetRegions` hook from the regions store to fetch regions data
  /* const [initRegionsPage, setInitRegionsPage] = useState(1); */
  /* const { data, isLoading } = regionsStore.useGetRegions({
    page: initRegionsPage,
    searchTerm: '',
  }); */
  /* const [availableRegions, setAvailableRegions] = useState<
    DropDownItemInterface[]
  >([]); */

  // Effect to fetch all pages of regions data to be displayed as dropdown options
  /* useEffect(() => {
    if (!data || isLoading) return;

    const regions = data?._embedded?.regions || [];
    const currentPage = data?._page || 1;
    const regionsPageCount = data?._page_count || 1;

    // Extract and map regions
    const extractedRegions = regions
      // .filter(
      // 	(region: {
      // 		id: number;
      // 		name: string;
      // 		status: RegionStatusEnum ;
      // 	}) => region.status === 1
      // )
      .map((region: any) => ({
        id: region.id,
        label: region.name,
      }));

    // Update available regions with new data
    setAvailableRegions((prevRegions) => [...prevRegions, ...extractedRegions]);

    // Update the page to fetch next set of regions
    if (currentPage < regionsPageCount) {
      setInitRegionsPage(currentPage + 1);
    }
  }, [data, isLoading]); */

  useEffect(() => {
    modalOptions.isOpen
      ? dialogRef.current?.open()
      : dialogRef.current?.close();
  }, [modalOptions.isOpen]);

  /**
   * Updates the options for the modal.
   *
   * @param {any | null} organization
   * @returns {void}
   */
  const updateModalOptions = (
    organization: OrganizationInterface | undefined
  ): void => {
    setModalOptions({
      isOpen: true,
      organization: organization,
    });
  };

  /**
   * Handles the search input change event.
   *
   * @param {string} debouncedValue - The debounced value of the search input.
   * This function updates the URL with the new search query and resets the page number to 1.
   * @returns {void}
   */
  const handleSearch = (debouncedValue: string): void => {
    setSearchValue(debouncedValue);
    setSearchParams({ page: '1' });
  };

  /**
   * Resets the form options to their initial state and closes the modal.
   *
   * This callback function is used to clear any data and reset the state of
   * the form options when the modal is closed.
   *
   * @callback
   * @returns {void}
   */
  const handleCloseModal = useCallback(() => {
    setModalOptions(INIT_MODAL_OPTIONS);
  }, []);

  /**
   * Handles page changes in pagination.
   * Updates the URL with the new page number.
   *
   * @param {number} newPage - The new page number to navigate to.
   */
  const handlePageChange = (newPage: number) => {
    if (newPage !== currentPage) {
      setSearchParams({ page: newPage.toString() });
    }
  };

  return (
    <div className="pks-layout-col-md">
      {/* Institutions List */}
      <SearchInput
        value={searchValue}
        onSearch={handleSearch}
        autofocus
        isFetching={isFetching}
      />
      <OrganizationsList
        organizations={organizations}
        onClick={updateModalOptions}
        searchValue={searchValue}
      />
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
      />

      {/* Institution Details Modal */}
      {modalOptions.isOpen && (
        <Modal
          ref={dialogRef}
          hasActionButton={false}
          onClose={handleCloseModal}
        >
          <OrganizationPreview
            organization={modalOptions.organization}
            regionName={
              (availableRegionsDropdownItems &&
                availableRegionsDropdownItems.find(
                  (reg: any) => reg.id === modalOptions.organization?.region_id
                )?.label) ||
              ''
            }
          />
        </Modal>
      )}
    </div>
  );
};

export default Organizations;

/**
 * Loader function to prefetch organizations.
 */
export async function loader({ request }: LoaderFunctionArgs) {
  const { searchParams } = new URL(request.url);
  const page = Number(searchParams.get('page')) || 1;
  const searchTerm = searchParams.get('search') || '';

  await organizationsStore.preloadOrganizations(page, searchTerm);
  return null;
}
