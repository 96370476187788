import { Link, useRouteError } from 'react-router-dom';

import Meta from '../components/layout/Meta';
import MainNavigation from '../components/layout/MainNavigationNew';
import Button from '../components/shared/Button';

import error_401 from '../assets/images/error/error-401.png';
import error_403 from '../assets/images/error/error-403.png';
import error_404 from '../assets/images/error/error-404.png';
import error_500 from '../assets/images/error/error-500.png';
import error_503 from '../assets/images/error/error-503.png';

import cableLeft from '../assets/images/error/cable-left.png';
import cableLeftMobile from '../assets/images/error/cable-left-mobile.png';
import cableRight from '../assets/images/error/cable-right.png';
import cableRightMobile from '../assets/images/error/cable-right-mobile.png';

import {
	HOME_PAGE_LINK,
	SOMETHING_WENT_WRONG_TEXT,
	TRY_AGAIN_TEXT,
} from '../constants';
import { useLogout } from '../hooks/useLogout';

interface ErrorMessageInterface {
	title: string;
	message: string;
	img?: string;
}

const ERROR_MESSAGES: Record<number | string, ErrorMessageInterface> = {
	401: {
		title: 'Neovlašćen pristup',
		message:
			'Nažalost, nemate ovlašćenje da pristupite ovom sadržaju.<br />Molimo ulogujte se da biste pristupili sadržaju.',
		img: error_401,
	},
	403: {
		title: 'Zabranjen pristup',
		message:
			'Nažalost, pristup ovom sadržaju je zabranjen.<br />Predlažemo da se vratite na početnu stranicu.',
		img: error_403,
	},
	404: {
		title: 'Stranica nije pronađena',
		message:
			'Nažalost, stranica kojoj pokušavate da pristupite nije dostupna.<br />Predlažemo da se vratite na početnu stranicu.',
		img: error_404,
	},
	500: {
		title: 'Greška na serveru',
		message:
			'Nažalost, došlo je do greške na serveru.<br />Pokušajte osvežiti stranicu ili se vratiti na početnu.',
		img: error_500,
	},
	503: {
		title: 'Greška u mreži',
		message:
			'Nažalost, usluga trenutno nije dostupna.<br />Pokušajte ponovo kasnije ili se vratite na početnu stranicu.',
		img: error_503,
	},
	default: {
		title: SOMETHING_WENT_WRONG_TEXT,
		message: TRY_AGAIN_TEXT,
	},
};

const getErrorMessage = (status: number | undefined): ErrorMessageInterface => {
	return status !== undefined && status in ERROR_MESSAGES
		? ERROR_MESSAGES[status]
		: ERROR_MESSAGES.default;
};

const ErrorMessage = ({ message }: { message: string }) => {
	// Split the message into lines
	const lines = message.split('<br />') as string[];

	return (
		<div>
			{lines.map((line, index) => (
				<p key={index}>
					{line}
					{index < lines.length - 1 && <br />}
				</p>
			))}
		</div>
	);
};

interface RouteError {
	response: any;
	status?: number;
	message?: string;
}

function ErrorPage() {
	const error = useRouteError() as RouteError;
	const { handleLogout } = useLogout();

	const status: number | undefined = error?.response?.status || error.status;
	const { title, message, img } = getErrorMessage(status);

	return (
		<>
			<Meta title='Error' />
			<div className='bg-secondary-50 overflow-x-hidden'>
				{status !== 401 && <MainNavigation />}
				<main className='pks-container flex lg:items-center pt-28 pb-8 md:pt-32 md:pb-12 min-h-screen lg:pt-40 lg:pb-40'>
					<div className='relative w-full flex flex-col items-center lg:items-start gap-36 lg:gap-0 lg:justify-between lg:flex-row lg:-mt-40'>
						<div className='relative lg:w-1/2'>
							<div className='pks-layout-col-md flex flex-col gap-8 lg:pr-20'>
								<h1>{title}</h1>
								<ErrorMessage message={message} />
								{status !== 401 && (
									<Link to={HOME_PAGE_LINK} className='z-10'>
										<Button>Na početnu</Button>
									</Link>
								)}

								{status === 401 && (
									<div>
										<Button onClick={handleLogout}>
											Ulogujte se
										</Button>
									</div>
								)}

								{img && <img
									className='absolute -bottom-24 left-0 min-w-[105%] hidden lg:block'
									src={cableLeft}
									alt=''
								/>}
							</div>
						</div>
						<>
							{img && <div className='relative lg:w-1/2'>
								<img
									src={img}
									alt=''
									className='w-10/12 m-auto lg:m-0 lg:ml-auto'
								/>
								<img
									className='absolute -top-24 left-[-14%] min-w-[114%] hidden lg:block'
									src={cableRight}
									alt=''
								/>
								<img
									className='absolute -top-[100px] -left-16 xxs:-left-4 lg:hidden'
									src={cableLeftMobile}
									alt=''
								/>
								<img
									className='absolute -top-[125px] -right-16 xxs:-right-4 lg:hidden'
									src={cableRightMobile}
									alt=''
								/>
							</div>}
						</>
					</div>
				</main>
			</div>
		</>
	);
}

export default ErrorPage;
