import {
  getAuthStateFromLocalStorage,
  handleErrors,
  setHeaders,
} from '../../../utils';
import AuthApi from './auth.api';

import { ActionResponse, ErrorResponse } from '../../../types';
import { LoginResponseDataInterface } from './auth.types';
import { AxiosError } from 'axios';
import { globalWS } from '../../../init';
import { AUTH_KEY } from '../../../constants';

const api: AuthApi = new AuthApi();

class AuthStore {
  authenticateUser = async (
    email: string,
    password: string
  ): Promise<ActionResponse<LoginResponseDataInterface>> => {
    try {
      const res = await api.authenticateUser(email, password);
      const resData = res.data as LoginResponseDataInterface;
      const successMessage =
        'Nastavite unosom MFA koda koji ćete dobiti putem emaila!';

      return {
        success: true,
        message: successMessage,
        data: resData,
      };
    } catch (error) {
      const { success, message } = handleErrors(error);
      return {
        success,
        message,
      };
    }
  };

  logoutUser = async () => {
    try {
      await globalWS.disconnect();
      const res = await api.logoutUser();
      const resData = res.data;
      const successMessage = 'Successfully logged out';
      localStorage.removeItem(AUTH_KEY);
      return {
        success: true,
        message: successMessage,
        data: resData,
      };
    } catch (error) {
      const { success, message } = handleErrors(error);
      return {
        success,
        message,
      };
    }
  };

  verifyCode = async (otpCode: string): Promise<ActionResponse<any>> => {
    try {
      const res = await api.verifyCode(otpCode);
      const resData = res.data as any;
      // await globalWS.connect(resData.access_token, resData.user_id);
      const successMessage = 'Welcome to PKS';

      return {
        success: true,
        message: successMessage,
        data: resData,
      };
    } catch (error) {
      const resError = error as AxiosError;
      const data = resError?.response?.data;

      interface ErrorResponseWithRes extends ErrorResponse {
        response?: string;
      }

      if (data) {
        const { message, response, success, code } =
          data as ErrorResponseWithRes;
        return {
          message: message || response || 'An error occurred.',
          success,
          code: code,
        };
      } else {
        return {
          message: 'Something went wrong.',
          success: false,
        };
      }
    }
  };

  forgetPassword = async (email: string): Promise<ActionResponse<any>> => {
    try {
      const res = await api.forgetPassword(email);
      const resData = res.data as any;
      const successMessage =
        resData?.message || 'Dobrodošli u Privrednu komoru Srbije!';

      return {
        success: true,
        message: successMessage,
        data: resData,
      };
    } catch (error) {
      const { success, message } = handleErrors(error);
      return {
        success,
        message,
      };
    }
  };

  resetPassword = async (
    token: undefined | string,
    password: string,
    repeatedPassword: string
  ) => {
    try {
      const res = await api.resetPassword(token, password, repeatedPassword);
      const resData = res.data as any;
      const successMessage = resData
        ? resData?.message || 'Resetovanje lozinke je uspešno'
        : 'Došlo je do greške!';

      return {
        success: true,
        message: successMessage,
        data: resData,
      };
    } catch (error) {
      const { success, message } = handleErrors(error);
      return {
        success,
        message,
      };
    }
  };

  createPassword = async (
    token: undefined | string,
    password: string,
    repeatedPassword: string
  ) => {
    try {
      const res = await api.createPassword(token, password, repeatedPassword);
      const resData = res.data as any;
      const successMessage = resData
        ? resData?.message || 'Kreiranje lozinke je uspešno'
        : 'Došlo je do greške!';

      return {
        success: true,
        message: successMessage,
        data: resData,
      };
    } catch (error) {
      const { success, message } = handleErrors(error);
      return {
        success,
        message,
      };
    }
  };

  refreshToken = async (): Promise<ActionResponse<any>> => {
    const { refreshToken: refreshTokenValue } =
      getAuthStateFromLocalStorage() || {};

    if (!refreshTokenValue) {
      localStorage.removeItem(AUTH_KEY);
      return { success: false, message: 'No refresh token available' };
    }

    try {
      setHeaders({ Authorization: `Bearer ${refreshTokenValue}` });
      const res = await api.refreshToken();
      const resData = res.data;
      const auth = {
        token: resData.access_token,
        expiresAt: resData.expires_at,
        refreshToken: resData.refresh_token,
        userId: resData.user_id,
        user: resData.user,
      };
      localStorage.setItem(AUTH_KEY, JSON.stringify(auth));
      const successMessage =
        resData?.message || 'Session successfully refreshed!';

      setHeaders({ Authorization: `Bearer ${auth.token}` });

      return {
        success: true,
        message: successMessage,
        data: resData,
      };
    } catch (error) {
      localStorage.removeItem(AUTH_KEY);
      const { success, message } = handleErrors(error);
      return {
        success,
        message,
      };
    }
  };
}

export default AuthStore;
