export enum ChatTabsEnum {
    Direct = 'direct',
    Group = 'group'
}

export type Message = {
    sender: string;
    text: string;
    timestamp: string; 
  };
  
export type Chat = {
    chatId: number;
    participants: string[];
    messages: Message[];
  };