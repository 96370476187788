import TableCellContent from './TableCellContent';

import {
	TableCellWithIdInterface,
	TableHeadingInterface,
} from '../../../types';

/** Table Interface */
interface TableProps {
	headings: TableHeadingInterface[];
	rows: TableCellWithIdInterface[];
	onRowClick?: (...args: any[]) => void;
}

/** Table Component */
const Table: React.FC<TableProps> = ({ headings, rows, onRowClick }) => {
	return (
		<div className='relative overflow-x-auto rounded-lg border border-secondary-200'>
			<table className='w-full rtl:text-right lg:table-fixed'>
				<thead className='text-left bg-secondary-200 text-secondary-400'>
					<tr>
						{headings.map((heading, index) => (
							<th
								key={index}
								scope='col'
								className={`p-4 font-normal whitespace-nowrap ${
									heading.className ? heading.className : ''
								} ${
									index === headings.length - 1
										? 'pr-4 text-right'
										: 'pr-6'
								}`.trim()}>
								{heading.content}
							</th>
						))}
					</tr>
				</thead>
				<tbody>
					{rows.map((row, rowIndex) => {
						return (
							<tr
								id={row.id.toString()}
								onClick={(e) => onRowClick?.(e, row.id)}
								key={rowIndex}
								className={`bg-white text-left ${
									rowIndex !== rows.length - 1
										? 'border-b border-b-secondary-200'
										: ''
								} ${
									onRowClick ? 'cursor-pointer' : ''
								}`.trim()}>
								{row.data.map((cell, cellIndex: number) => (
									<td
										key={cellIndex}
										className={`p-4 pr-4 ${
											cellIndex === row.data.length - 1
												? 'text-right'
												: ''
										}`}>
										<TableCellContent cell={cell} />
									</td>
								))}
							</tr>
						);
					})}
				</tbody>
			</table>
		</div>
	);
};

export default Table;
