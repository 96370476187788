import { useEffect, useRef } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import NewsList from './List';
import NoResultsInfoMessage from '../../../components/shared/InfoMessage/NoResults';
import Pagination from '../../../components/shared/Pagination';
import TransitionLoader from '../../../components/shared/TransitionLoader/TransitionLoader';

import { ViewType, ViewEnum } from '../../../types';
import { URL_PARAMS } from '../services/news.api';
import NewsStore from '../services/news.store';
const newsStore: NewsStore = new NewsStore();

const NEWS = URL_PARAMS.NEWS;

/**
 * NewsPreviewProps Interface
 *
 * Defines the props for the News component.
 * @interface
 * @property {string} [title] - The title of the news section.
 * @property {ViewType} [view] - The view type for displaying the news, either compact, detailed, or grid.
 */
interface NewsPreviewProps {
  title?: string;
  view?: ViewType;
  showInactive?: boolean;
}

/**
 * News Component
 *
 * This component fetches and displays a list of news articles with pagination.
 * It supports different view types (compact, detailed, grid) and provides navigation controls.
 * Loaded via a 'loader' in the router configuration.
 *
 * @param {NewsPreviewProps} props - The props object for the component.
 * @returns {React.JSX.Element | null} The rendered News component or null if data is not available.
 */
const News: React.FC<NewsPreviewProps> = ({
  title,
  view = ViewEnum.Compact,
  showInactive = false,
}: NewsPreviewProps): React.JSX.Element | null => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  // Extract query parameters for pagination
  const page = Number(searchParams.get('page')) || NEWS.page;
  const region = searchParams.get('regionIds[]') || '';
  const rowsPerPage = Number(searchParams.get('rowsPerPage')) || NEWS.limit;

  // Fetch news data using the custom useFetchNews hook
  const { data: newsQueryData, isFetching } = newsStore.useGetNews({
    page,
    region,
    rowsPerPage,
    inactive: showInactive,
  });

  useEffect(() => {
    if (!page || page === 1) return;
    searchParams.set('page', '1');
    setSearchParams(searchParams, { replace: true }); // Use replace to avoid creating a new history entry
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showInactive]);

  //  newsStore.useGetNewsPhoto();

  const articles = newsQueryData?._embedded.news || [];
  const currentPage = newsQueryData?._embedded?._page || 1;
  const totalPages = newsQueryData?._page_count || 1;

  if (!newsQueryData) return null;
  // Handle page change for pagination
  const handlePageChange = (newPage: number) => {
    navigate(`?page=${newPage}${`&rowsPerPage=${rowsPerPage}`}`);
    if (newPage.toString() !== currentPage) {
      searchParams.set('page', newPage.toString());

      if (region) {
        searchParams.set('regionIds[]', region);
      }

      setSearchParams(searchParams);
    }
  };

  return (
    <>
      {isFetching && <TransitionLoader />}
      <aside className="pks-layout-col-md">
        {title && <h2>{title} </h2>}
        <div className="pks-layout-col-md">
          {articles.length === 0 ? (
            <NoResultsInfoMessage />
          ) : (
            <>
              <NewsList news={articles} view={view} />
              <Pagination
                currentPage={page}
                totalPages={totalPages}
                onPageChange={handlePageChange}
              />
            </>
          )}
        </div>
      </aside>
    </>
  );
};

export default News;
