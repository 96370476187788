import {
  keepPreviousData,
  QueryKey,
  queryOptions,
  useQuery,
  UseQueryOptions,
} from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';

import queryClient from '../../../config/tanstackQueryConfig';
import { SOMETHING_WENT_WRONG_TEXT } from '../../../constants';
import {
  ActionResponse,
  ErrorResponse,
  UploadPercentage,
  UploadFileInterface,
} from '../../../types';
import { handleErrors } from '../../../utils';
import { QUERY_KEY as searchQueryKey } from '../../search/services/search.store';
import { QUERY_KEY_STATISTICS } from '../../statistics/services/statistics.store';
import FraudCasesApi from './fraudCases.api';

const QUERY_KEY = 'fraud-cases';
const QUERY_KEY_TYPES = 'fraud-cases-types';
const QUERY_KEY_CARD_TYPES = 'fraud-case-card-types';
const QUERY_KEY_TERMINAL_TYPES = 'fraud-cases-terminal-types';
const QUERY_KEY_COMMENTS = 'fraud-case-comments';
const QUERY_KEY_FILE = 'fraud-case-file';

const api: FraudCasesApi = new FraudCasesApi();

class FraudCaseStore {
  preloadFraudCases = async (
    page?: number,
    category?: string,
    organization?: any
  ) => {
    return await queryClient.fetchQuery(
      fraudCasesQueryOptions(page, category, organization)
    );
  };

  useGetFraudCases = (page?: number, category?: string, organization?: any) => {
    return useQuery(fraudCasesQueryOptions(page, category, organization));
  };

  preloadFraudCase = async (id?: number) => {
    return await queryClient.fetchQuery(fraudCaseQueryOptions(id));
  };

  useGetFraudCase = (id?: number) => {
    return useQuery(fraudCaseQueryOptions(id));
  };

  useGetFraudCasesTypes = () => {
    return useQuery(fraudCasesTypesQueryOptions());
  };

  useGetFraudCardTypes = () => {
    return useQuery(fraudCasesCardTypesQueryOptions());
  };

  useGetFraudCasesTerminalTypes = () => {
    return useQuery(fraudCasesTerminalTypesQueryOptions());
  };

  addFraudCase = async (
    data: FormData,
    onUpload?: (
      percentage: UploadPercentage,
      files?: UploadFileInterface[]
    ) => void
  ): Promise<ActionResponse<any>> => {
    try {
      const res = await api.addFraudCase(data, onUpload);
      const resData = res.data;
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
      queryClient.invalidateQueries({ queryKey: [searchQueryKey] });
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY_STATISTICS] });
      return {
        success: true,
        message: resData?.message || 'Slučaj prevare je uspešno dodat!',
        data: resData,
      };
    } catch (error) {
      const resError = error as AxiosError;
      const data = resError?.response?.data;
      const code = resError?.response?.request?.status || null;

      if (data) {
        const { message, success } = data as ErrorResponse;
        const newMessage = message || data;
        return {
          message: newMessage || SOMETHING_WENT_WRONG_TEXT,
          success,
          code,
        };
      } else {
        return {
          message: SOMETHING_WENT_WRONG_TEXT,
          success: false,
        };
      }
    }
  };

  editFraudCase = async (
    id: number,
    data: any
  ): Promise<ActionResponse<any>> => {
    try {
      const res = await api.editFraudCase(id, data);
      const resData = res.data;
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY, { id }] });
      return {
        success: true,
        message: resData?.message || 'Fraud case successfully edited!',
        data: resData,
      };
    } catch (error) {
      const { message, success } = handleErrors(error);
      return { message, success };
    }
  };

  useGetFraudCaseComments = (id?: string, page?: number) => {
    return useQuery(fraudCasesCommentsQueryOptions(id, page));
  };

  addComment = async (id: number, data: any) => {
    try {
      const res = await api.addFraudCaseComment(id, data);
      const resData = res.data;
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY_COMMENTS],
      });
      return {
        success: true,
        message: resData?.message || 'Komentar je uspešno dodat!',
        data: resData,
      };
    } catch (error) {
      const { message, success } = handleErrors(error);
      return { message, success };
    }
  };

  useGetFraudCaseFiles = (id: number) => {
    return useQuery(fraudCaseFileOptions(id));
  };

  getFraudCaseFile = async (fileId: number) => {
    try {
      const res: any = await api.getFraudCaseFile(fileId);
      const fileUrl = window.URL.createObjectURL(res.data);
      return fileUrl;
    } catch (error) {
      console.error('error');
    }
  };

  deleteFraudCaseFile = async (fileId: number) => {
    try {
      const res = await api.deleteFraudCaseFile(fileId);
      const resData = res.data;
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY_FILE],
      });
      queryClient.invalidateQueries({ queryKey: [searchQueryKey] });
      return {
        success: true,
        message: resData?.message || 'File successfully deleted!',
        data: resData,
      };
    } catch (error) {
      const { message, success } = handleErrors(error);
      return { message, success };
    }
  };

  addFraudCaseFiles = async ({
    id,
    data,
    onUpload,
  }: {
    id: number;
    data: any;
    onUpload: (
      percentage: UploadPercentage,
      files?: UploadFileInterface[]
    ) => void;
  }) => {
    try {
      const docs = data?.documents;

      if (docs.length === 0) {
        return {
          success: false,
          message: null,
        };
      }

      const res = await api.addFraudCaseFiles(id, data, onUpload);
      const resData = res.data;

      queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY_FILE],
      });
      queryClient.invalidateQueries({ queryKey: [searchQueryKey] });

      const hasMultipleDocs = Array.isArray(docs) && docs.length > 1;
      const successMsg = hasMultipleDocs
        ? 'Dokumenti su uspešno otpremljeni!'
        : 'Dokument je uspešno otpremljen!';

      return {
        success: true,
        message: resData?.message || successMsg,
        data: resData,
      };
    } catch (error) {
      const resError = error as AxiosError;
      const data = resError?.response?.data;
      const code = resError?.response?.request?.status || null;

      if (data) {
        const { message, success } = data as ErrorResponse;
        const newMessage = message || data;

        return {
          message: newMessage || SOMETHING_WENT_WRONG_TEXT,
          success,
          code: code,
        };
      } else {
        return {
          message: SOMETHING_WENT_WRONG_TEXT,
          success: false,
        };
      }
    }
  };
}

export default FraudCaseStore;

/**
 * Returns query options for fetching fraud cases with a standard query.
 *
 * @returns {UseQueryOptions<any, Error>} The query options object for use with `useQuery`.
 */

export const fraudCasesQueryOptions = (
  page?: number,
  category?: string,
  organization?: any
): UseQueryOptions<any, Error> =>
  queryOptions({
    queryKey: [QUERY_KEY, { page, category, organization }] as QueryKey,
    queryFn: async (): Promise<any> => {
      const response: AxiosResponse<any> = await api.getFraudCases(
        page,
        category,
        organization
      );
      return response.data;
    },
    placeholderData: keepPreviousData,
  });

/**
 * Returns query options for fetching single fraud case with a standard query.
 *
 * @returns {UseQueryOptions<any, Error>} The query options object for use with `useQuery`.
 */
export const fraudCaseQueryOptions = (
  id?: number
): UseQueryOptions<any, Error> =>
  queryOptions({
    queryKey: [QUERY_KEY, { id }] as QueryKey,
    queryFn: async (): Promise<any> => {
      const response: AxiosResponse<any> = await api.getFraudCase(id);
      return response.data;
    },
    enabled: !!id,
    placeholderData: keepPreviousData,
  });

/**
 * Returns query options for fetching fraud cases types with a standard query.
 *
 * @returns {UseQueryOptions<any, Error>} The query options object for use with `useQuery`.
 */
export const fraudCasesTypesQueryOptions = (): UseQueryOptions<any, Error> =>
  queryOptions({
    queryKey: [QUERY_KEY_TYPES] as QueryKey,
    queryFn: async (): Promise<any> => {
      const response: AxiosResponse<any> = await api.getFraudCasesTypes();
      return response.data;
    },
    placeholderData: keepPreviousData,
  });

/**
 * Returns query options for fetching fraud cases card types with a standard query.
 *
 * @returns {UseQueryOptions<any, Error>} The query options object for use with `useQuery`.
 */
export const fraudCasesCardTypesQueryOptions = (): UseQueryOptions<
  any,
  Error
> =>
  queryOptions({
    queryKey: [QUERY_KEY_CARD_TYPES] as QueryKey,
    queryFn: async (): Promise<any> => {
      const response: AxiosResponse<any> = await api.getFraudCasesCardTypes();
      return response.data;
    },
    placeholderData: keepPreviousData,
  });

/**
 * Returns query options for fetching fraud cases terminal types with a standard query.
 *
 * @returns {UseQueryOptions<any, Error>} The query options object for use with `useQuery`.
 */
export const fraudCasesTerminalTypesQueryOptions = (): UseQueryOptions<
  any,
  Error
> =>
  queryOptions({
    queryKey: [QUERY_KEY_TERMINAL_TYPES] as QueryKey,
    queryFn: async (): Promise<any> => {
      const response: AxiosResponse<any> =
        await api.getFraudCasesTerminalTypes();
      return response.data;
    },
    placeholderData: keepPreviousData,
  });

/**
 * Returns query options for fetching fraud case comments with a standard query.
 *
 * @returns {UseQueryOptions<any, Error>} The query options object for use with `useQuery`.
 */
export const fraudCasesCommentsQueryOptions = (
  id?: string,
  page?: number
): UseQueryOptions<any, Error> =>
  queryOptions({
    queryKey: [QUERY_KEY_COMMENTS, { id }] as QueryKey,
    queryFn: async (): Promise<any> => {
      const response: AxiosResponse<any> = await api.getFraudCaseComments(
        Number(id),
        page
      );
      return response.data;
    },
    enabled: !!id,
    placeholderData: keepPreviousData,
  });

const fraudCaseFileOptions = (id: number): UseQueryOptions<any, Error> =>
  queryOptions({
    queryKey: [QUERY_KEY_FILE, { id }] as QueryKey,
    queryFn: async (): Promise<any> => {
      const response: AxiosResponse<any> = await api.getFraudCaseFiles(
        Number(id)
      );
      return response.data;
    },
    enabled: !!id,
    placeholderData: keepPreviousData,
  });

// const deleteFraudCaseFileOptions = (fileId: number): UseQueryOptions<any, Error> => queryOptions({
// 	queryKey: [QUERY_KEY_FILE, { fileId }] as QueryKey,
// 	queryFn: async (): Promise<any> => {
// 		const response: AxiosResponse<any> = await api.deleteFraudCaseFile(
// 			Number(fileId)
// 		)
// 		return response.data
// 	},
// 	enabled: !!fileId,
// 	placeholderData: keepPreviousData
// 	})
