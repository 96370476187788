import { useRef, useState } from 'react';
import Icon from '../../../components/shared/Icon';

interface RegionChatConversationsProps {
  chat: any;
  currentOpenChat: any | null;
  groupName?: any;
  allUsers: any;
  countUnseenForRegion: any;
}

const ChatGroupConversation = ({
  chat,
  currentOpenChat,
  allUsers,
  countUnseenForRegion,
  groupName,
}: RegionChatConversationsProps) => {
  const isCurrentChat = currentOpenChat?.id === chat?.id;
  const [isOpen, setIsOpen] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);

  const toggleOpen = (e: any) => {
    e.stopPropagation();
    setIsOpen(!isOpen);
  };

  return (
    <div
      className={` h-auto p-4 rounded-lg shadow-card cursor-pointer   ${
        isCurrentChat ? 'bg-primary-300' : 'bg-white'
      }`}
    >
      <div className="flex justify-between items-center  border-b pb-2 border-b-secondary-200">
        <p
          className={`font-bold ${isCurrentChat ? 'text-white' : 'text-black'}`}
        >
          Region: {groupName}
        </p>
        <div className="flex flex-col items-end justify-end gap-2">
          {countUnseenForRegion > 0 && (
            <div
              className={`text-sm size-6 rounded-full flex items-center justify-center  ${
                isCurrentChat
                  ? 'text-black bg-white'
                  : 'text-white bg-primary-300'
              } shadow-card`}
            >
              {countUnseenForRegion}
            </div>
          )}
        </div>
      </div>

      {/* Toggle Button */}
      {/* Collapsible Content */}
      <div className="space-y-1 mt-3  ">
        {allUsers.length > 0 && (
          <>
            <span
              className={`flex items-center justify-between cursor-pointer ${
                isCurrentChat
                  ? 'text-white font-medium'
                  : 'text-blackfont-medium'
              }`}
              onClick={(e) => toggleOpen(e)}
            >
              <p className="">Članovi</p>
              <Icon
                name={'chevronDown'}
                className={`${
                  isOpen && 'rotate-180'
                } transition-transform duration-100 `}
              />
            </span>

            <div
              ref={contentRef}
              className={`overflow-hidden transition-all duration-500 ease-in-out max-h-[150px] overflow-y-scroll scrollbar ${
                isCurrentChat ? 'text-white ' : 'text-black'
              } ${isOpen ? 'max-h-screen' : 'max-h-0'}`}
              style={{ height: isOpen ? contentRef.current?.scrollHeight : 0 }}
            >
              {allUsers?.map((member: any, index: number) => (
                <p key={index}>
                  {member.firstName} {member.lastName}
                </p>
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default ChatGroupConversation;
