export const WEBSITE_NAME = 'Privredna komora Srbije';
export const LOADING_TEXT = 'Učitavanje...';
export const SUBMITTING_TEXT = 'Slanje...';
export const EDITING_TEXT = 'Izmeni';
export const ADD_TEXT = 'Dodaj';
export const ADD_ORGANIZATION = 'Dodaj instituciju';
export const EDIT_ORGANIZATION = 'Izmeni instituciju';
export const CANCEL = 'Odustani';
export const CLOSE = 'Zatvori';
export const SEND = 'Pošalji';
export const BACK = 'Nazad';
export const BACK_TO_LIST = 'Nazad na listu';
export const BACK_TO_NEWS = 'Nazad na vesti';
export const SOMETHING_WENT_WRONG_TEXT = 'Nešto je krenulo po zlu.';
export const TRY_AGAIN_TEXT =
  'Došlo je do greške. Molimo pokušajte ponovo kasnije.';
export const SEARCH_TEXT = 'Pretraži';
export const SEARCH_RESULTS_EMPTY = 'Nema pronađenih rezultata.';
export const SEARCH_RESULTS_EMPTY_TERM = 'Nema pronađenih rezultata za pojam: ';
export const VIEW_ALL_RESULTS = 'Pogledaj sve rezultate';
export const VISIT_PROFILE_TEXT = 'Pogledaj profil';
export const LOGOUT_TEXT = 'Izloguj se';
export const CONFIRM_TEXT = 'Potvrdi';
