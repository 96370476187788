import {
  useState,
  useRef,
  useEffect,
  ChangeEvent,
  ClipboardEvent,
  FocusEvent,
  FormEvent,
  KeyboardEvent,
} from 'react';
import { useNavigate, Form } from 'react-router-dom';

import Label from '../../../components/shared/Label';
import Button from '../../../components/shared/Button';

import AuthStore from '../services/auth.store';
import {
  getAuthStateFromLocalStorage,
  showToast,
  testRegex,
} from '../../../utils';

import {
  ALPHANUMERIC_REGEX,
  SUBMITTING_TEXT,
  LOGIN_EMAIL_VERIFICATION_PAGE_LINK,
  LOGIN_PAGE_LINK,
  ACTIVE_REGION_KEY,
} from '../../../constants';
import {
  AvailableRegionInterface,
  AvailableRegionsInterface,
} from '../../../types';

const FIELDS_LEN = 6;
const setOtpInitVal = (fieldsLength = FIELDS_LEN) =>
  Array(fieldsLength).fill('');

const authStore: AuthStore = new AuthStore();

/**
 * @interface EmailVerificationFormProps
 * @description Props for the EmailVerificationForm component, which handles OTP input and verification.
 */
interface EmailVerificationFormProps {
  /**
   * The label displayed above the OTP input fields.
   * @type {string}
   * @default 'Unesi OTP kód poslat na email'
   * @optional
   */
  label?: string;

  /**
   * The number of OTP input fields.
   * @type {number}
   * @default 6
   * @optional
   */
  fieldsLength?: number;
}

/**
 * EmailVerificationForm component handles the input and submission of a One-Time Password (OTP)
 * sent via email for verification purposes. It manages an array of input fields and validates
 * the entered OTP before submission.
 *
 * @component
 * @param {EmailVerificationFormProps} props Props for the component.
 * @returns {JSX.Element} The rendered OTP input form.
 */
const EmailVerificationForm = ({
  label = 'Unesi OTP kód poslat na email *',
  fieldsLength = FIELDS_LEN,
}: EmailVerificationFormProps): JSX.Element => {
  const navigate = useNavigate();

  const [otpDigits, setOtpDigits] = useState<string[]>(setOtpInitVal); // Array with {fieldsLength} empty strings
  const [otpError, setOtpError] = useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const submitButtonRef = useRef<HTMLButtonElement>(null);

  const inputRefs = useRef<(HTMLInputElement | null)[]>([]);
  const inputClassName =
    'pks-input pks-input-initial pks-input-h-full pks-input-px-0 text-center sm:text-xl font-bold uppercase';

  useEffect(() => {
    // Focus on submit button when all OTP inputs are filled
    const isValid = otpDigits.every((digit) => digit !== '');
    if (isValid && submitButtonRef.current) {
      submitButtonRef.current.focus();
    }
  }, [otpDigits]);

  /**
   * Handles keydown events for OTP input fields.
   * Prevents input of non-alphanumeric and non-number characters and manages navigation on delete/backspace.
   *
   * @param {KeyboardEvent<HTMLInputElement>} e - The keydown event.
   * @returns {void}
   */
  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>): void => {
    if (
      !/^[a-zA-Z0-9]{1}$/.test(e.key) &&
      e.key !== 'Backspace' &&
      e.key !== 'Delete' &&
      e.key !== 'Tab' &&
      !e.metaKey
    ) {
      e.preventDefault();
    }

    if (e.key === 'Delete' || e.key === 'Backspace') {
      const index = inputRefs.current.indexOf(e.target as HTMLInputElement);
      if (index > 0) {
        setOtpDigits((prevOtp) => [
          ...prevOtp.slice(0, index - 1),
          '',
          ...prevOtp.slice(index),
        ]);
        inputRefs.current[index - 1]?.focus();
      }
    }
  };

  /**
   * Handles input events for OTP fields, updating the state and focusing the next input field.
   *
   * @param {ChangeEvent<HTMLInputElement>} e - The change event.
   * @returns {void}
   */
  const handleInput = (e: ChangeEvent<HTMLInputElement>): void => {
    const { target } = e;
    const index = inputRefs.current.indexOf(target as HTMLInputElement);
    if (target.value) {
      setOtpDigits((prevOtp) => [
        ...prevOtp.slice(0, index),
        target.value,
        ...prevOtp.slice(index + 1),
      ]);
      if (index < otpDigits.length - 1) {
        inputRefs.current[index + 1]?.focus();
      }
    }
  };

  /**
   * Handles focus events for OTP fields, clearing any error message and selecting the input value.
   *
   * @param {FocusEvent<HTMLInputElement>} e - The focus event.
   * @returns {void}
   */
  const handleFocus = (e: FocusEvent<HTMLInputElement>): void => {
    otpError && setOtpError(null);
    e.target.select();
  };

  /**
   * Handles paste events for OTP fields, extracting and setting the pasted value.
   *
   * @param {ClipboardEvent<HTMLInputElement>} e - The paste event.
   * @returns {void}
   */
  const handlePaste = (e: ClipboardEvent<HTMLInputElement>): void => {
    e.preventDefault();
    const text = e.clipboardData.getData('text');

    if (!new RegExp(`^[a-zA-Z0-9]{${otpDigits.length}}$`).test(text)) {
      return;
    }
    const digits = text.split('');
    setOtpDigits(digits);
  };

  /**
   * Focuses the first empty OTP input field.
   *
   * @returns {void}
   */
  const handleEmptyInputFocus = (): void => {
    const firstEmptyIndex = otpDigits.findIndex((digit) => digit === '');
    if (firstEmptyIndex !== -1) {
      inputRefs.current[firstEmptyIndex]?.focus();
    }
  };

  /**
   * Handles form submission, validates the OTP, and interacts with the authentication API.
   *
   * @param {FormEvent<HTMLFormElement>} e - The form submission event.
   */
  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // Combine the individual OTP digits into a single string and trim any whitespace
    const otpCode = otpDigits.join('').trim();

    // Validate the OTP code
    const isValid =
      testRegex(ALPHANUMERIC_REGEX, otpCode) && otpCode.length === fieldsLength;

    // If the OTP code is invalid, set error message and focus on the first empty input
    if (!isValid) {
      const otpErrorMessage = `OTP kod mora sadržati ${fieldsLength} brojeva`;
      setOtpError(otpErrorMessage);
      // Focus on the first empty input
      for (const ref of inputRefs.current) {
        if (ref && ref.value === '') {
          ref.focus();
          break;
        }
      }
      return;
    }

    setOtpError(null);

    //Get 'userId' from local storage
    const { userId } = getAuthStateFromLocalStorage();

    if (!userId) {
      navigate(LOGIN_EMAIL_VERIFICATION_PAGE_LINK, { replace: true });
    }

    setIsSubmitting(true);

    const response = await authStore.verifyCode(otpCode);
    const { success, message } = response;

    if (success) {
      const resData = response.data;

      if (!resData) {
        showToast(message, !success);
        setIsSubmitting(false);
        return;
      }

      // Store the authentication object in local storage
      const auth = {
        token: resData.access_token,
        expiresAt: resData.expires_at,
        refreshToken: resData.refresh_token,
        userId: resData.user_id,
        user: resData.user,
      };
      localStorage.setItem('auth', JSON.stringify(auth));

      const regions = resData.regions;
      const transformedRegions: AvailableRegionsInterface = {
        allRegions: regions.allRegions,
        regions: regions.regions
          .filter((region: AvailableRegionInterface) => region.status === 1) // Filter regions with status 1
          .map((region: AvailableRegionInterface) => ({
            id: region.id,
            name: region.name,
          })),
        activeRegion: localStorage.getItem(ACTIVE_REGION_KEY)
          ? parseInt(localStorage.getItem(ACTIVE_REGION_KEY) as string)
          : regions.regions.length > 0
          ? regions.regions[0].id
          : undefined,
      };
      localStorage.setItem('regions', JSON.stringify(transformedRegions));

      // Show toast success
      showToast(message, success);

      // Navigate to the home page
      navigate('/', { replace: true });
      /* SWITCH TO HOME */
    } else {
      // Handle error response
      showToast(message, success, true);
      if (response.code) {
        navigate(LOGIN_PAGE_LINK, { replace: true });
      }
      setIsSubmitting(false);
    }
  };

  return (
    <div>
      {/* Heading */}
      {label && <Label label={label} onClick={handleEmptyInputFocus} />}
      {/* Email Verification Form */}
      <Form method="post" onSubmit={handleSubmit}>
        <div className="pks-layout-col-xl">
          <div>
            {/* OTP Input Field */}
            <div className="flex align-center justify-between gap-2 sm:gap-4">
              {otpDigits.map((digit, index) => {
                const id = `otp-${index + 1}`;

                return (
                  <div key={id} className="aspect-square">
                    <label htmlFor={id} className="sr-only">
                      OTP 1
                    </label>
                    <input
                      id={id}
                      name={id}
                      type="text"
                      value={digit}
                      maxLength={1}
                      autoComplete="off"
                      onChange={handleInput}
                      onKeyDown={handleKeyDown}
                      autoFocus={index === 0}
                      onFocus={handleFocus}
                      onPaste={handlePaste}
                      className={inputClassName}
                      ref={(el) =>
                        (inputRefs.current[index] = el as HTMLInputElement)
                      }
                    />
                  </div>
                );
              })}
            </div>
            {/* Error */}
            {otpError && <div className="text-danger">{otpError}</div>}
          </div>
          {/* Submit Button */}
          <Button
            ref={submitButtonRef}
            type="submit"
            className="sm:w-full"
            ariaLabel="Potvrdi kód"
            disabled={isSubmitting}
          >
            {isSubmitting ? SUBMITTING_TEXT : 'Potvrdi kód'}
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default EmailVerificationForm;
