import FreudCasesPreview from '../../modules/fraudCasesOld/UI/Preview';
import StatisticsList from '../../modules/statistics/UI/List';

import StatisticsStore from '../../modules/statistics/services/statistics.store';
const statisticsStore = new StatisticsStore();

const PreviewWithStatisticsSidebar = () => {
  const { data: statistics } = statisticsStore.useGetStatistics();

  return (
    <div className="pks-layout-col-xl">
      <FreudCasesPreview />
      {statistics && <StatisticsList data={statistics} />}
    </div>
  );
};

export default PreviewWithStatisticsSidebar;
