import Icon from '../../../components/shared/Icon';
import * as ICONS from '../../../components/shared/Icon/Icons';

interface ChatSkeletonProps {
  iconName: keyof typeof ICONS;
  type: string;
  wrapperClassNames?: string;
}

const ChatSkeleton = ({
  iconName,
  type = 'gray',
  wrapperClassNames,
}: ChatSkeletonProps) => {
  const isGrayType = type === 'gray';

  return (
    <div
      className={`${
        isGrayType
          ? 'bg-secondary-200 border border-transparent'
          : 'bg-white border-secondary-200 border'
      } w-[277px] h-[66px] relative rounded-lg flex gap-2 items-center justify-center p-4 ${wrapperClassNames}`}
    >
      <div
        className={`size-8 ${
          isGrayType ? 'bg-white' : 'bg-secondary-200'
        } rounded-lg`}
      ></div>
      <div className="flex-1 flex flex-col gap-1">
        <div
          className={`rounded-sm h-1 w-6/12 ${
            isGrayType ? 'bg-white' : 'bg-secondary-200'
          }`}
        ></div>
        <div
          className={`rounded-sm h-1 w-10/12 ${
            isGrayType ? 'bg-white' : 'bg-secondary-200'
          }`}
        ></div>
        <div className="rounded-sm h-1 w-2/12 bg-secondary-100"></div>
      </div>
      <div
        className={`p-2 rounded-lg absolute  shadow-card ${
          isGrayType
            ? 'bg-white left-0 top-0 -translate-y-1/2 -translate-x-1/2 '
            : 'bg-secondary-200 right-0 bottom-3  translate-x-1/2 '
        }`}
      >
        <Icon name={iconName} />
      </div>
    </div>
  );
};

export default ChatSkeleton;
