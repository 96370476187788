import { useLoaderData, useNavigate } from 'react-router-dom';
import { FieldValues, SubmitHandler } from 'react-hook-form';

import Button from '../../components/shared/Button';
import ForumCouncilForm from '../../modules/info/UI/ForumCouncilForm';
import Icon from '../../components/shared/Icon';
import Info from '../../modules/info/UI';
import Meta from '../../components/layout/Meta';
import NoResultsInfoMessage from '../../components/shared/InfoMessage/NoResults';
import PageLayout from '../../layouts/PageLayout';
import PageTitleWithActions from '../../layouts/PageLayout/TitleWithActions';

import queryClient from '../../config/tanstackQueryConfig';
import { useModal } from '../../context/modal-context';
import { usePaginatedDropdown } from '../../hooks/usePaginatedDropdown';
import { ActionResponse, DropDownItemInterface } from '../../types';

import InfoStore, {
  forumCouncilQueryOptions,
} from '../../modules/info/services/info.store';
import ForumMembersStore from '../../modules/forumMembers/services/forumMembers.store';
import {
  decodeHtmlEntities,
  extractMultipleErrors,
  hasNestedObjects,
  showToast,
} from '../../utils';
import { toast } from 'react-toastify';

const infoStore: InfoStore = new InfoStore();
const forumMembersStore: ForumMembersStore = new ForumMembersStore();

const PAGE_TITLE = 'Savet foruma';

interface UserInterface {
  userId: number;
  fullname: string;
  organization: string;
  email: string;
}

function ForumCouncil() {
  const navigate = useNavigate();
  const { data } = useLoaderData() as LoaderData;

  const { openModal, closeModal } = useModal();

  const transformContentData = (content: any[]) => {
    return content.map((item, index) => ({
      id: `fc-${index + 1}`,
      fullname: item?.fullname,
      email: item?.email,
      organization: item?.organization,
      position: item.position && decodeHtmlEntities(item.position),
    }));
  };

  const forumCouncilMembers = data ? transformContentData(data.content) : null;

  // Get Forum Members
  const useGetAllForumMembers = () => {
    return usePaginatedDropdown({
      fetchData: (page) => forumMembersStore.useGetForumMembers(page, ''),
      extractItems: (data) => {
        return (data?.users || []).map((user: any) => ({
          userId: user.id.toString(),
          fullname: user.first_name + ' ' + user.last_name,
          organization: user.organization.name,
          email: user.email,
        }));
      },
    });
  };

  const { finalItems: allUsers, isLoadingRef: isLoadingUsers } =
    useGetAllForumMembers() as any;

  const allUsersDropdown: DropDownItemInterface[] = allUsers.map(
    (user: any) => ({
      id: user.userId,
      label: user.fullname,
    })
  );

  const defaultValues: any = data
    ? {
        users:
          forumCouncilMembers
            ?.filter(({ email }) =>
              allUsers.some((user: any) => user.email === email)
            )
            .map(({ email, position }) => {
              const matchingUser = allUsers.find(
                (user: any) => user.email === email
              );
              return matchingUser
                ? { id: matchingUser.userId, position }
                : null;
            })
            .filter(Boolean) || null, // Ensure to remove null values
        regionId: data?.regionId,
      }
    : null;

  const handleSubmit: SubmitHandler<FieldValues> = async (formData) => {
    const submittedUsers = formData.users;
    const submittedRegion = formData.regionId;

    // Find matching users in `allUsers` and add position
    const preparedData = submittedUsers.map((submittedUser: any) => {
      const matchingUser: UserInterface = allUsers.find(
        (user: any) => user.userId === submittedUser.id
      );

      if (matchingUser) {
        const { userId, ...restUserDetails } = matchingUser; // Exclude `userId`
        return {
          ...restUserDetails,
          position: submittedUser.position, // Add position to user data
        };
      }

      // Handle case where the user ID doesn't exist in `allUsers`
      console.warn(`User with ID ${submittedUser.id} not found.`);
      return null;
    });

    const data = {
      contentJson: preparedData,
      regionId: submittedRegion,
    };

    const { success, message } = await infoStore.addForumCouncil({ data });

    if (success) {
      typeof message === 'string' && toast.success(message);
      closeModal();
      navigate('.', { replace: true, state: { key: Date.now() } });
    } else {
      if (hasNestedObjects(message)) {
        const errorMessages = extractMultipleErrors(message);
        toast.error(Object.values(errorMessages)[0] || 'Greška!');
      } else {
        showToast(message, success, true);
      }
    }
  };

  const handleOpenModal = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    openModal(
      <ForumCouncilForm
        users={allUsersDropdown}
        onSubmit={handleSubmit}
        defaultValues={defaultValues}
        isLoading={isLoadingUsers?.current ? true : false}
      />,
      data ? 'Izmeni članove' : 'Novi članovi'
    );
  };

  return (
    <>
      <Meta title={PAGE_TITLE} />
      <PageLayout>
        <PageTitleWithActions title={PAGE_TITLE}>
          <Button className="flex gap-2 items-center" onClick={handleOpenModal}>
            <Icon name="pencil" />
            {data ? 'Izmeni članove' : 'Dodaj članove'}
          </Button>
        </PageTitleWithActions>
        {data?.date && <p>{data.date}</p>}
        <Info>
          <>
            {forumCouncilMembers ? (
              <>
                <ul className="flex flex-col gap-3">
                  {forumCouncilMembers.map((member) => (
                    <li key={member.id} className="pks-card rounded-lg p-2">
                      <div className="flex sm:items-center sm:justify-between flex-col gap-2 sm:flex-row">
                        <div className="flex flex-col gap-2">
                          <span className="font-bold text-lg">
                            {member.fullname}
                          </span>
                          <span className="font-bold sm:hidden">
                            {member.position}
                          </span>
                          <span className="text-secondary-400">
                            {member.organization}
                          </span>
                          <span className="text-secondary-400">
                            {member.email}
                          </span>
                        </div>
                        <div className="hidden sm:block">
                          <span className="text-secondary-400">
                            {member.position}
                          </span>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </>
            ) : (
              <NoResultsInfoMessage />
            )}
          </>
        </Info>
      </PageLayout>
    </>
  );
}

export default ForumCouncil;

// Loader Function
interface LoaderData {
  data: any | null;
}

export async function loader(): Promise<LoaderData> {
  const response = (await queryClient.fetchQuery(
    forumCouncilQueryOptions()
  )) as ActionResponse<any>;

  return {
    data: response.success ? response.data || null : null,
  };
}
