import { To } from 'react-router-dom';
import { MegaMenuProps, MenuDataProps } from '../../../types';
import MainLink from './MainLink';
import { menuData } from './menuData';

const MegaMenu = ({
  handleToggle,
  handleToggleSubmenu,
  clicked,
  setIsDrawerOpen,
  clickedSubMenu,
}: MegaMenuProps) => {
  return (
    <nav className="  lg:h-full ">
      <ul className=" flex flex-col lg:flex-row h-full w-full items-start lg:items-center justify-center  ">
        {menuData.map(
          (
            { label, href, children, pathName, iconName }: MenuDataProps,
            index
          ) => (
            <MainLink
              key={index}
              to={href as To}
              iconName={iconName}
              pathName={pathName}
              label={label}
              childrenMenu={children as unknown as MenuDataProps}
              setIsDrawerOpen={setIsDrawerOpen}
              onToggle={() => handleToggle && handleToggle(index)}
              onToggleSubmenu={handleToggleSubmenu}
              active={clicked === index}
              clickedSubMenu={clickedSubMenu}
            />
          )
        )}
      </ul>
    </nav>
  );
};

export default MegaMenu;
