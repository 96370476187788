import { useEffect, useRef, useState } from 'react';
import ChatConversationPanel from './ChatConversationPanel';
import ChatSidebar from './ChatSidebar';
import { Chat } from '../../../types/Chat';
import ForumMembersStore from '../../forumMembers/services/forumMembers.store';
import { usePaginatedDropdown } from '../../../hooks/usePaginatedDropdown';
import TransitionLoader from '../../../components/shared/TransitionLoader/TransitionLoader';
import { globalWS } from '../../../init';
import { useAuth } from '../../../context/auth-context';
import { useRegions } from '../../../context/regions-context';
const forumMembersStore: ForumMembersStore = new ForumMembersStore();

const ChatLayout = ({
  allUsers,
  allRegions,
  isLoadingUsers,
  isLoadingRegions,
}: any) => {
  const { activeRegion } = useRegions();
  const { userId } = useAuth();

  const [regionChatsList, setRegionChatsList] = useState<any>([]);
  const [latestMessagesList, setLatestMessagesList] = useState<any>([]);
  const [individualConversationIds, setIndividualConversationIds] =
    useState<any>([]);
  const [individualChats, setIndividualChats] = useState<any>([]);
  const [currentOpenChat, setCurrentOpenChat] = useState<Chat | null>(null);
  const [isPanelVisible, setPanelVisible] = useState(false);

  // const [sortingChatSidebarOnFrontend, setSortingChatSidebarOnFrontend] =
  //   useState(false);

  const [isMobile, setIsMobile] = useState(window.innerWidth < 1050);

  // USER AVATAR HANDLING
  // const [avatars, setAvatars] = useState<{ [id: string]: File }>({});
  // const [avatarsUrls, setAvatarsUrls] = useState<{ [id: string]: string }>({});
  // const avatarsUrlsRef = useRef<{ [id: string]: string }>({});

  // const avatarsFetchedRef = useRef(false);
  //
  // useEffect(() => {
  //   console.log(allUsers, 'CHANGE?');
  // }, [allUsers]);

  // useEffect(() => {
  //   if (individualConversationIds.length === 0 || avatarsFetchedRef.current)
  //     return;

  //   const newAvatars: { [id: string]: File } = {};
  //   const fetchAvatars = async () => {
  //     for (const conversation of individualConversationIds) {
  //       const { userId } = conversation;

  //       if (!avatars[userId]) {
  //         const response = await forumMembersStore.getForumMemberAvatar(
  //           +userId
  //         );

  //         if (response.success && response.data instanceof File) {
  //           newAvatars[userId] = response.data;
  //         } else if (response.success === undefined) {
  //           console.warn('Response success is undefined');
  //           continue;
  //         }
  //       }
  //     }

  //     if (Object.keys(newAvatars).length > 0) {
  //       setAvatars((prevAvatars) => ({ ...prevAvatars, ...newAvatars }));
  //     }
  //   };

  //   fetchAvatars();
  //   avatarsFetchedRef.current = true;
  // }, [avatars, individualConversationIds]);

  // useEffect(() => {
  //   const generateAvatarUrls = () => {
  //     const newUrls: { [id: string]: string } = { ...avatarsUrlsRef.current };

  //     for (const id in avatars) {
  //       const avatarFile = avatars[id];
  //       if (
  //         avatarFile &&
  //         avatarFile instanceof File &&
  //         !avatarsUrlsRef.current[id]
  //       ) {
  //         newUrls[id] = URL.createObjectURL(avatarFile);
  //       }
  //     }

  //     avatarsUrlsRef.current = newUrls;
  //     // setAvatarsUrls(newUrls);
  //   };

  //   if (Object.keys(avatars).length > 0) {
  //     generateAvatarUrls();
  //   }

  //   return () => {
  //     Object.values(avatarsUrlsRef.current).forEach((url) =>
  //       URL.revokeObjectURL(url)
  //     );
  //   };
  // }, [avatars]);

  function getChatPartner(userId: any, arr: any) {
    if (arr[0] === arr[1] && arr[0] === userId) {
      return userId;
    }
    return arr[0] === userId ? arr[1] : arr[0];
  }
  // Create individualChats with chatPartnerId for more convenient use of ids
  useEffect(() => {
    if (allUsers && latestMessagesList) {
      let latestIndividualChats = latestMessagesList;
      latestIndividualChats.map((chat: any, index: number) => {
        const chatPartnerId = getChatPartner(userId, chat.memberIds);
        allUsers.filter((user: any) => {
          if (+chatPartnerId === +user.userId) {
            latestIndividualChats[index].chatPartnerId = chatPartnerId;
            latestIndividualChats[index].chatPartnerName = user;
          } else return null;
        });
      });
      setIndividualChats(latestIndividualChats);
    }
  }, [allUsers, latestMessagesList, userId]);

  // Get only unique ids of latesMessagesList
  useEffect(() => {
    if (allUsers && latestMessagesList) {
      const getUniqueUserIds = () => {
        const allIds = latestMessagesList
          .map((conversation: any) => conversation.memberIds)
          .flat();
        return Array.from(new Set(allIds));
      };

      const createChatList = () => {
        const uniqueIds = getUniqueUserIds();
        return allUsers.filter((user: any) => uniqueIds.includes(+user.userId));
      };

      const chatList = createChatList();
      setIndividualConversationIds(chatList);
    }
    const handleSetConversationList = (conversations: any) => {
      setLatestMessagesList(conversations);
    };
    globalWS.on('latestMessagesReceived', handleSetConversationList);
    return () => {
      globalWS.off('latestMessagesReceived', handleSetConversationList);
    };
  }, [allUsers, isLoadingUsers, latestMessagesList]);

  const handleChatClick = (chat: any | null) => {
    setCurrentOpenChat(chat);
    if (isMobile) {
      setPanelVisible(true);
    }
  };

  useEffect(() => {
    const handleSetGroupChatsList = (groupChats: any) => {
      const chats = [];
      chats.push(groupChats);
      setRegionChatsList(chats);
    };
    globalWS.on('regionChatsReceived', handleSetGroupChatsList);
    return () => {
      globalWS.off('regionChatsReceived', handleSetGroupChatsList);
    };
  }, [regionChatsList]);

  const togglePanelVisibility = () => {
    setPanelVisible(!isPanelVisible);
    setCurrentOpenChat(null);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1023);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const [chatConnectionStatus, setChatConnectionStatus] = useState(false);

  useEffect(() => {
    const handleSetConnectionStatus = (status: boolean) => {
      setChatConnectionStatus(status);
    };
    globalWS.on('chatConnectionStatusListener', handleSetConnectionStatus);
    return () => {
      globalWS.off('chatConnectionStatusListener', handleSetConnectionStatus);
    };
  }, [activeRegion]);

  useEffect(() => {
    if (!activeRegion) return;
    setCurrentOpenChat(null);
  }, [activeRegion]);

  useEffect(() => {
    if (chatConnectionStatus === true && activeRegion) {
      globalWS.refreshRegionChat(String(activeRegion.id));
    }
  }, [activeRegion, chatConnectionStatus]);

  const moveChatToTop = (chatId: number) => {
    // setSortingChatSidebarOnFrontend(true);
    setIndividualChats((prevChats: any) => {
      if (prevChats[0]?.id === chatId) {
        return prevChats;
      }

      const chatToMove = prevChats.find((chat: any) => chat.id === chatId);

      const updatedChats = [
        ...(chatToMove ? [chatToMove] : []),
        ...prevChats.filter((chat: any) => chat.id !== chatId),
      ];

      return updatedChats;
    });
  };

  const { availableRegionsData } = useRegions();
  const hasAvailableRegionsDropdown = availableRegionsData?.regions?.length
    ? true
    : false;

  return isLoadingRegions || isLoadingUsers ? (
    <TransitionLoader message="Učitavanje poruka" />
  ) : (
    <div className="lg:h-[calc(100vh-200px)]  h-screen  flex  w-full lg:border  lg:border-secondary-200 rounded-lg  overflow-hidden   ">
      {allUsers.length > 0 &&
        individualConversationIds &&
        (isMobile ? (
          !isPanelVisible ? (
            <ChatSidebar
              // avatarsList={avatarsUrls}
              allRegions={allRegions}
              allUsers={allUsers}
              currentOpenChat={currentOpenChat}
              conversations={individualChats}
              regionChatConversations={regionChatsList}
              onSelectCurrentChat={handleChatClick}
            />
          ) : (
            <ChatConversationPanel
              allUsers={allUsers}
              allRegions={allRegions}
              // avatarsList={avatarsUrls}
              conversations={individualChats}
              onSelectCurrentChat={handleChatClick}
              currentOpenChat={currentOpenChat}
              onTogglePanelVisibility={togglePanelVisibility}
              handleMoveChatToTop={moveChatToTop}
            />
          )
        ) : (
          <>
            <ChatSidebar
              // avatarsList={avatarsUrls}
              allRegions={allRegions}
              allUsers={allUsers}
              currentOpenChat={currentOpenChat}
              conversations={individualChats}
              regionChatConversations={regionChatsList}
              onSelectCurrentChat={handleChatClick}
            />
            <ChatConversationPanel
              // avatarsList={avatarsUrls}
              allRegions={allRegions}
              onSelectCurrentChat={handleChatClick}
              conversations={individualChats}
              currentOpenChat={currentOpenChat}
              allUsers={allUsers}
              handleMoveChatToTop={moveChatToTop}
            />
          </>
        ))}
    </div>
  );
};

export default ChatLayout;
