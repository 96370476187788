import {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { toast } from 'react-toastify';

import {
  ACTIVE_REGION_KEY,
  AUTH_KEY,
  LOGGING_OUT_USER_MESSAGE,
  REGIONS_KEY,
  USER_ACTIVE_KEY,
} from '../constants';
import { UserInterface } from '../types';
import {
  getAuthStateFromLocalStorage,
  getTokenDuration,
  showToast,
  showSessionExpiredToast,
  getAvailableRegionsStateFromLocalStorage,
} from '../utils';

import AuthStore from '../modules/auth/services/auth.store';
import ForumMembersStore from '../modules/forumMembers/services/forumMembers.store';
import { globalWS } from '../init';

const authStore: AuthStore = new AuthStore();
const forumMembersStore: ForumMembersStore = new ForumMembersStore();

const TOKEN_REFRESH_THRESHOLD = 5 * 60 * 1000; // used for refreshing token X minutes before token expires (currently 5)
const IDLE_TIMEOUT_DURATION = TOKEN_REFRESH_THRESHOLD * 3 - 1001; // after X minutes of user inactivity popup appears (currently ~14)

// Define the shape of the context value
interface AuthStateInterface {
  token?: string;
  refreshToken?: string;
  expiresAt?: string;
  userId?: string;
  user?: UserInterface;
  avatar?: undefined;
  isAuth?: boolean | undefined;
}

interface AuthContextInterface extends AuthStateInterface {
  avatarUrl: string | undefined;
  setAvatarUrl: (value: string) => void;
  isInactivityModalButtonClicked: boolean;
  setIsInactivityModalButtonClicked: (value: boolean) => void;
  showInactivityModal: boolean;
  setShowInactivityModal: (value: boolean) => void;
  isLoggingOut: boolean;
  logoutAuthUser: () => void;
  resetAuthState: () => void;
  updateAuthState: () => void;
}

// Default initial state
const DEFAULT_AUTH_STATE: AuthStateInterface = {
  token: undefined,
  refreshToken: undefined,
  expiresAt: undefined,
  userId: undefined,
  user: undefined,
  avatar: undefined,
  isAuth: undefined,
};

// Initialize context with default values
const AuthContext = createContext<AuthContextInterface>({
  ...DEFAULT_AUTH_STATE,
  isLoggingOut: false,
  avatarUrl: undefined,
  setAvatarUrl: () => {
    console.warn('setAvatarUrl function not initialized');
  },
  showInactivityModal: false,
  setShowInactivityModal: () => {
    console.warn('setShowInactivityModal function not initialized');
  },
  isInactivityModalButtonClicked: false,
  setIsInactivityModalButtonClicked: () => {
    console.warn('setShowInactivityModal function not initialized');
  },
  logoutAuthUser: () => {
    console.warn('logoutAuthUser function not initialized');
  },
  resetAuthState: () => {
    console.warn('resetAuthState function not initialized');
  },
  updateAuthState: () => {
    console.warn('setAuthState function not initialized');
  },
});

interface AuthProviderProps {
  children: ReactNode;
}

const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [authState, setAuthState] = useState<AuthStateInterface>(() => {
    const { token, refreshToken, expiresAt, userId, user } =
      getAuthStateFromLocalStorage();
    const isAuth = !!(token && refreshToken && expiresAt && userId && user);
    return { token, refreshToken, expiresAt, userId, user, isAuth };
  });
  const [avatarUrl, setAvatarUrl] = useState<string | undefined>(undefined);
  const [isInactivityModalButtonClicked, setIsInactivityModalButtonClicked] =
    useState(false);
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const [showInactivityModal, setShowInactivityModal] = useState(false);

  const hasFetchedAvatar = useRef(false); // Track if fetch has already happened

  const { token } = authState;
  const isInvalid = !token;
  const idleTimeoutId = useRef<NodeJS.Timeout | null>(null);

  // Reset Auth State
  const resetAuthState = useCallback(() => {
    setAuthState(DEFAULT_AUTH_STATE);
    localStorage.removeItem(AUTH_KEY);
  }, []);

  // Update Auth State
  const updateAuthState = useCallback(() => {
    const {
      token: lsToken,
      refreshToken: lsRefreshToken,
      userId: lsUserId,
      expiresAt: lsExpiration,
      user: lsUser,
    } = getAuthStateFromLocalStorage();

    if (lsToken && lsUserId && lsExpiration) {
      setAuthState((prevState) => ({
        ...prevState,
        token: lsToken,
        refreshToken: lsRefreshToken,
        userId: lsUserId,
        user: lsUser,
        expiresAt: lsExpiration,
        isAuth: true,
      }));
    }
  }, []);

  // Logout User
  const logoutAuthUser = useCallback(
    async (expired = false) => {
      const regions = getAvailableRegionsStateFromLocalStorage();
      setIsLoggingOut(true);
      toast.dismiss();
      !expired && (await authStore.logoutUser());
      localStorage.removeItem(USER_ACTIVE_KEY);
      resetAuthState();
      setIsLoggingOut(false);
      setShowInactivityModal(false);
      globalWS.disconnect();
      regions?.activeRegion &&
        localStorage.setItem(
          ACTIVE_REGION_KEY,
          JSON.stringify(regions.activeRegion)
        );
      localStorage.removeItem(REGIONS_KEY);
      hasFetchedAvatar.current = false;
    },
    [resetAuthState]
  );

  // Token expiration / refresh token if necessary
  useEffect(() => {
    if (!token) return;

    let timeoutId: string | number | NodeJS.Timeout | undefined;

    const tokenDuration = getTokenDuration();

    if (tokenDuration <= 0) {
      clearTimeout(timeoutId);
      logoutAuthUser(true);
      return;
    }

    const refreshToken = async () => {
      // console.log('Token refreshing...');
      try {
        const response = await authStore.refreshToken();
        const { success } = response;

        if (success) {
          scheduleCheck();
        } else {
          //console.error('Token refresh failed:', message);
          logoutAuthUser();
          showSessionExpiredToast();
        }
      } catch (error) {
        //console.error('Error refreshing token:', error);
        logoutAuthUser();
        showSessionExpiredToast();
      }
    };

    const checkTokenExpiry = async () => {
      const tokenDuration = getTokenDuration();

      // If the token is expiring within the threshold, refresh it
      if (tokenDuration > 0 && tokenDuration <= TOKEN_REFRESH_THRESHOLD) {
        refreshToken();
      }
    };

    const scheduleCheck = () => {
      const tokenDuration = getTokenDuration();

      if (tokenDuration > 0) {
        // Schedule the check to run just before the token expires
        timeoutId = setTimeout(() => {
          checkTokenExpiry();
        }, tokenDuration - TOKEN_REFRESH_THRESHOLD);
      }
    };

    if (showInactivityModal) {
      if (timeoutId) {
        clearTimeout(timeoutId);
        //console.log('Timeout cleared due to inactivity modal:', timeoutId);
      }
    }

    if (!isInactivityModalButtonClicked) {
      scheduleCheck();
    }

    if (isInactivityModalButtonClicked) {
      refreshToken();
      setIsInactivityModalButtonClicked(false);
    }

    // Clean up the timeout on unmount or when dependencies change
    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
        // console.log(
        //   'Timeout cleared on component unmount or dependency change:',
        //   timeoutId
        // );
      }
    };
  }, [
    token,
    isInactivityModalButtonClicked,
    showInactivityModal,
    logoutAuthUser,
  ]);

  // Avatar
  useEffect(() => {
    const tokenDuration = getTokenDuration();

    if (tokenDuration <= 0) return;

    const fetchAvatar = async () => {
      const response = await forumMembersStore.getForumMemberAvatar(
        Number(authState.userId)
      );

      const { success } = response;

      if (success && response.data instanceof File) {
        const url = URL.createObjectURL(response.data);
        setAvatarUrl(url);

        return () => URL.revokeObjectURL(url);
      }
    };

    // If userId exists and avatar has not been fetched yet, fetch it
    if (authState.userId && !hasFetchedAvatar.current) {
      hasFetchedAvatar.current = true; // Mark as fetched
      fetchAvatar();
    }
  }, [authState.userId]);

  // Idle Timeout - if user inactive for 'IDLE_TIMEOUT_DURATION' (currently set to 15 minutes), log them out
  const handleIdleTimeout = useCallback(() => {
    setShowInactivityModal(true);
    localStorage.removeItem(USER_ACTIVE_KEY);
  }, []);

  useEffect(() => {
    if (isInvalid || showInactivityModal) return;

    const resetIdleTimer = () => {
      if (idleTimeoutId.current) {
        localStorage.setItem(USER_ACTIVE_KEY, '1');
        clearTimeout(idleTimeoutId.current);
      }

      idleTimeoutId.current = setTimeout(
        handleIdleTimeout,
        IDLE_TIMEOUT_DURATION
      );
    };

    resetIdleTimer();
    window.addEventListener('mousemove', resetIdleTimer);
    window.addEventListener('keydown', resetIdleTimer);
    window.addEventListener('scroll', resetIdleTimer);

    return () => {
      if (idleTimeoutId.current) {
        clearTimeout(idleTimeoutId.current);
      }
      window.removeEventListener('mousemove', resetIdleTimer);
      window.removeEventListener('keydown', resetIdleTimer);
      window.removeEventListener('scroll', resetIdleTimer);
    };
  }, [handleIdleTimeout, isInvalid, showInactivityModal]);

  // Storage Changes
  useEffect(() => {
    const handleStorageChange = (event: StorageEvent) => {
      // Ignore changes if `isInvalid` is true
      if (isInvalid) return;

      // Check if the change is related to the `auth` key
      if (event.storageArea === localStorage && event.key === AUTH_KEY) {
        if (event.newValue === null) {
          // The `auth` key was deleted
          logoutAuthUser();
          showToast(LOGGING_OUT_USER_MESSAGE, false);
        } else {
          updateAuthState();
        }
      }

      if (event.storageArea === localStorage && event.key === USER_ACTIVE_KEY) {
        setShowInactivityModal(false);
      }
    };

    // Add event listener to handle changes
    window.addEventListener('storage', handleStorageChange);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [isInvalid, logoutAuthUser, updateAuthState]);

  return (
    <AuthContext.Provider
      value={{
        ...authState,
        showInactivityModal,
        setShowInactivityModal,
        avatarUrl,
        setAvatarUrl,
        isInactivityModalButtonClicked,
        setIsInactivityModalButtonClicked,
        isLoggingOut,
        updateAuthState,
        resetAuthState,
        logoutAuthUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth };
