export interface SearchApiInterface {
	term: string;
	page?: number;
	rowsPerPage?: number;
	regionIds?: number[];
}

export enum SearchResultItemType {
	FRAUD_CASE = 'fraud_case',
	NEWS = 'news',
	ANY = 'any', // Fallback for any other types
}

export interface SearchItemSourceInterface {
	id: string;
	title: string;
	content: string;
	type: SearchResultItemType;
	region: string[];
	url: string;
}

export interface SearchItemInterface {
	_source: SearchItemSourceInterface;
}

export interface SearchResultsInterface {
	hits: SearchItemSourceInterface[];
	total: number;
}

export interface SearchResultsProps {
	data: SearchResultsInterface;
	error?: boolean;
	searchTerm: string;
	initialSearchTerm?: string;
	minChars: number;
	isFetching?: boolean;
	onClose?: () => void;
}

export interface DocumentResponseInterface {
	url?: string;
	error?: string;
}
