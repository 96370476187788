import { Link, useParams } from 'react-router-dom';

import Button from '../../components/shared/Button';
import Meta from '../../components/layout/Meta';
import PageLayout from '../../layouts/PageLayout';
import PageTitleWithActions from '../../layouts/PageLayout/TitleWithActions';
import DocumentReports from '../../modules/documents/UI';

function DocumentsPage() {
  const { slug } = useParams<{ slug: string }>();

  const allowedSlugs = {
    decisions: 'Odluke',
    workreports: 'Izveštaji o radu foruma',
  };

  const pageTitle =
    allowedSlugs[slug as keyof typeof allowedSlugs] || 'Dokumenti';

  return (
    <>
      <Meta title={pageTitle} />
      <PageLayout>
        <PageTitleWithActions title={pageTitle}>
          {slug && Object.keys(allowedSlugs).includes(slug) && (
            <Link to="add" replace className="focus:outline-none group">
              <Button tabIndex={-1}>Novi dokument</Button>
            </Link>
          )}
        </PageTitleWithActions>
        <DocumentReports slug={slug} />
      </PageLayout>
    </>
  );
}

export default DocumentsPage;
