import React, { useState } from 'react';
import Accordion from './Accordion';

interface FAQAccordionProps {
  items: { id: number | string; question: string; answer: string }[];
  singleOpen?: boolean; // Determines if only one accordion can be open
}

const FAQAccordion: React.FC<FAQAccordionProps> = ({
  items,
  singleOpen = false, // Default to single-open mode
}) => {
  const [openItemId, setOpenItemId] = useState<number | string | null>(null);
  const [openItems, setOpenItems] = useState<(number | string)[]>([]);

  const handleToggleAccordion = (id: number | string) => {
    if (singleOpen) {
      // Single open mode: toggle only one item
      setOpenItemId((prev) => (prev === id ? null : id));
    } else {
      // Multi-open mode: toggle multiple items
      setOpenItems((prev) =>
        prev.includes(id)
          ? prev.filter((itemId) => itemId !== id)
          : [...prev, id]
      );
    }
  };

  return (
    <div className="space-y-3">
      {items.map((item) => (
        <Accordion
          key={item.id}
          item={item}
          isOpen={
            singleOpen ? openItemId === item.id : openItems.includes(item.id)
          }
          onToggle={() => handleToggleAccordion(item.id)}
        />
      ))}
    </div>
  );
};

export default FAQAccordion;
