import { useRef, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import HeaderSearch from '../../../modules/search/UI/HeaderSearch';
import Icon from '../../shared/Icon';
import Logo from '../../shared/Logo';
import AvailableRegions from './AvailableRegions';
import MegaMenu from './MegaMenu';
import MobileNavigationDrawer from './MobileNavigationDrawer';
import UserAvatarPopup from './UserAvatarPopup';

const SCROLL_DELTA = 5; // Minimum scroll difference to trigger header visibility change
const SCROLL_THRESHOLD = 80; // Scroll position threshold for hiding the header

/**
 * Props interface for MainNavigation component.
 * @property {string} [userName] - The name of the logged-in user, if available.
 */
interface MainNavigationProps {
  userName?: string;
  userId?: string;
}

/**
 * MainNavigation component renders a navigation bar
 * with a logo, main links, nested links, and an optional logout button.
 * It also handles showing and hiding the header based on the user's scroll position.
 *
 * @param {MainNavigationProps} props - The props for the component.
 * @param {string} [props.userName] - The name of the logged-in user, if available.
 */

const MainNavigation = ({ userName, userId }: MainNavigationProps) => {
  // Ref to the header element for manipulating its style
  const headerRef = useRef<HTMLHeadingElement>(null);
  // Ref to store the last known scroll position
  const lastScrollYRef = useRef(0);
  // Ref to track the visibility of the header
  const isHeaderVisibleRef = useRef(true);

  /**
   * Updates the visibility of the header by changing its transform property.
   * @param {boolean} isVisible - Whether the header should be visible or not.
   */
  const updateHeaderVisibility = (isVisible: boolean) => {
    if (headerRef.current) {
      headerRef.current.style.transform = isVisible
        ? 'translateY(0)'
        : 'translateY(-100%)';
    }
  };

  // Effect to handle the scroll event and update header visibility
  useEffect(() => {
    if (isDrawerOpen) return;
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      const scrollDelta = Math.abs(currentScrollY - lastScrollYRef.current);

      if (scrollDelta > SCROLL_DELTA) {
        if (
          currentScrollY > lastScrollYRef.current &&
          currentScrollY > SCROLL_THRESHOLD
        ) {
          // Scroll down
          if (isHeaderVisibleRef.current) {
            isHeaderVisibleRef.current = false;
            updateHeaderVisibility(false);
          }
        } else if (currentScrollY < lastScrollYRef.current) {
          // Scroll up
          if (!isHeaderVisibleRef.current) {
            isHeaderVisibleRef.current = true;
            updateHeaderVisibility(true);
          }
        }
        lastScrollYRef.current = currentScrollY;
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const drawerButtonRef = useRef(null);

  const location = useLocation();
  useEffect(() => {
    if (setIsDrawerOpen) setIsDrawerOpen(false);
  }, [location]);

  useEffect(() => {
    if (isDrawerOpen) document.body.classList.add('overflow-hidden');
    if (!isDrawerOpen) document.body.classList.remove('overflow-hidden');
  }, [isDrawerOpen]);

  return (
    <header
      ref={headerRef}
      className="fixed z-30 top-0 w-full h-20 border-b border-gray-300 bg-white text-secondary transition-transform duration-300"
    >
      <div className="pks-container flex items-center justify-between gap-4 h-full screen-  ">
        <button
          ref={drawerButtonRef}
          className="menu_icon lg:hidden block"
          aria-haspopup="true"
          onClick={() => setIsDrawerOpen(!isDrawerOpen)}
        >
          <Icon name="hamburgerMenu" className="pointer-events-none" />
        </button>

        {/* Logo */}
        <Logo className="" />

        {/* Navigation Desktop*/}
        <div className="hidden lg:block h-full w-full">
          <MegaMenu />
        </div>
        {/* Navigation Mobile*/}
        <div className="lg:hidden block">
          <MobileNavigationDrawer
            {...{ isDrawerOpen, setIsDrawerOpen, drawerButtonRef }}
          />
        </div>

        {/* Search && Logout Button*/}
        <div className="flex gap-8 items-center h-full">
          <HeaderSearch />
          <UserAvatarPopup name={userName} id={userId} />
        </div>
      </div>
      <AvailableRegions />
    </header>
  );
};

export default MainNavigation;
