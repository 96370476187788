import { FieldValues } from 'react-hook-form';
import http from '../../../utils/http';
import { InfoPageTypeEnum } from '../../../pages/info';
import { FaqAPIProps } from './info.types';

class InfoApi {
  getInfo = async (type: InfoPageTypeEnum | 'qanda' | 'adviceforum') => {
    return http.get(`/articles/${type}`);
  };

  addInfo = async ({
    data,
    type,
  }: {
    data: FieldValues;
    type: InfoPageTypeEnum;
  }) => {
    return http.post(`/articles/${type}`, data);
  };

  addFAQ = async ({ contentJson, regionId }: FaqAPIProps) => {
    return http.post(`/articles/qanda`, {
      contentJson: contentJson,
      regionId: regionId,
    });
  };

  addForumCouncil = async ({ contentJson, regionId }: any) => {
    return http.post(`/articles/adviceforum`, {
      contentJson: contentJson,
      regionId: regionId,
    });
  };
}

export default InfoApi;
