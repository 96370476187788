import InfoMessage from '.';

const NoSearchResultsInfoMessage = () => {
  return (
    <InfoMessage
      icon="info"
      message="Ne postoje rezultati za zadatu pretragu."
    />
  );
};

export default NoSearchResultsInfoMessage;
