import { useNavigate, useParams } from 'react-router-dom';

import PageLayout from '../../layouts/PageLayout';
import Button from '../../components/shared/Button';
import Meta from '../../components/layout/Meta';
import ForumMemberForm from '../../modules/forumMembers/UI/ForumMembersForm';

import { BACK_TO_LIST, FORUM_MEMBERS_PATH } from '../../constants';
import { useAuth } from '../../context/auth-context';

const ForumMemberFormPage = () => {
  const { slug } = useParams();
  const navigate = useNavigate();
  const { userId } = useAuth();
  const pageTitle = slug ? 'Izmeni člana' : 'Dodaj člana';

  return (
    <>
      <span className="mb-8 flex gap-2 items-center w-fit justify-center b sm:flex-none focus:outline-none group">
        <Button
          onClick={() => navigate(FORUM_MEMBERS_PATH)}
          type="button"
          variant="back"
          tabIndex={-1}
        >
          {BACK_TO_LIST}
        </Button>
      </span>
      <Meta title={pageTitle} />
      <PageLayout
        className="max-w-[500px]"
        title={userId?.toString() === slug ? undefined : pageTitle}
      >
        <ForumMemberForm />
      </PageLayout>
    </>
  );
};

export default ForumMemberFormPage;
